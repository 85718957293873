<template>
  <div
    :class="[`profile ${this.classModificator}`,{'profile--open': profileIsOpen}]"
    @click="toggleDropdown"
    v-click-outside="close"
  >

    <Notices  
      @click.stop    
      class="profile__notices"
      v-if="$store.state.isMobile && this.classModificator === 'profile--sidebar'"
    />

    <div class="profile__wrap">
      <div class="profile__avatar">
        <div class="profile__avatarDefault">
          <span>{{firstWord}}</span>
        </div>
      </div>
      <div
        class="profile__infoHeadData"
        v-if="$store.state.isMobile && this.classModificator === 'profile--sidebar'"
      >
          <div class="profile__infoHeadDataUsername">{{user.username}}</div>
          <div
            class="profile__infoHeadDataEmail"
            v-if="this.currentStore.legalName"
          >
            {{this.currentStore.legalName}}
          </div>
          <div class="profile__infoHeadDataId">ID {{user.id}}</div>
        </div>
    </div>

    <div class="profile__info" v-if="profileIsOpen" @click.stop>
      <div class="profile__infoHead">
        <div class="profile__infoHeadAvatar">
          <div class="profile__infoHeadAvatarDefault">
            <span>{{firstWord}}</span>
          </div>
        </div>
        <div class="profile__infoHeadData">
          <div class="profile__infoHeadDataUsername">{{user.username}}</div>
          <div
            class="profile__infoHeadDataEmail"
            v-if="this.currentStore.legalName"
          >
            {{this.currentStore.legalName}}
          </div>
          <div class="profile__infoHeadDataId">ID {{user.id}}</div>
        </div>
      </div>

      <GlobalSetting      
        v-if="$route.name === 'PBBasicProducts' && $store.state.isMobile"
        class="profile__globalSetting"
      />

      <UserStore        
        v-if="storeList.length"
        class="profile__userStore"
        :storeList="storeList"
      />

      <div class="profile__infoBalance">
        <div class="profile__infoBalanceTitle">Баланс</div>
        <div class="profile__infoBalanceValue">{{ balanceInfo ? balanceInfo : '0 ₽' }} </div>
      </div>
      <div class="profile__infoMenu">

        <router-link
          class="profile__infoMenuLink"
          :to="{path: '/account/balance'}"
          @click="profileIsOpen = false"
          replace
        >
          Баланс
        </router-link>

        <router-link
          class="profile__infoMenuLink"
          :to="{path: '/account/tickets'}"
          @click="profileIsOpen = false"
          replace
        >
          Тикеты
        </router-link>
        <router-link
          class="profile__infoMenuLink"
          :class="{'profile__infoMenuLink--instruction' : !$store.state.auth.user.is_instructed.integration}"
          :to="{path: '/account/settings'}"
          @click="profileIsOpen = false"
          replace
        >
          Настройки пользователя
        </router-link>
        <router-link
          class="profile__infoMenuLink"
          :to="{path: '/account/team'}"
          @click="profileIsOpen = false"
          replace
        >
          Команда
        </router-link>

        <router-link
          v-if="user.role === 'Admin' || user.role === 'Seo'"
          class="profile__infoMenuLink"
          :to="{path: '/admin'}"
          @click="profileIsOpen = false"
        >
          Админ-панель
        </router-link>

        <!-- <button class="profile__infoMenuLink">Обновить список товаров (API)</button> -->

      </div>
      <div class="profile__infoFooter">
        <button
          class="profile__infoFooterButton"
          @click="logout"
        >
          Выйти
        </button>
        <Socials />
      </div>
    </div>

  </div>
</template>

<script>
import GlobalSetting from '@/components/GlobalSetting'
import UserStore from '@/components/UserStore/UserStore'
import Socials from '@/components/Socials'
import Notices from '@/components/Notices'
import {mapActions, mapState} from 'vuex'

export default {
  components: {
    GlobalSetting,
    UserStore,
    Socials,
    Notices
  },
  props: {
    classModificator: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      profileIsOpen: false
    } 
  },
  methods: {
    handlerAddSetInternalStepsInstructionOnMobile(val) {
      if(this.$store.state.isMobile && this.$route.name === 'PBBasicProducts' && !this.$store.state.auth.user.is_instructed.integration) this.addSetInternalStepsInstructionOnMobile(val)
    },
    close() {
      if(this.profileIsOpen) {
        this.profileIsOpen = false
        this.$emit('dropdownStatus', false)
        if(this.$route.path === '/crm/dashboard' || this.$route.path === '/price_bidder') this.handlerAddSetInternalStepsInstructionOnMobile(1)
      }
    },
    toggleDropdown() {
      if(this.$store.state.isMobile && !(this.classModificator === 'profile--sidebar') && this.$route.name !== 'admin') {
        this.openPopup({
          component: 'SidebarMobile'
        })
      } else {
        this.profileIsOpen = true
        this.$emit('dropdownStatus', true)
        if(this.$route.path === '/crm/dashboard' || this.$route.path === '/price_bidder') this.handlerAddSetInternalStepsInstructionOnMobile(2)
      }
    },
    ...mapActions({
      logout: 'auth/logout',
      openPopup: 'popup/openPopup',
      getBalanceInfo: 'userBalance/getBalanceInfo',
      productList: 'priceBidder/productList',
      addSetInternalStepsInstructionOnMobile: 'popup/addSetInternalStepsInstructionOnMobile'
    })
  },
  computed: {
    ...mapState({
      user: state => state.auth.user,
      currentStore: state => state.userStore.currentStore,
      storeList: state => state.userStore.storeList,
      balanceInfo: (state) => state.userBalance.balanceInfo,
      params: state => state.priceBidder.params,
      products: state => state.priceBidder.products,
      internalStepsInstructionOnMobile: state => state.popup.internalStepsInstructionOnMobile
    }),
    firstWord() {
      if(this.user) return this.user.username[0]
    }
  },
  beforeMount() {
    if(this.$route.name === 'balance') return
    else if(!this.balanceInfo) this.getBalanceInfo()
  }
}
</script>

<style lang="scss">
@import 'src/scss/utils/mixins.scss';
@import 'src/scss/base/project/var.scss';

.profile {
  z-index: 9998;
  display: flex;
  max-width: max-content;
  position: relative;
  width: 30px;
  max-width: 100%;
  font-family: $font-primary;
  color: $black;
  @include breakpoint(md) {
    width: 40px;
    justify-content: flex-end;
  }
  &--open {
    z-index: 9998;
  }
  &--admin &__info {
    right: 0;
    left: auto;
  }

  &__notices {
    z-index: 9999;
    @include verticalCenter();
    right: 30px;
    @include mediaMaxWidth(375) {
      right: 20px;
    }
  }

  &__wrap {
    cursor: pointer;
    width: 100%;
    display: flex;
    align-items: center;
  }
  &__avatar {
    flex: 0 0 30px;
    height: 30px;
    border-radius: 100%;
    overflow: hidden;
    @include breakpoint(md) {
      margin-right: 0;
      flex: 0 0 40px;
      width: 40px;
      height: 40px;
    }
    &Default {
      position: relative;
      width: inherit;
      height: inherit;
      background-image: $radial-gradient-profile-avatar;
      color: $blue;
      font-size: 16px;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      @include breakpoint(md) {
        background-image: $radial-gradient-profile-avatar-menu;
      }
      span {
        position: relative;
        top: 0;
        display: block;
        font-weight: 600;
        @include breakpoint(md) {
          color: $white;
          font-size: 25px;
          line-height: 25px;
        }
      }
    }
  }
  &__userName {
    max-width: calc(100% - 40px);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-size: 15px;
    line-height: 12px;
    line-height: 30px;
    @include breakpoint(xl) {
      font-size: 13px;
      line-height: 10px;
    }
    @include breakpoint(md) {
      font-size: 14px;
      line-height: 11px;
    }
  }
  &__info {
    z-index: 9998;
    position: absolute;
    top: 0;
    left: -15px;
    background-color: $white;
    width: 320px;
    padding: 30px;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.15);
    border-radius: 20px;
    @include breakpoint(md) {
      left: auto; 
      right: 0;
    }
    @include breakpoint(sm) {
      width: 290px;
    }
    &Head {
      display: flex;
      align-items: center;
      margin-bottom: 30px;
      &Avatar {
        @extend .profile__avatar;
        flex: 0 0 60px;
        width: 60px;
        height: 60px;
        margin-right: 10px;
        @include breakpoint(xl) {
          width: 50px;
          height: 50px;
          flex: 0 0 50px;
        }
        @include breakpoint(md) {
          margin-right: 10px;
        }
        &Default {
          @extend .profile__avatarDefault;
          background-image: $radial-gradient-profile-avatar-menu;
          color: $white;
          span {
            top: 0;
            font-size: 25px;
            line-height: 25px;
          }
        }
      }
      &Data {
        margin-top: 2px;
        display: block;
        max-width: calc(100% - 70px);
        @include breakpoint(xl) {
          max-width: calc(100% - 60px);
          margin-top: 0;
        }
        &Username {
          line-height: 18px;
          @include breakpoint(xl) {
            margin-bottom: 3px;
          }
        }
        &Username, &Email {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          margin-bottom: 3px;
        }
        &Id, &Email {
          color: $gray-400;
          font-size: 12px;
          line-height: 12px;
        }
      }
    }

    &Balance {
      margin-bottom: 30px;
      &Title {
        margin-bottom: 5px;
        @include breakpoint(xl) {
          font-size: 13px;
          line-height: 10px;
        }
        @include breakpoint(md) {
          font-size: 14px;
          line-height: 11px;
        }
      }
      &Value {
        font-size: 30px;
        line-height: 24px;
        font-weight: 600;
        @include breakpoint(xl) {
          font-size: 24px;
          line-height: 19px;
        }
      }
    }

    &Menu {
      margin-bottom: 30px;
      &Link {
        display: block;
        margin-bottom: 20px;
        font-size: 15px;
        line-height: 12px;
        cursor: pointer;
        &:last-child {
          margin-bottom: 0;
        }
        &--instruction {
          color: $dark-green-400;
          font-weight: 600;
        }
        @include breakpoint(xl) {
          font-size: 13px;
          line-height: 10px;
        }
        @include breakpoint(md) {
          font-size: 14px;
          line-height: 11px;
        }
      }
    }

    &Footer {
      &Actions {
        margin-bottom: 30px;
      }
      &Button {
        text-align: left;
        white-space: nowrap;
        width: 100%;
        display: block;
        line-height: 12px;
        margin-bottom: 30px;
        @include breakpoint(xl) {
          font-size: 13px;
          line-height: 10px;
        }
        @include breakpoint(md) {
          font-size: 14px;
          line-height: 11px;
        }
      }
    }

  }

  &__globalSetting {
    margin-bottom: 30px; 
    margin-right: 20px;
    @include mediaMaxWidth(375) {
      margin-right: 10px;
    }
    > div {
      margin-bottom: 10px;
    }
  }

  &__userStore {
    margin-bottom: 30px;
  }

  &--sidebar & {
    &__avatar {
      @include breakpoint(md) {
        width: 60px;
        height: 60px;
        flex: 0 0 60px;
        margin-right: 10px;
      }
    }
    &__userName {
      @include breakpoint(md) {
      font-size: 20px;
      }
      @include breakpoint(xs) {
        font-size: 14px;
      }
    }
    &__info {
      @include breakpoint(md) {
        max-width: 100%;
        width: 100%;
        right: 0;
        padding: 30px;
      }
      @include breakpoint(sm) {
        padding: 30px;
      }
      @include mediaMaxWidth(375) {
        padding: 30px 20px;
      }
      &Balance {
        @include breakpoint(md) {
          font-size: 14px;
          line-height: 11px;
        }
      }
      &Value {
        @include breakpoint(md) {
          font-size: 30px;
          line-height: 24px;
        }
      }
      &Menu {
        &Link {
          @include breakpoint(md) {
            font-size: 14px;
            line-height: 11px;
          }
          @include breakpoint(xs) {
            font-size: 14px;;
            line-height: 11px;
          }
        }
      }
      &Footer {
        &Button {
          @include breakpoint(md) {
            font-size: 14px;
            line-height: 11px;
          }
          @include breakpoint(xs) {
            font-size: 14px;;
            line-height: 11px;
          }
        }
      }
    }
  }

}

</style>