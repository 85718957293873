<template>
  <div class="tabs">
    <div
      :class="[
        'tabs__item',
        { 'tabs__item--active': tab.name === selectedTab },
      ]"
      v-for="tab in tabs"
      :key="tab"
      @click="changeTab(tab)"
    >
      {{ tab.name }}
    </div>

    <div
      :class="[
        'tabs__item',
        'tabs__item--favorite',
        { 'tabs__item--favoriteActive': showFavorites },
      ]"
      @click="isFavorite"
      v-if="favorite"
    >
      <BaseFavoriteIcon 
        class="tabs__itemFavoriteIcon"
        :isFavorite="showFavorites"
      />
      <span>Только избранные</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tabs: {
      type: Array,
    },
    favorite: {
      type: Boolean,
      defalut: false,
    },
  },
  data() {
    return {
      showFavorites: false,
      selectedTab: null,
    };
  },
  beforeMount() {
      if(this.$route.name === 'PBBasicProducts' && this.$store.state.priceBidder.selectedTab) {
          this.selectedTab = this.$store.state.priceBidder.selectedTab.name;
          this.changeTab(this.$store.state.priceBidder.selectedTab);
      } else {
          this.selectedTab = this.tabs[0].name;
          this.changeTab(this.tabs[0]);
      }
  },
  methods: {
    changeTab(tab) {
      this.selectedTab = tab.name;
      this.$emit("updateTab", tab.value);
      if(this.$route.name === 'PBBasicProducts') {
            this.$store.state.priceBidder.searchQuery = ''
            this.$store.commit("priceBidder/setSelectedTab", tab);
      }
    },
    isFavorite() {
      this.showFavorites = !this.showFavorites;
      this.$emit("updateFavorite", this.showFavorites);
      if(this.$route.name === 'PBBasicProducts') this.$store.state.priceBidder.searchQuery = ''
    },
  },
};
</script>

<style lang="scss">
@import "src/scss/utils/mixins.scss";
@import "src/scss/base/project/var.scss";

.tabs {
  position: relative;
  &::before {
    content: "";
    position: absolute;
    bottom: -40px;
    left: 0;
    width: 100%;
    height: 40px;
    box-shadow: 0px -7.5px 15px -7.5px rgba(0, 0, 0, 0.15);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    
    @include breakpoint(xl) {
      height: 30px;
      bottom: -30px;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
    }

    @include breakpoint(md) {
      display: none;
    }

  }
  &__item {
    white-space: nowrap;
    display: flex;
    align-items: center;
    margin-right: 10px;
    padding: 0 20px;
    height: 40px;
    background-color: $gray-100;
    cursor: pointer;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    transition: background-color $transition-duration ease,
    box-shadow $transition-duration ease;
    
    @include breakpoint(xl) {
      height: 30px;
      font-size: 14px;
    }

    @include breakpoint(md) {
      font-size: 16px;
      line-height: 16px;
      padding: 0 35px;
      height: 46px;
      border-radius: 20px;
      margin-right: 0;
      width: auto!important;
    }

    @include breakpoint(sm) {
      font-size: 14px;
      padding: 15px 20px;
      justify-content: center;
    }
    &:nth-child(2) {
      @include breakpoint(sm) {
        margin: 0 5px;
      }
    }
    &:last-child {
      margin-right: 0;
    }
    &--active {
      font-weight: 600;
      background-color: $white;
      box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
      z-index: 2;
      @include breakpoint(md) {
        box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.07);
      }
    }
    &--favorite {
      background-color: transparent;
      font-size: 15px;
      padding: 0 0 0 10px;
      @include breakpoint(xl) {
        font-size: 13px;
      }
      @include breakpoint(md) {
        font-size: 15px;
        line-height: 15px;
        padding: 0 10.5px;
        box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.07);
        border-radius: 20px;
      }
      @include breakpoint(sm) {
        flex: 0 0 100%;
        display: flex;
        justify-content: center;
        margin-top: 5px;
      }
    }
    &FavoriteIcon {
      width: 25px;
      height: 25px;
      margin-right: 5px;
    }
  }
}
</style>
