export default {

  namespaced: true,

  state: () => ({

    navigation: {
      dropdown: [
        {
          id: 1,
          type: 'dropdown',
          menu: {
            root: 'CRM система',
            isDropDownRoot: false,
            icon: 'crmIconMenu',

            //user structure
            links: [
              {
                id: 14, 
                name: 'Продажи',
                urlPath: "/crm/sale",
                isFavorite: false,
              },
              {
                id: 4, 
                name: 'Заказы',
                urlPath: "/crm/orders",
                isFavorite: false
              },
              {
                id: 10, 
                name: 'Закуп партии',
                urlPath: "/crm/purchase-batches",
                isFavorite: false
              },
              {
                id: 2, 
                name: 'Блогеры',
                urlPath: "/crm/blogers",
                isFavorite: false,
              }
            ],
            
            //admin structure
            childs: [
              {
                id: 1,
                name: 'Доски 1',
                isDropDownChild: false,
                links: [
                  {id: 1, name: 'Покупная реклама', urlPath: '/crm/ad-items', isFavorite: false},
                  {id: 2, name: 'Деньги WB', urlPath: '/crm/money-wb', isFavorite: false},
                  {id: 3, name: 'Реклама', urlPath: '/crm/ads-wb', isFavorite: false},
                  {id: 4, name: 'Заказы Отчет', urlPath: '/crm/orders', isFavorite: false},
                  {id: 5, name: 'Блогеры', urlPath: '/crm/blogers', isFavorite: false},
                ]
              },
              {
                id: 2,
                name: 'Доски 2',
                isDropDownChild: false,
                links: [
                    {id: 6, name: 'Себестоимость товара', urlPath: '/crm/cost', isFavorite: false},
                    {id: 7, name: 'Общее о товарах', urlPath: '/crm/product-info', isFavorite: false},
                    {id: 8, name: 'Юнит Экономика', urlPath: '/crm/unit-economics', isFavorite: false},
                    {id: 9, name: 'Товары', urlPath: '/crm/product', isFavorite: false},
                    {id: 10, name: 'Закуп партии', urlPath: '/crm/purchase-batches', isFavorite: false},
                ]
              },
              {
                id: 3,
                name: 'Доски 3',
                isDropDownChild: false,
                links: [
                    {id: 11, name: 'Выдача по СЕО', urlPath: '/crm/seo', isFavorite: false},
                    {id: 12, name: 'Дашбоард', urlPath: '/crm/dashboard', isFavorite: false},
                    {id: 13, name: 'Оборачиваемость', urlPath: '/crm/turnover', isFavorite: false},
                    {id: 14, name: 'Продажи', urlPath: '/crm/sale', isFavorite: false},
                ]
              },
            ]
          }
        }
      ],
      common: [
        {name: 'Репрайсер', urlPath: '/price_bidder', icon: 'priceBidderIconMenu'},
        {name: 'Биддер рекламы', urlPath: '/advertising_bidder', icon: 'advertisingBidderIconMenu'},
        {name: 'Биддер креативов', urlPath: '/creative_bidder', icon: 'creativeBidderIconMenu'},
        {name: 'SEO парсер', urlPath: '/seo_parser', icon: 'seoIconMenu'},
        {name: 'Расширение', urlPath: '/extension', icon: 'extensionIconMenu'},
      ]
    },
    favorite: {
      dropdown: [
        {
          id: 1,
          type: 'dropdown',
          menu: {
            root: 'Избранное',
            isDropDownRoot: false,
            emptyFavorite: false,
            links: []
          }
        }
      ]
    }
  }),

  mutations: {
    setCurrentMenu(state, payload) {
      state.navigation = payload
    },

    //set a favorite link
    setFavorite(state, data) {

      //set in store
      if(data.role === 'Admin') {
        state.navigation.dropdown[0]?.menu?.childs?.map(child => {
          child.links.forEach(link => {
            if(link.urlPath === data.url) link.isFavorite = !link.isFavorite
          })
        }) 
      } else if(data.role === 'Client') {
        state.navigation.dropdown[0]?.menu?.links?.map(link => {
          if(link.urlPath === data.url) link.isFavorite = !link.isFavorite
        })
      }
      

      //set in localStorage
      let isFavorite

      if(data.role === 'Client' && localStorage.getItem('isFavorite')) isFavorite = JSON.parse(localStorage.getItem('isFavorite'))
      else if(data.role === 'Admin' && localStorage.getItem('isFavoriteAdmin')) isFavorite = JSON.parse(localStorage.getItem('isFavoriteAdmin'))
      else isFavorite = []

      if(isFavorite.length) !isFavorite.includes(data.url) ? isFavorite.push(data.url) : isFavorite = isFavorite.filter(item => item !== data.url)
      else isFavorite.push(data.url)

      data.role === 'Admin' ? localStorage.setItem('isFavoriteAdmin', JSON.stringify(isFavorite)) : localStorage.setItem('isFavorite', JSON.stringify(isFavorite))

    }
  },

  actions: {
    //get actual menu with favorites
    getActualMenu({state, commit}, role) {

        let isFavorite

        role === 'Admin' ? isFavorite = JSON.parse(localStorage.getItem('isFavoriteAdmin')) : isFavorite = JSON.parse(localStorage.getItem('isFavorite'))

        if(role === 'Admin' && localStorage.getItem('isFavoriteAdmin')) {
          state.navigation.dropdown[0].menu.childs.forEach(child => {
            child.links.forEach(link => {
              isFavorite.forEach(favoriteItem => {
                if(link.urlPath === favoriteItem) link.isFavorite = true
              })
            })
          })
        } else if(role === 'Client' && localStorage.getItem('isFavorite')) {
          state.navigation.dropdown[0].menu.links.forEach(link => {
            isFavorite.forEach(favoriteItem => {
              if(link.urlPath === favoriteItem) link.isFavorite = true
            })
          })
        } else return state.navigation
      
    },

    //generate favorite based on the current menu state
    getFavorite({state}, role) {
      state.favorite.dropdown[0].menu.links = []

      if(role === 'Admin') {
        state.navigation?.dropdown[0]?.menu?.childs?.forEach(child => {
          child?.links?.forEach(link => {
            if(link.isFavorite) {
              state.favorite.dropdown[0].menu.links.push(link)
            }
          })
        })
      } else if(role === 'Client') {
        state.navigation?.dropdown[0]?.menu?.links?.forEach(link => {
          if(link.isFavorite) {
            state.favorite.dropdown[0].menu.links.push(link)
          }
        })
      }
    },

    toggleFavorite({commit}, url) {
      commit('setFavorite', url)
    }
  }

}
