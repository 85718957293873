<template>
    <template v-if="view_type===1">
        <div class="camp-table-cell-inner">
            <div class="camp-statuses-total">
                <span style="width: 100%; background-color: #CACACA"></span>
            </div>
        </div>
    </template>
    <template v-else-if="view_type===2">

        <StatusTotal
            :field="field"
            :statuses="getStatuses"
            :title="title"
            :value="value"
        />
    </template>
    <template v-else>
        <!-- camp__is-opened camp__is-active -->
        <div
            :id="getElementId"
            ref="status_container"
            class="camp-table-cell-inner camp-table-cell__status-picker camp-dropdown__wrapper camp__js-dropdown"
        >
            <!-- Блок для отображения выбранного статуса -->
            <div class="camp-table-cell__status-picker_display camp-status" :style="'background-color: ' + getStatusColor()">
                <p class="camp-table-cell__status-picker_text">{{ getStatusText() }}</p>
            </div>

            <!-- Status picker -->
            <div class="camp-dropdown camp-dropdown-statuses" >
                <!-- presets -->
                <div class="camp-dropdown-statuses__presets camp-dropdown__level">
                    <div class="camp-dropdown-statuses__list camp-dropdown__body">

                        <div class="camp-dropdown-statuses__list--item">

                            <button class="camp-status"
                                    type="button"
                                    :class="'statbtn0'"
                                    :style="{backgroundColor: `#cacaca`}"
                                    @click="handleStatusClick(0)"
                            >Не выбран</button>

                        </div>

                        <div v-for="(status, index) in getStatuses" :key="index"
                             class="camp-dropdown-statuses__list--item">

                            <button class="camp-status"
                                    type="button"
                                    :class="'statbtn'+index"
                                    :style="'background-color: ' + status.color"
                                    @click="handleStatusClick(status.id)"
                            >{{ status.status_text }}</button>

                        </div>
                    </div>
                    <div class="camp-dropdown__footer">
                        <button 
                            class="camp-btn-transparent camp-btn-wide camp__js-dropdown-btn" 
                            data-action="edit" 
                            type="button"
                        >Редактировать отметки</button>
                    </div>
                </div>
                <!-- //presets -->

                <StatusEditor
                    :key="getStatusEditorKey"
                    :statuses="getStatuses"
                    :group_id="group_id"
                    :item_id="item_id"
                    :field="field"
                    :statustype="statustype"
                    :value="value"
                    @onEditStatuses="handleStatusEditedEvent"
                    
                />
            </div>
            <!-- //Status picker -->
        </div>


    </template>
    
</template>

<script>

    import {mapActions, mapGetters} from 'vuex'

    import ColorPickers from "./ColorPickers";
    import StatusEditor from "./StatusEditor";
    import StatusTotal from "../Totals/StatusTotal";

    export default {
        name: "Status",
        components: {StatusTotal, StatusEditor, ColorPickers},
        /*
         *    view_type -Тип представления статусов по умолчанию это dropdown со статусами
         */
        props: {
            statuses: {
                type: Array,
                required: false
            },
            title:'',
            group_id:0,
            item_id:Number,
            field:null,
            value:null,
            view_type:0, // Тип представления статусов.
            statustype:1,
        },
        data() {
            return {
                doc:null,
                nullStatus:{
                    "id":0,
                    "status_text":"Не выбран",
                    "color":"#cacaca",
                    },
                classDropdown:'camp-table-cell-inner camp-table-cell__status-picker camp-dropdown__wrapper camp__js-dropdown',
                selectedStatus: {},
                statusEditorKey:0,
            }
        },
        methods: {
            init() {
                const doc = document;
                this.doc=doc;
            },
            handleStatusEditedEvent()
            {                   
                //const elemId=this.getElementId
                const element = this.$refs.status_container
                //var element = document.getElementById(elemId);

                element.classList.remove('camp__is-opened');
                element.classList.remove('camp__is-active');

                //this.statusEditorKey+=1;
                console.log("Статусы отредактированы",this.statusEditorKey)
                // this.init();
                // this.initCurrentStatus();
                // this.dropdowns();
            },
            closeDropdowns(dropdownsElements) {
                dropdownsElements.forEach(function(dropdown) {
                    dropdown.parentNode.classList.remove('camp__is-opened');
                    dropdown.parentNode.classList.remove('camp__is-active');
                });
            },

            handleStatusClick(status_id) {
                console.log('Set status:', status_id,this.field,this.value,this.item_id);

                const element = this.$refs.status_container

                const selected = this.getStatuses.find(status => status.id === status_id);
                this.selectedStatus = selected || this.nullStatus;
                console.log('selectedStatus:', this.selectedStatus );
                //var element = document.getElementById(elemId);
                

                element.classList.remove('camp__is-opened');
                element.classList.remove('camp__is-active');


                this.editItem({
                    obj: {
                        group_id:this.group_id,
                        item_id:this.item_id,
                        action:this.item_id===0 ? 'add' : 'edit',
                        val: status_id,
                        key_field:this.field,
                    }

                }).then(() => {
                    this.$store.commit('crm/setUiaction', {action:'close_dropdowns'});

                })
                .catch(() => {
                    this.$store.commit('crm/setUiaction', {action:'close_dropdowns'});

                })
                .finally(() => {
                    this.$store.commit('crm/setUiaction', {action:'close_dropdowns'});
                })


            },
            dropdowns(){
                const doc=this.doc;
                const closeDropdowns=this.closeDropdowns;
                // Dropdowns
                const dropdowns = doc.querySelectorAll('.camp__js-dropdown .camp-table-cell__status-picker_display');
                const dropdownsStatuses = doc.querySelectorAll('.camp-dropdown .camp-status');


                if (dropdowns) {
                    dropdowns.forEach(function(dropdown) {
                        dropdown.addEventListener('click', function(e) {


                            let tableWrapper = this.closest('.camp-table__wrapper');

                            if (this.classList.contains('camp__is-opened') && this.classList.contains('camp__is-active')) {

                                closeDropdowns(dropdowns);
                                this.parentNode.classList.remove('camp__is-opened');
                                this.parentNode.classList.remove('camp__is-active');

                                if (tableWrapper) {
                                    tableWrapper.classList.remove('camp__is-upper');
                                }
                            } else {
                                closeDropdowns(dropdowns);
                                this.parentNode.classList.add('camp__is-opened');
                                this.parentNode.classList.add('camp__is-active');
                                if (tableWrapper) {
                                    tableWrapper.classList.add('camp__is-upper');
                                }
                            }

                        });
                    });
                }
                // if (dropdownsStatuses) {
                //     // console.log(dropdownsStatuses);
                //     dropdownsStatuses.forEach(function(dropdownStatus) {
                //         dropdownStatus.addEventListener('click', function() {
                //             const currentDropdown = this.closest('.camp__js-dropdown');
                //             const currentStatusText = this.innerHTML;
                //             const currentStatusColor = this.style.backgroundColor;
                //
                //             currentDropdown.querySelector('.camp-table-cell__status-picker_display').style.backgroundColor = currentStatusColor;
                //             currentDropdown.querySelector('.camp-table-cell__status-picker_text').innerHTML = currentStatusText;
                //             currentDropdown.classList.remove('camp__is-opened');
                //             currentDropdown.classList.remove('camp__is-active');
                //
                //
                //         });
                //     });
                //
                // }


                const dropdownsStatusesLevels = doc.querySelectorAll('.camp-dropdown-statuses');

                if (dropdownsStatusesLevels) {
                    dropdownsStatusesLevels.forEach(function(dropdownsStatusesLevel) {
                        dropdownsStatusesLevel.addEventListener('click', function(e) {
                            let target = e.target;
                            if (!target.classList.contains('camp__js-dropdown-btn')) return;

                            let action = target.getAttribute('data-action');
                            let level = target.closest('.camp-dropdown__level');

                            if (action === 'edit') {
                                level.classList.add('camp__is-hidden');
                                level.nextElementSibling.classList.remove('camp__is-hidden');
                            } else {
                                target.closest('.camp__js-dropdown').classList.remove('camp__is-opened');
                                target.closest('.camp__js-dropdown').classList.remove('camp__is-active');
                                setTimeout(function() {
                                    level.classList.add('camp__is-hidden');
                                    level.previousElementSibling.classList.remove('camp__is-hidden');
                                }, 200);

                            }
                        });
                    });
                }
            },
            getStatusData(){
                // Поиск выбранного статуса по значению value
                const selected = this.getStatuses.find(status => status.id === parseInt(this.value));
                // Если выбранный статус не найден, установим серый статус
                return this.selectedStatus = selected || this.nullStatus;

            },
            getStatusColor(){
                return this.getStatusData().color;
            },
            getStatusText(){
                return this.getStatusData().status_text
            },
            initCurrentStatus(){
                this.getStatusData();
            },
            ...mapActions({
                editItem: 'crm/editItem',
                editStatus: 'crm/editStatus',
            })
        },
        computed: {
            ...mapGetters({
                getStatusValues: 'crm/getStatusValues',
                getStatusEditor: 'crm/getStatusEditor',
            }),
            getClassDropdown(){
                return this.classDropdown;
            },
            getStatuses() {
                if (this.getStatusValues && this.field in this.getStatusValues) {
                    const listStauses = this.getStatusValues[this.field];
                    return listStauses;
                } else {
                    // Возвращаем значение по умолчанию или пустой массив, если поле не существует
                    return []; // или значение по умолчанию, например: return defaultValue;
                }
            },
            getElementId(){
                return 'dropdown_status_'+this.field+'_'+this.item_id;
            },
            getStatusEditorKey(){
                return this.statusEditorKey
            }
        },
        mounted() {
            this.init();
            this.initCurrentStatus();
            this.dropdowns();
            //console.log("Statuses",this.field,this.getStatuses);
        }
    }
</script>

<style scoped>

</style>
