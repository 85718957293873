<template>
  <button
    :class="['button', classModificator]"
    type="button"
    :disabled="!isDisabled"
  >
    <span v-if="!preloader">
      <slot></slot>
    </span>
    <BaseButtonLoader
      v-else
      class="button__loader"
    />
  </button>
</template>

<script>
export default {
  name: 'BaseButton',
  props: {
    classModificator: {
      type: String,
      default: ''
    },
    isDisabled: {
      type: Boolean,
      default: true
    },
    preloader: {
      type: Boolean,
      defalut: false
    }
  }
}
</script>

<style lang="scss">
@import 'src/scss/utils/mixins';
@import 'src/scss/base/project/var';

.button {

  font-family: $font-primary;
  position: relative;
  overflow: hidden;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 20px;
  font-size: 15px;
  font-weight: 400;
  @include breakpoint(xl) {
    font-size: 14px;
  }
  @include breakpoint(md) {
    font-size: 15px;
  }
  @include breakpoint(xs) {
    font-size: 14px;
  }

  span {
    z-index: 1;
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &--setting {
    height: 30px;
    padding: 0 15px;
    background-color: $gray-100;
    color: $gray-450;
    border-radius: 10px;
    font-size: 14px;
    line-height: 14px;
    font-family: $font-primary;
    font-weight: 500;
    transition: all $transition-duration ease;
    border: 1px solid transparent;
    font-size: 14px;
    line-height: 11px;
    @include breakpoint(xl) {
      font-size: 13px;
      line-height: 13px;
    }
    @include breakpoint(md) {
      font-size: 14px;
      line-height: 14px;
    }

    &Transparent {
      background-color: transparent;
    }

    &Red {
      color: $red;
      &:hover {
        border-color: $red;
        box-shadow: 0px 2px 10px 0px rgba($black, .07);
      }
    }
    &Green {
      color: $green-100;
      &:hover {
        border-color: $green-100;
        box-shadow: 0px 2px 10px 0px rgba($black, .07);
      }
    }

    &Active {
      &Fill {
        background-color: $green-100;
        color: $white;
        &:hover {
          box-shadow: 0px 2px 10px 0px rgba($black, .07);
        }
      }
    }
  }

  &--form{
    border-radius: 15px;
    box-shadow: 0px 2px 4px 0px rgba($black, 0.15) inset;
    background-color: $gray-200;
    color: $white;
    font-weight: 900;
    font-size: 20px;
    cursor: default;
    &::before {
      border-radius: 15px;
      overflow: hidden;
      position: absolute;
      content: '';
      opacity: 0;
      width: 100%;
      height: 100%;
      transition: all $transition-duration ease;
    }
  }

  &--form-ready {
    cursor: pointer;
    &::before {
      opacity: 1;
      background: linear-gradient(284deg, $green-200 -0.77%, $dark-green-300 86.36%), $dark-green-100;
      box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.07);
    }
    &:active {
      &::before {
        background: linear-gradient(285deg, $green-200 -11.44%, $dark-green-300 62.87%), $dark-green-100;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15) inset;
      }
    }
  }

  &--primary {
    font-size: 15px;
    line-height: 15px;
    box-shadow: 0px 5px 10px 0px rgba($black, 0.07);
    background-color: $green-100;
    color: $white;
    font-weight: 400;
    @include breakpoint(xl) {
      font-size: 13px;
      line-height: 13px;
    }
    @include breakpoint(md) {
      font-size: 15px;
      line-height: 15px;
    }
    @include breakpoint(sm) {
      font-size: 14px;
      line-height: 14px;
    }
    &::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 0;
      background-image: linear-gradient(298deg, $green-200 -16.49%, $green-100 83.77%);
      box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.07);
      opacity: 0;
      transition: opacity $transition-duration ease;
    }
    &:hover {
      &::before {
        opacity: 1;
      }
    }
    &:active {
      &::before {
        opacity: 0;
      }
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15) inset;
    }
  }

  &--transparent {
    border: 1px solid $black;
    background-color: transparent;
    transition: all $transition-duration ease;
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.07);
    &:hover {
      border-color: transparent;
    }
    &:active { 
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15) inset;
    }
  }

  &--form {
    height: 60px;
    @include breakpoint(xs) {
      height: 40px;
    }
  }

  &--bs {
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.07);
    &:active { 
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15) inset;
    }
  }

  &--edit, &--hamburger {
    display: flex;
    background-color: $white;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    justify-content: center;
    align-items: center;
    filter: drop-shadow(0px 5px 10px rgba(0, 0, 0, 0.10));
    @include breakpoint(xl) {
      width: 30px;
      height: 30px;
    }
    @include breakpoint(md) {
      width: 40px;
      height: 40px;
    }
  }

  &--edit {
    svg {
      position: relative;
      top: -2px;
      right: -2px;
    }
  }

  &--black {
    background-color: $black;
    color: $white;
  }

  &--green {
    border-color: $green-100;
    color: $green-100;
  }

  &--brs-43 {
    border-radius: 43px;
  }
  &--brs-20 {
    border-radius: 20px;
  }

  &--w-140 {
    max-width: 130px;
  }

  &--h-40 {
    height: 40px;
  }

  &--h-60 {
    height: 60px;
  }
  &--h-xl-50 {
    @include breakpoint(xl) {
      height: 50px;
    }
  }
  &--h-sm-60 {
    @include breakpoint(sm) {
      height: 60px;
    }
  }

  &--h-30 {
    height: 30px;
  }

}

</style>