<template>
    <BasePopup>

        <div class="PBSingleProductLeaderSearchProgressbarPopup">

            <header class="PBSingleProductLeaderSearchProgressbarPopup__header">
                <div class="PBSingleProductLeaderSearchProgressbarPopup__titlePrimary titlePrimary titlePrimary--small">Ручной запуск ценового бидера по поиску лидера успешно запущен в работу</div>
                <div class="PBSingleProductLeaderSearchProgressbarPopup__subtitle">Дождитесь окончания его работы, чтобы увидеть результат</div>
            </header>

            <div
                class="PBSingleProductLeaderSearchProgressbarPopup__progress"
                :class="{'PBSingleProductLeaderSearchProgressbarPopup__progress--half': half}"
            >
                <span>{{percent}}%</span>
                <div class="PBSingleProductLeaderSearchProgressbarPopup__progressBar" :style="`width: ${percent}%;`"></div>
            </div>

        </div>
    </BasePopup>

</template>

<script>

import {mapActions, mapState} from "vuex";

export default {
    name: 'PBSingleProductLeaderSearchProgressbarPopup',
    data() {
        return {
            done: false,
            percent: 10,
            selectedDays:30,
            sku: 0,
            orders: 0,
            price: 0,
            maxPrice: 0,
            name: '',
            discount: 0,
            half: false,
            isStartedStatistic :false,
            productMinPrice:0,
            productMaxPrice:0,
            productDiscount:0,
            interval:null,
        }
    },
    props: {
        product_id: 0
    },
    mounted(){
        this.incrementPercent();

    },
    computed: {
        ...mapState({
            productLeaderGet: state => state.priceBidder.leaderData,
            isStatisticRunning: state => state.priceBidder.isStatisticRunning,
        }),
    },
    watch:{
        productLeaderGet(newVal)
        {

            console.log("productLeader:",newVal);

        },

    },
    methods: {
        ...mapActions({
            openPopup: 'popup/openPopup',
            closePopup: 'popup/closePopup',
            getProductLeader: 'priceBidder/getLeadersByDay',
            forceRunBidder: 'priceBidder/runStatisticService',
            getSingleProduct: 'priceBidder/getSingleProduct',
        }),
        openLeaderPopUp()
        {
            const prodLeader=this.productLeaderGet;

            this.openPopup(
                {
                    component: 'PBSingleProductLeaderSearchResultPopup',
                    props: {
                        minPrice:prodLeader?.data?.price,
                        maxPrice:parseFloat ((parseFloat(prodLeader?.data?.price) + parseFloat(prodLeader?.data?.price) * parseFloat(prodLeader?.data?.discount)/100).toFixed(2)),
                        discount:prodLeader?.data?.product_discount,
                        sku: prodLeader?.data?.product_id,
                        leaderFound:true,
                        productMinPrice:prodLeader?.data?.product_min_price,
                        productMaxPrice:prodLeader?.data?.product_max_price,
                        productDiscount:prodLeader?.data?.product_discount,
                    }
                }
            )

        },
        findLeaderPopup(){
            this.getProductLeader({
                product_id: this.product_id,
                countdays:  30,
            }).then(r=>{
                clearInterval(this.interval);
                console.log("Product Leader found",r)
                this.done = true;
                this.$store.commit('priceBidder/setIsStatisticRunning', false);

                //Получаем новые данные для карточки товара
                this.getSingleProduct(this.product_id)

                this.closePopup();
                //Открываем Попап с результатами поиска лидера
                this.openLeaderPopUp();

            }).catch(reason => {
                clearInterval(this.interval);
                console.log("Error", reason)
                this.done = true;
                this.$store.commit('priceBidder/setIsStatisticRunning', false);
                this.closePopup()

            })
        },
        async startStatisticService(){
            console.log("forceRunBidder started");
            await this.forceRunBidder({product_id:this.product_id}).then(r=>{
                //this.closePopup()

                console.log("rFind",r)

                    this.findLeaderPopup()

            }).catch(error => {

                //console.log("Error", error)
                this.findLeaderPopup()
            })
        },

        incrementPercent() {
            this.interval = setInterval(() => {
                if (this.percent < 99) {
                    this.percent += 1;
                    if (this.percent > 2) {
                        if (!this.isStartedStatistic) {

                            //Если еще не запущена статистика
                            console.log("forceRunBidder isRunning:",this.isStatisticRunning);
                            if(!this.isStatisticRunning)
                            {
                                this.startStatisticService()
                            }
                            this.isStartedStatistic=true
                        }
                    }
                    if (this.percent > 50) {

                        if(!this.half)
                        {
                            this.half=true
                        }
                    }


                }
                else {
                    clearInterval(this.interval);

                    if(!this.done) {
                        //this.findLeaderPopup()
                    }
                    this.done = true;

                }
            }, 200);
        }
    }
}
</script>

<style lang="scss" scoped>
@import 'src/scss/utils/mixins.scss';
@import 'src/scss/base/project/var.scss';

.PBSingleProductLeaderSearchProgressbarPopup {

    width: 600px;
    max-width: 100%;
    text-align: center;
    border-radius: 20px;
    overflow: hidden;
    @include breakpoint(md) {
        width: 340px;
    }
    @include breakpoint(sm) {
        width: 320px;
    }
    @include breakpoint(xs) {
        width: 280px;
    }

    &__header {
        padding: 37px 60px 30px;
        @include breakpoint(md) {
            padding: 50px 30px;
        }
        @include breakpoint(sm) {
            padding-left: 20px;
            padding-right: 20px;
        }
        @include breakpoint(sm) {
            padding-left: 15px;
            padding-right: 15px;
        }
    }
    &__titlePrimary {
        margin-bottom: 10px;
        @include breakpoint(md) {
            margin-bottom: 20px;
        }
    }
    &__subtitle {
        font-size: 14px;
        line-height: 14px;
        @include breakpoint(xl) {
            font-size: 13px;
            line-height: 13px;
        }
        @include breakpoint(md) {
            font-size: 14px;
            line-height: 14px;
        }
    }
    &__progress {
        height: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        @include breakpoint(md) {
            height: 50px;
        }
        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            box-shadow: 0 2px 4px 0 rgba($black,.15) inset;
            z-index: 1;
        }
        &--half {
            span {
                color: $white;
            }
        }
        span {
            position: relative;
            z-index: 1;
            @include breakpoint(xl) {
                font-size: 14px;
            }
            @include breakpoint(md) {
                font-size: 16px;
            }
        }
        &Bar {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            background-image: $progressbar-bg;
            transition: all $transition-duration ease;
        }
    }
}

</style>
