<template>
  <div class="productSetting" v-show="!sidebarRollUp">

    <div class="productSetting__switcherArea">
      <div class="productSetting__switcherAreaField">
        <BaseSwitcher
          class="productSetting__switcher"
          :name="'Включить репрайсер'"
          :classModificator="'switcher--productSetting'"
          :active="singleProduct.bidder===1"
          @click="clickBidderRunSwitcher"
        />
        <BaseTooltip
          :content="'Состояние бидера конкретно для этого товара – включен или выключен. Если бидер выключен, то скрипт установки цены на основании лидера продаж конкурентов для этого товара не сработает.'"
          :small="true"
        />
      </div>
      <div class="productSetting__runScript">
        <span>Запуск скрипта по поиску лидера</span>
        <div class="productSetting__buttonWrap productSetting__buttonWrap--runScript">
          <BaseButton
            @click="findLeader()"
            class="productSetting__button button--primary"
          >
            Искать
          </BaseButton>

          <BaseTooltip
            :content="'Эта кнопка запускает работу бидера по поиску лидера именно для этого товара прямо сейчас, не дожидаясь следующего автоматического цикла, который происходит в 12.00 и в 00.00. Скрипт ищет лидера продаж конкурентов на основании статистики по ключевым запросам этого товара и устанавливает на товар цену и скидку лидера конкурентов минус 10 руб.'"
            :small="true"
          />
        </div>
      </div>
    </div>

    <div class="productSetting__separator separator"></div>

    <div class="productSetting__fieldset">
      <div class="productSetting__field">

          <BaseInputEdit
              class=""
              :label="'Мин. цена'"
              :labelClass="'inputEdit__label--productSetting'"
              :controlClass="'inputEdit__control--productSetting'"
              v-model="min_price"
          />

          <BaseInputEdit
              class=""
              :label="'Макс. цена'"
              :labelClass="'inputEdit__label--productSetting'"
              :controlClass="'inputEdit__control--productSetting'"
              v-model="max_price"
          />

      </div>
      <div class="productSetting__buttonWrap">
        <BaseButton
          class="productSetting__button productSetting__button--save button--primary"
          @click="saveParamBidder()"
        >
          Сохранить
        </BaseButton>

        <BaseTooltip
          :content="'Этот ценовой диапазон учитывается (цена на ваш товар не выйдет за эти пределы) при отборе 100 товаров конкурентов по каждому ключевому запросу. На основании этих 100 товаров конкурентов в итоге выбирается лидер продаж конкурентов для определения оптимальной цены на товар'"
          :small="true"
        />
      </div>
    </div>

    <div class="productSetting__separator separator"></div>

    <div class="productSetting__switcherArea">
      <div class="productSetting__switcherAreaField">
        <BaseSwitcher
          class="productSetting__switcher"
          :name="'Приоритет автоматической установки цены'"
          :active="getAutoPrice"
          :classModificator="'switcher--productSetting'"
          @click="switchAutoPrice"
        />
        <BaseTooltip
          :content="'При установке цены вручную она будет перезаписана при следующем цикле автоматической установки цены на основании лидера продаж конкурентов'"
          :small="true"
        />
      </div>
      <div class="productSetting__switcherAreaField">
        <BaseSwitcher
          class="productSetting__switcher"
          :name="'Приоритет ручной установки цены'"
          :active="getManualPrice"
          :classModificator="'switcher--productSetting'"
          @click="switchAutoPrice"
        />
        <BaseTooltip
          :content="'Автоматический цикл установки цены на основании лидера продаж конкурентов не будет перезаписывать цену. Установленная вручную цена сохраняется'"
          :small="true"
        />
      </div>
    </div>

    <div class="productSetting__separator separator"></div>

    <div class="productSetting__fieldset">
      <div class="productSetting__field">
        <BaseInputEdit
          class=""
          :label="'Цена вручную'"
          :labelClass="'inputEdit__label--productSetting'"
          :controlClass="'inputEdit__control--productSetting'"
          @change="calcManual"
          v-model="manual_price"
        />

        <BaseInputEdit
          class=""
          :label="'Скидка, %'"
          :labelClass="'inputEdit__label--productSetting'"
          :controlClass="'inputEdit__control--productSetting'"
          @change="calcManual"
          v-model="discount"
        />
      </div>
      <div class="productSetting__totalPrice">Итоговая цена: {{resultPrice}} ₽</div>
      <div class="productSetting__buttonWrap">
        <BaseButton
          class="productSetting__button productSetting__button--save button--primary"
          @click="saveManual"
        >
          Сохранить
        </BaseButton>

        <BaseTooltip
          :content="'Устанавливает цену на товар вне зависимости от глобального и локального (для конкретного товара) состояния бидера. Независимо от ценового диапазона'"
          :small="true"
        />
      </div>

    </div>
  </div>
</template>

<script>
import {mapState,mapActions} from 'vuex'
import BaseInputEdit from "./Global/BaseInputEdit";


export default {
  components: {BaseInputEdit},
  data() {
    return {
        autoSetPrice:true,
        min_price:0,
        max_price:0,
        manual_price:0,
        discount:0,
        resultPrice:0,
        timeLeftToBidderInfo:0,
        bidderUpdateTime:3600,//При запуске биддера время ожидан
    }
  },
  computed: {
    ...mapState({
      sidebarRollUp: state => state.sidebarRollUp,
      singleProduct: state => state.priceBidder.singleProduct,
      productBidder: state => state.priceBidder.productBidder,
      isRunConfirmed: state => state.priceBidder.isRunConfirmed,
    }),
    getAutoPrice()
    {
       let isAutoPrice=false
       if(this.singleProduct.bidder===1) {
	       isAutoPrice=this.autoSetPrice
       }
       return isAutoPrice
    },
    getManualPrice()
    {
       let isManualPrice=false
       if(this.singleProduct.bidder===1) {
	       isManualPrice=!this.autoSetPrice
       }
       return isManualPrice
    }
  },
  methods: {
      ...mapActions({
          productList: 'priceBidder/productList',
          runBidderForProduct: 'priceBidder/runBidder',
	      stopBidderForProduct: 'priceBidder/stopBidder',
          searchLeader: 'priceBidder/searchLeader',
          updateBidderData: 'priceBidder/updateBidderData',
          setProductPrice: 'priceBidder/setProductPrice',
	      openPopup: 'popup/openPopup',
	      closePopup: 'popup/closePopup',
      }),
      findLeader(){
        const product_id=this.$route.params.id


          if (this.singleProduct?.bidder_min_price > 0 &&
              this.singleProduct?.bidder_max_price > 0 &&
              this.singleProduct?.keywords.length > 0
          ) {

              this.singleProduct.bidder =1;
              this.autoSetPrice=true
              this.setProductBidderData(this.singleProduct)

              this.runBidder()

              //this.searchLeader(product_id)
              console.log("Поиск лидера", product_id);

              if(this.timeLeftToBidderInfo>0) {
                  this.openPopup(
                      {
                          component: 'PBSingleProductFirstRunPopup',
                          props: {
                              time: this.timeLeftToBidderInfo,
                          }

                      }
                  )
              }
              else {
                  this.autoSetPrice=true

                  this.openPopup(
                      {
                          component: 'PBSingleProductLeaderSearchProgressbarPopup',
                          props: {
                              percent: 50,
                              product_id:this.$route.params.id,
                              //text: 'Скрипт поиска лидера по ключевым словам запущен'
                          }
                      }
                  )
              }
          }
          else {

              this.openPopup(
                  {
                      component: 'SettingsAlertPopup',
                      props: {
                          text: 'Для запуска репрайсера необходимо указать ключевые слова, а также минимальную и максимальную цену товара'
                      }
                  }
              )

          }


      },
	  switchAutoPrice()
      {
          if(this.singleProduct.bidder===1) {
	          //const autoPrice = !this.productBidder.auto;
              this.autoSetPrice=!this.autoSetPrice
	          const vm = {
		          id: this.$route.params.id,
		          keywords: this.singleProduct?.keywords ?? null,
		          auto: this.autoSetPrice,
                  bidder_min_price: this.min_price,
				  bidder_max_price: this.max_price,
	          }
	          this.updateBidderData(vm);


	          this.$store.commit('priceBidder/updateProductBidder', this.productBidder);

              if(this.autoSetPrice)
              {
                  this.openPopup(
                      {
                          component: 'PBSingleProductSelectedPricePriorityPopup',
                          props:{
                              priority:'автоматический',
                          }
                      }
                  )
              }
              else {
                  this.openPopup(
                      {
                          component: 'PBSingleProductSelectedPricePriorityPopup',
                          props:{
                              priority:'ручной',
                          }
                      }
                  )

              }


          }
          else{
	          this.openPopup(
		          {
			          component: 'SettingsAlertPopup',
			          props: {
				          text: 'Необходимо, чтобы репрайсер был запущен'
			          }
		          }
	          )
          }

      },
      clickBidderRunSwitcher(){

          if(this.singleProduct.bidder>1) {
              this.openPopup(
                  {
                      component: 'PBSingleProductBidderStartConfirmPopup',
                      props: {
                          time:this.bidderUpdateTime,
                      },
                  }
              )
          }
          else {

              this.stopBidder()
              this.singleProduct.bidder =2;
              this.setProductBidderData(this.singleProduct)
              this.openPopup(
                  {
                      component: 'SettingsAlertPopup',
                      props: {
                          text: 'Репрайсер остановлен для текущего товара'
                      }
                  }
              )
          }
      },
      setBidderStatus(){


	      let newSingleProduct = this.singleProduct

	      if(newSingleProduct.bidder>1) {


              if (newSingleProduct?.bidder_min_price > 0 &&
                  newSingleProduct?.bidder_max_price > 0 &&
                  this.singleProduct?.keywords.length > 0
              ) {

                  if(!this.singleProduct.bidder_has_history) {

                      this.openPopup(
                          {
                              component: 'PBSingleProductFirstRunPopup',
                              props: {
                                  time: 3600,
                              }
                          }
                      )
                  }


	              newSingleProduct.bidder =1;
	              this.setProductBidderData(newSingleProduct)
	              this.runBidder()
                  this.autoSetPrice=true

              }
              else {
                  this.openPopup(
                      {
                          component: 'SettingsAlertPopup',
                          props: {
                              text: 'Для запуска репрайсера необходимо указать ключевые слова, а также минимальную и максимальную цену товара'
                          }
                      }
                  )

              }

	      }
	      else {


	      }

      },
	  saveParamBidder () {

		  const keywords = this.singleProduct?.keywords ?? null
		  const bidderStatus = this.singleProduct?.bidder ?? 2
		  let newSingleProduct = this.singleProduct

		  const vm = {
			  product_id: this.$route.params.id,
			  keywords: keywords,
			  auto:this.productBidder?.auto,
			  min_price: this.min_price,
			  max_price: this.max_price,
		  }

		  newSingleProduct.bidder_min_price = this.min_price
		  newSingleProduct.bidder_max_price = this.max_price
          newSingleProduct.auto=this.autoSetPrice

		  //console.log( 'productBidder bidderStatus: ' , newSingleProduct.bidder);
		  this.setProductBidderData(newSingleProduct)
          this.updateBidderData(newSingleProduct);
	  },
      runBidder(){
	      const keywords = this.singleProduct?.keywords ?? null
	      const bidderStatus = this.singleProduct?.bidder ?? 2

	      const vm = {
		      product_id: this.$route.params.id,
		      keywords: keywords,
              auto:this.autoSetPrice,
		      min_price: this.min_price,
		      max_price: this.max_price,
	      }

	      //console.log('Bidder', bidderStatus)
	      if (bidderStatus) {

		      this.runBidderForProduct(vm)

	      }
      },

      stopBidder(){
	      const vm = {
		      product_id: this.$route.params.id,
		  }
	      this.stopBidderForProduct(vm)

      },

      setProductBidderData(dataProduct) {
          const payload={
              data:{
                  data:{
                      product:dataProduct
                  }
              }
          } ;

          this.$store.commit('priceBidder/setSingleProduct', payload);

      },
      calcManual(){

          if (this.discount > 99) {
              this.discount = 99;
          } else if (this.discount < 0) {
              this.discount = 0;
          } else {

          }

          this.resultPrice = Math.ceil( parseFloat((this.manual_price - (this.manual_price * this.discount) / 100))).toFixed(0);

      },
      saveManual(){


         this.calcManual()

         this.setProductPrice(
             {
                 product_id: this.$route.params.id,
                 new_price : this.manual_price,
                 discount: this.discount
             }
         );
          this.autoSetPrice=false


          const vm = {
              id: this.$route.params.id,
              auto: this.autoSetPrice,
          }
          this.updateBidderData(vm);


          this.openPopup(
              {
                  component: 'SettingsAlertPopup',
                  props: {
                      text: 'Цена успешно изменена. \n Новая цена: '+this.resultPrice+ ' руб \n Скидка : '+ this.discount + ' % '
                  }
              }
          )
	  }

  },

  mounted(){

  },
  watch: {
    singleProduct(newValue) {
        console.log("newVal",newValue)
        this.min_price=newValue?.bidder_min_price
        this.max_price=newValue?.bidder_max_price
        this.manual_price=newValue?.max_price ?? 0
        this.discount=newValue?.discount ?? 0
        if (newValue?.bidder===1) {
            this.autoSetPrice = newValue?.auto ?? true
        }
        else{
            this.autoSetPrice = true
        }
        this.timeLeftToBidderInfo=newValue?.wb_bidder?.seconds_to_info ?? 0
        this.calcManual()
    },
    isRunConfirmed(newValue)
    {
        if (newValue===true) {
            console.log("run confirmed")
            //Возвращаем обратно в false для следующего вызова окна
            this.$store.commit('priceBidder/setIsRunConfirmed', false);

            this.setBidderStatus()
        }
    }
  },

}
</script>

<style lang="scss" scoped>
@import 'src/scss/utils/mixins.scss';
@import 'src/scss/base/project/var.scss';

.productSetting {

  font-family: $font-primary;

  &__buttonWrap {
    position: relative;
    max-width: max-content;
    &--runScript {
      max-width: 100%;
      flex: 0 0 81px;
    }
  }

  &__runScript {
    display: flex;
    justify-content: space-between;
    align-items: center;
    span {
      font-size: 14px;
      line-height: 14px;
      flex: 1;
      margin-right: 10px;
    }
  }

  &__button {
    height: 30px;
    font-size: 14px;
    line-height: 14px;
    font-weight: 500;
    height: 24px;
    &--save {
      width: 109px;
    }
    @include breakpoint(md) {
      height: 30px;
    }
  }

  &__field {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    > div {
      flex: 0 0 113px;
      &:first-child {
        @include breakpoint(md) {
          margin-right: 20px;
        }
      }
      @include breakpoint(md) {
        flex: 1;
        max-width: 160px;
      }
      @include breakpoint(sm) {
        max-width: 140px;
      }
    }
  }

  &__switcherArea {
    margin-right: 25px;
    &Field {
      position: relative;

      &:first-child {
        margin-bottom: 20px;
      }
    }
  }

  &__totalPrice {
    margin: 10px 0;
    line-height: 15px;
    font-size: 14px;
    line-height: 14px;
  }

  &__separator {
    margin: 20px 0;
  }

}
</style>
