export default {
  namespaced: true,

  state: () => ({
    isLoading: true,
    userList: [],
    selectedUser: [],
    columnTitles: {
      users: [
        {
          sortKey: "name",
          label: "Логин",
          svgState: false,
        },
        {
          sortKey: "phone",
          label: "Телефон",
          svgState: false,
        },
        {
          sortKey: "id",
          label: "ID клиента",
          svgState: false,
        },
        {
          sortKey: "email",
          label: "Почта",
          svgState: false,
        },
        {
          sortKey: "created_at",
          label: "Создание",
          svgState: false,
        },
        {
          sortKey: "last_login",
          label: "Посл. вход",
          svgState: false,
        },
        {
          sortKey: "count_enter",
          label: "Кол-во входов",
          svgState: false,
        },
        {
          sortKey: "manager",
          label: "Менеджер",
          svgState: false,
        },
        {
          sortKey: "confirmed",
          label: "Статус",
          svgState: false,
        },
        {
          sortKey: "utm",
          label: "UTM",
          svgState: false,
        },
        {
          sortKey: "role",
          label: "Роль",
          svgState: false,
        },
        {
          sortKey: "legal",
          label: "Юр. лицо",
          svgState: false,
        },
      ],
    },
    userParams: {
      limit:100,
      sort: 'created_at',
      skip: 0,
      period: '',
      date_form: '',
      date_to: '',
      last_login: false,
      role: '',
      status: '',
      search: '',
      search_value: '',
      search_value_multiple: [],
    },
    updateParams: {
      user_id: '',
      email: '',
      phone: '',
      first_name: '',
      last_name: '',
      role: '',
      status: null,
      currentUser: '',
    }
  }),

  mutations: {
    setIsLoading(state, payload) {
      state.isLoading = payload;
    },
    setUserList(state, payload) {
      state.userList = payload;
    },
    setSelectedUser(state, payload) {
      state.selectedUser = payload;
    },
    setSortUserList(state, payload) {
      state.userList = payload
    },
    setUserParams(state, payload) {
      state.userParams = payload
    },
    setUpdateParams(state, payload) {
      state.updateParams = payload
    }
  },
  
  getters: {

  },

  actions: {
    getUserList({ commit }, userParams) {
      commit("setIsLoading", true);
      return new Promise((resolve) => {
        YC.adminUserApi.getUserList(userParams)
          .then((usersList) => {
            var data = usersList.data.data;
            for (let i = 0; i < data.length; i++) {
              data[i]['email'] === null ? data[i]['email'] = '' : '';
            }
            commit("setUserList", data);
            resolve();
          })
          .finally(() => {
            commit("setIsLoading", false);
          })
      });
    },
    getSelectedUser({ commit }, id) {
      commit("setSelectedUser", []);
      return new Promise((resolve) => {
        YC.adminUserApi.getSelectedUser(id)
          .then((user) => {
            console.log(user);
            commit("setSelectedUser", user.data.stores);
            resolve();
          })
          .finally(() => {
          
          })
      });
    },
    postUpdateUser({commit}, params) {
      return new Promise((resolve) => {
        YC.adminUserApi.post('admin/users/update/' + params.updateParams.user_id, params.updateParams)
          .then((response) => {
            resolve();
          })
          .finally(() => {
            return new Promise((resolve) => {
              YC.adminUserApi.getUserList(params.userParams)
                .then((usersList) => {
                  var data = usersList.data.data;
                  for (let i = 0; i < data.length; i++) {
                    data[i]['email'] === null ? data[i]['email'] = '' : '';
                  }
                  commit("setUserList", data);
                  resolve();
                })
                .finally(() => {
                })
            });
          })
      });
    },
    postDeleteUser({ commit }, id) {
      //commit("setIsLoading", true);
      return new Promise((resolve) => {
        YC.adminUserApi.post('admin/users/delete/'+id,{user:id})
          .then((response) => {
            console.log(response);

            resolve();
          })
          .finally(() => {
              //commit("setIsLoading", false);
          })
      });
    },
    setSortUserList({ commit }, data) {
      const userList = data.userList;
      const sort_item = data.sort_item;
      const sort_direction = data.sort_direction;

      userList.forEach(list => {
        list.name =  !list.name ? '' : list.name;
        list.email = !list.email ? '' : list.email;
        list.last_login = !list.last_login ? '' : list.last_login;
        list.count_enter = !list.count_enter ? '' : list.count_enter;
        list.created_at = !list.created_at ? '' : list.created_at;
        list.utm = !list.utm ? '' : list.utm;
        list.legal = !list.legal ? '' : list.legal;
        list.manager = !list.manager ? '' : list.manager;
        list.phone = !list.phone ? '' : list.phone;
        list.role = !list.role ? '' : list.role;
      });

      userList.sort(function(a, b) {
        if(sort_item === 'name') {
          if (sort_direction === true) {
            return b.name.localeCompare(a.name);
          } else {
            return a.name.localeCompare(b.name);
          }
        } else if (sort_item === 'phone') {
          if (sort_direction === true) {
            return b.phone - a.phone;
          } else {
            return a.phone - b.phone;
          }
        } else if (sort_item === 'id') {
          if (sort_direction === true) {
            return b.id - a.id;
          } else {
            return a.id - b.id;
          }
        } else if(sort_item === 'email') {
          if (sort_direction === true) {
            return b.email.localeCompare(a.email);
          } else {
            return a.email.localeCompare(b.email);
          }
        } else if(sort_item === 'created_at') {
          if (sort_direction === true) {
            return b.created_at.localeCompare(a.created_at);
          } else {
            return a.created_at.localeCompare(b.created_at);
          }
        } else if(sort_item === 'last_login') {
          if (sort_direction === true) {
            return b.last_login.localeCompare(a.last_login);
          } else {
            return a.last_login.localeCompare(b.last_login);
          }
        } else if(sort_item === 'count_enter') {
          if (sort_direction) {
            return b.count_enter - a.count_enter;
          } else {
            return a.count_enter - b.count_enter;
          }
        } else if(sort_item === 'manager') {
          if (sort_direction === true) {
            return b.manager.localeCompare(a.manager);
          } else {
            return a.manager.localeCompare(b.manager);
          }
        } else if(sort_item === 'confirmed') {
          if (sort_direction === true) {
            return b.confirmed - a.confirmed;
          } else {
            return a.confirmed - b.confirmed;
          }
        } else if(sort_item === 'utm') {
          if (sort_direction === true) {
            return b.utm.localeCompare(a.utm);
          } else {
            return a.utm.localeCompare(b.utm);
          }
        } else if(sort_item === 'role') {
          if (sort_direction === true) {
            return b.role.localeCompare(a.role);
          } else {
            return a.role.localeCompare(b.role);
          }
        } else if(sort_item === 'legal') {
          if (sort_direction === true) {
            return b.legal.localeCompare(a.legal);
          } else {
            return a.legal.localeCompare(b.legal);
          }
        }
      });
      commit('setSortUserList', userList);
    },
    postLoginAsUser({ commit }, data) {
      return new Promise((resolve) => {
          YC.adminUserApi.post('admin/users/login-as-user', data)
          .then((response) => {
              console.log(response);
              commit('auth/setToken', response.data.authorization.token,{ root: true })
              localStorage.setItem('token', response.data.authorization.token)
              resolve();
          })
          .finally(() => {
          
          })
      });
    },
  },
}