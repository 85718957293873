<template>
  <div class="PBBasicProductsHeader">

    <div class="PBBasicProductsHeader__container">
      <template v-if="$store.state.isMobile">
        <div class="PBBasicProductsHeader__mobileTop">

          <BaseButtonBack
            @click="$router.go(-1)"
            v-if="$store.state.isMobile"
          >
            Назад
          </BaseButtonBack>

          <BaseSearch    
            class="PBBasicProductsHeader__searchMobile" 
            :classModificator="'searchMobile'"
            :model-value="searchQuery"
            @update:model-value="setSearchQuery"
            @updateSearch="search"
          />

          <BaseButton
            :classModificator="'button--hamburger'"
            @click="openPopup({
              component: 'SidebarMobile'
            })"
          >
            <SvgUse    
              :width="23"
              :height="16"
              id="hamburger"        
            />
          </BaseButton>

        </div>
      </template>

      <Tabs        
        class="PBBasicProductsHeader__tabs"
        :tabs="tabsData"
        :favorite="true"
        @updateTab="setTab"
        @updateFavorite="setFavorite"
      />

      <div class="PBBasicProductsHeader__group">

        <div
          class="PBBasicProductsHeader__toolbar"
          v-if="!$store.state.isMobile"  
        >
          <GlobalSetting           
            class="PBBasicProductsHeader__globalSetting" 
          />

          <BaseSearch    
            class="PBBasicProductsHeader__search" 
            :model-value="searchQuery"
            @update:model-value="setSearchQuery"
            @updateSearch="search"
          />
        </div>

        <div class="PBBasicProductsHeader__filters">

          <BaseFilterDropDown   
            :label="'Показать товары'"  
            :model-value="params.limit"    
            @update:model-value="setParamsLimit"   
          />

          <BaseSorting       
            :label="'Название'"
            :model-value="sortParams.name" 
            @update:model-value="setSortParamsName"     
          />

          <BaseSorting        
            :label="'Дата'"
            :model-value="sortParams.date"   
            @update:model-value="setSortParamsDate"   
          />
        </div>
      </div>

      <template v-if="!$store.state.isMobile">
        <div class="PBBasicProductsHeader__bottom">
          <div class="PBBasicProductsHeader__bottomCol">Изображение</div>
          <div class="PBBasicProductsHeader__bottomCol">Название товара</div>
          <div class="PBBasicProductsHeader__bottomCol">Артикул</div>
          <div class="PBBasicProductsHeader__bottomCol">Приоритет</div>
          <div class="PBBasicProductsHeader__bottomCol">Граница цен <br> (Мин. - Макс.)</div>
          <div class="PBBasicProductsHeader__bottomCol">Цена до <br> скидки</div>
          <div class="PBBasicProductsHeader__bottomCol">Скидка</div>
          <div class="PBBasicProductsHeader__bottomCol">Цена после <br> скидки</div>
          <div class="PBBasicProductsHeader__bottomCol"></div>
        </div>
      </template>
    </div>

  </div>
</template>

<script>
import GlobalSetting from '@/components/GlobalSetting'
import Tabs from '@/components/Tabs.vue'
import {mapActions, mapMutations, mapState} from 'vuex'


export default {
  components: {
    Tabs,
    GlobalSetting
  },
  data() {
    return {
      tabsData: [
        {name: 'Все', value: ''},
        {name: 'Ручной', value: 'manual'},
        {name: 'Репрайсер', value: 'script'},
      ]
    }
  },
  computed: {
    ...mapState({
      searchQuery: state => state.priceBidder.searchQuery,
      params: state => state.priceBidder.params,
      sortParams: state => state.priceBidder.sortParams
    })
  },
  methods: {
    setTab(tabValue) {
      this.setParamsPriority(tabValue)
    },
    setFavorite(favoriteShow) {
      this.$store.commit('priceBidder/setParamsIsFavorite', favoriteShow)
    },
    ...mapActions({
      openPopup: 'popup/openPopup',
      searchProducts: 'priceBidder/searchProducts',
      productList: 'priceBidder/productList'
    }),
    ...mapMutations({
      setSearchQuery: 'priceBidder/setSearchQuery',
      setParamsLimit: 'priceBidder/setParamsLimit',
      setParamsPriority: 'priceBidder/setParamsPriority',
      setSortParamsName: 'priceBidder/setSortParamsName',
      setSortParamsDate: 'priceBidder/setSortParamsDate'
    }),
    search() {

      if(this.searchQuery !== '') {
        let currentLength = this.searchQuery.length
        setTimeout(() => {
          let currentLengthAtTimeOfCall = this.searchQuery.length
          if(currentLengthAtTimeOfCall === currentLength) this.searchProducts({search: this.searchQuery})
        }, 500)

      } else this.productList(this.params)
    }
  }
}
</script>

<style lang="scss">
@import 'src/scss/utils/mixins.scss';
@import 'src/scss/base/project/var.scss';

.PBBasicProductsHeader {
  z-index: 3;

  &__tabs {
    padding-left: 20px;
    display: flex;
    @include breakpoint(md) {
      padding-left: 0;
      margin: 0 auto;
      margin-bottom: 40px;
      justify-content: space-between;
      
    }
    @include breakpoint(sm) {
      flex-flow: row wrap;
      margin-bottom: 30px;
    }
  }

  &__group {
    padding: 15px 40px 0 20px;
    background-color: $white;
    z-index: 3;
    position: relative;
    margin-bottom: 25px;
    @include breakpoint(xl) {
      padding: 30px 20px 0;
      margin-bottom: 30px;;
    }
    @include breakpoint(md) {
      margin-bottom: 0;
      padding: 0 55px;
    }
    @include breakpoint(sm) {
      padding: 0;
    }
  }

  &__toolbar {
    display: flex;
    justify-content: space-between;
    margin-bottom: 22px;
  }

  &__filters {
    display: flex;
    width: 100%;
    flex: 1;
    max-width: 510px;
    justify-content: space-between;
    @include breakpoint(xl) {
      max-width: 416px; 
    }
    @include breakpoint(md) {
      margin: 0 auto;
      max-width: 100%;
    }
  }
  
  &__globalSetting {
    display: flex;
    max-width: 662px;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-right: 35px;
    @include breakpoint(xl) {
      max-width: 600px;
    }
  }

  &__search {
    flex: 1;
    max-width: 500px;
    margin-left: 10px;
    @include breakpoint(xl) {
      max-width: 370px;
    }
  }

  &__searchMobile {
    @include breakpoint(md) {
      display: block;
      flex: 1;
      max-width: 460px;
      margin: 0 10px;
    }
    .search__button {
      display: none;
    }
    .search__control {

    }
  }

  &__mobileTop {
    @include breakpoint(md) {
      margin-bottom: 30px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    @include breakpoint(xs) {
      margin-bottom: 15px;
    }
  }

  &__bottom {
    justify-content: space-between;
    text-align: center;
    font-size: 15px;
    line-height: 15px;
    display: flex;
    align-items: center;
    padding: 0 40px 10px 20px;
    @include breakpoint(xl) {
      padding-bottom: 16px;
      padding-right: 20px;
    }
    &Col {
      text-align: center;
      flex: 0 0 8.124%;
      @include breakpoint(xl) {
        font-size: 13px;
        line-height: 13px;
        flex: 0 0 9.124%;
      }
      &:first-child {
        text-align: left;
        flex: 0 0 112px;
        @include breakpoint(xl) {
          flex: 0 0 100px;
        }
      }
      &:nth-child(2) {
        flex: 0 0 25.57%;
        @include breakpoint(xl) {
          flex: 0 0 19.2012%;
        }
      }
      &:last-child {
        flex: 0 0 125px;
      }
    }
  }
}
</style>