<template>
  <div class="keywords">
    <div class="keywords__titlePrimary titlePrimary titlePrimary--small">Ключевые слова</div>
    <div class="keywords__area">

      <div class="keywords__areaInner">

        <KeywordsItem
          class="keywords__keywordItem"
          v-for="keyword in keywords"
          :key="keyword"
          :keyword="keyword"
          @remove="removeKeyword"
        />

        <KeywordsAdd
          class="keywords__keywordAdd"
          @add="addKeyword"
        />

      </div>

    </div>
  </div>
</template>

<script>
import KeywordsItem from '@/components/Keywords/KeywordsItem'
import KeywordsAdd from '@/components/Keywords/KeywordsAdd'

export default {
  props: {
    keywords: {
      type: Array,
      default: () => []
    }
  },
  components: {
    KeywordsItem,
    KeywordsAdd
  },
  methods: {
    addKeyword(newKeyword) {
      this.$emit('add', newKeyword)
    },
    removeKeyword(keyword) {
      this.$emit('remove', keyword)
    }
  }
}
</script>

<style lang="scss">
@import 'src/scss/utils/mixins.scss';
@import 'src/scss/base/project/var.scss';

.keywords {

  max-width: max-content;

  &--PBBasicProductEditPopup {
    .keywords__area {
      box-shadow: none;
      padding: 0;
    }
  }

  &__titlePrimary {
    margin-bottom: 20px;
    @include breakpoint(xl) {
      margin-bottom: 15px;
    }
    @include breakpoint(md) {
      margin-bottom: 20px;
    }
  }

   &--PBSingleProduct {
    .keywords__titlePrimary {
      font-weight: 600;
      @include breakpoint(xl) {
        font-size: 16px;
        line-height: 16px;
      }
      @include breakpoint(md) {
        font-size: 20px;
        line-height: 20px;
      }
      @include breakpoint(xs) {
        font-size: 16px;
        line-height: 16px;
      }
    }
  }

  &__area {
    box-shadow: 0px 5px 15px 0px rgba($black, 0.07);
    border-radius: 20px;
    padding: 10px;
    max-width: fit-content;
    @include breakpoint(md) {
      box-shadow: none;
      padding: 0;
    }
    &Inner {
      margin: -5px -5px 0 0;
      display: flex;
      flex-flow: row wrap;
    }
  }

  &__keywordItem, &__keywordAdd {
    margin: 5px 5px 0 0;
  }

}
</style>
