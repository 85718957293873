<template>
  <div class="singleProductHeaderMobile">
    <div class="singleProductHeaderMobile__container">

      <BaseButtonBack
        @click="$router.go(-1)"
        class="singleProductHeaderMobile__buttonBack"
      >
        Назад
      </BaseButtonBack>

      <BaseButton
        :classModificator="'singleProductHeaderMobile__button singleProductHeaderMobile__button--edit button--edit'"
        @click="openPopup({
          component: 'SidebarMobile'
        })"
      >
        <SvgUse    
          :width="22"
          :height="22"
          id="iconEditBlackInActive"        
        />
      </BaseButton>

      <div class="singleProductHeaderMobile__imageWrap">
        <img :src="singleProduct.image" :alt="singleProduct.name" class="singleProductHeaderMobile__image">
      </div>

      <div class="singleProductHeaderMobile__content">
        <div class="singleProductHeaderMobile__article">
          <span>{{singleProduct.sku}}</span>
          <BaseFavoriteIcon
            class="singleProductHeaderMobile__articleFavoriteIcon"
            :isFavorite="singleProduct.favorite"
          />
        </div>

        <div class="singleProductHeaderMobile__title">{{singleProduct.name}}</div>

        <ProductPrice    
          v-if="$route.name === 'PBSingleProduct'"    
          class="singleProductHeaderMobile__productPrice"
          :priceDiscount="singleProduct.min_price"
          :priceWithoutDiscount="singleProduct.max_price"
        />

      </div>

    </div>
  </div>
</template>

<script>
import {mapActions, mapState} from 'vuex'
import ProductPrice from '@/components/ProductPrice'
import BaseFavoriteIcon from '@/components/Global/BaseFavoriteIcon.vue';

export default {
  components: {
    ProductPrice
  },
  methods: {
    ...mapActions({
      openPopup: 'popup/openPopup'
    })
  },
  computed: {
    ...mapState({
      singleProduct: state => state.priceBidder.singleProduct
    })
  }
}
</script>

<style lang="scss" scoped>
@import 'src/scss/utils/mixins.scss';
@import 'src/scss/base/project/var.scss';

.singleProductHeaderMobile {
    text-align: center;
    z-index: 1;
    @include breakpoint(md) {
      display: block;
    }
    &__button, &__buttonBack {
      position: absolute;
      top: 0;
      &--edit {
        right: 0
      }
    }
    &__buttonBack {
      left: 0;
    }
    &__container {
      display: flex;
      position: relative;
      justify-content: center;
      align-items: center;
      @include breakpoint(sm) {
        align-items: flex-start;
      }
    }
    &__imageWrap {
      flex: 0 0 74px;
      height: 100px;
      margin-right: 20px;
      border-radius: 10px;
      overflow: hidden;
      @include breakpoint(sm) {
        flex: 0 0 80px;
        height: 110px;
        margin-top: 60px;
      }
      @include mediaMaxWidth(375) {
        flex: 0 0 66px;
        height: 90px;
      }
      @include breakpoint(xs) {
        margin-right: 5px;
      }
    }
    &__image {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    &__content {
      text-align: left;
    }
    &__title {
      margin-bottom: 10px;
      font-family: $font-secondary;
      font-size: 24px;
      line-height: 24px;
      font-weight: 400;
      max-width: 400px;
      @include breakpoint(sm) {
        font-size: 20px;
        line-height: 20px;
        max-width: 280px;
      }
    }
    &__article {
      font-size: 14px;
      line-height: 14px;
      display: flex;
      align-items: center;
      &FavoriteIcon {
        flex: 0 0 30px;
        height: 30px;
      }
      span {
        margin-right: 10px;
      }
      @include breakpoint(sm) {
        margin-bottom: 40px;
        margin-top: 8px;
      }
      @include mediaMaxWidth(375) {
        margin-bottom: 27px;
        margin-left: -8px;
        span {
          margin-right: 5px;
        }
      }
    }
    &__price {
      font-size: 15px;
      display: flex;
      justify-content: center;
      @include breakpoint(sm) {
        justify-content: flex-start;
        font-size: 14px;
        margin-bottom: 16px;
      }
      @include mediaMaxWidth(400) {
        flex-direction: column;
        margin-bottom: 0;
      }
    }
    &__priceDiscount { 
      margin-right: 30px;
      @include breakpoint(sm) {
        margin-right: 10px;
      }
      @include mediaMaxWidth(400) {
        margin-right: 0;
        margin-bottom: 5px;
      }
    }
    &__priceDiscount, &__priceWithoutDiscount {
      @include breakpoint(sm) {
        text-align: left;
        span {
          display: block;
          margin-top: 4px;
        }
      }
      @include mediaMaxWidth(400) {
        span {
          display: inline;
          margin-top: 0;
        }
      }
    }
  }
</style>