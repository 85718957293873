<template>
  <div
    :class="`instructionPopup ${classModifier ? 'instructionPopup--' + classModifier : ''}`"
  >
  
    <div 
      :class="`instructionPopup__layout ${classModifier ? 'instructionPopup__layout--' + classModifier : ''}`"
      @click="handlerCloseInstructionPopup"
    >

      <BaseButton
        class="instructionPopup__hamburger"
        :classModificator="'button--hamburger'"
        v-if="$store.state.isMobile && 
              !$store.state.auth.user.is_instructed.integration && 
              $store.state.popup.internalStepsInstructionOnMobile === 0 &&
              (this.$route.path.includes('crm') || this.$route.path === '/price_bidder')"    
        @click.stop="handlerHamburger"
      >
        <SvgUse    
          :width="23"
          :height="16"
          id="hamburger"        
        />
      </BaseButton>

      <div
        :class="`instructionPopup__container ${classModifier ? 'instructionPopup__container--' + classModifier : ''}`"
        @click.stop
      >
        <BaseCloseButton
          @click="handlerCloseInstructionPopup"
          v-if="!cannotBeClosed"
        />
        <slot></slot>
      </div>
      <div
        :class="`instructionPopup__instructionBackLight ${classModifier ? 'instructionPopup__instructionBackLight--' + classModifier : ''}`"
        :style="{'width': dashboardStatisticsColDataWidth && classModifier === 'DashboardInstructionFirstStep' ? dashboardStatisticsColDataWidth + 'px' : null}"
        v-if="showBackLight"
      >
        <div :class="`instructionPopup__instructionBackLightInner ${classModifier ? 'instructionPopup__instructionBackLightInner--' + classModifier : ''}`"></div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapMutations, mapState} from 'vuex'
export default {
  name: 'BaseInstructionPopup',
  props: {
    classModifier: {
      type: String,
      default: ''
    },
    cannotBeClosed: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dashboardStatisticsColDataWidth: null
    }
  },
  methods: {
    ...mapActions({
      closePopup: 'popup/closePopup',
      openPopup: 'popup/openPopup',
      addSetInternalStepsInstructionOnMobile: 'popup/addSetInternalStepsInstructionOnMobile'
    }),
    ...mapMutations({
      cleanQueue: 'popup/cleanQueue',
    }),
    handlerCloseInstructionPopup() {
      this.cannotBeClosed ? '' : this.closePopup()
    },
    handlerDashboardResize() {
      this.dashboardStatisticsColDataWidth = document.querySelector('.camp-db-statistics__col-data')?.clientWidth + 15
    },
    handlerHamburger() {
      if(this.$route.path === '/crm/dashboard' || this.$route.path === '/price_bidder') this.addSetInternalStepsInstructionOnMobile(1)
      this.openPopup({
        component: 'SidebarMobile',
        props: {
          instruction: true
        }
      })
    }
  },
  computed: {
    ...mapState({
      user: state => state.auth.user
    }),
    showBackLight() {
      return !this.user.is_instructed.repricer && 
              (this.classModifier.includes('PBBasicProductsInstruction') || this.classModifier.includes('PBSingleProductInstruction')) ||
              this.classModifier.includes('DashboardInstruction') || 
              this.classModifier.includes('BloggersInstruction') && !this.classModifier.includes('BloggersInstructionFirstStep')
    }
  },
  mounted() {
    //Dashboard instruction, set instructionBackLight style statisticsColData dynamic width
    if(this.$route.path === '/crm/dashboard') {
      this.dashboardStatisticsColDataWidth = document.querySelector('.camp-db-statistics__col-data')?.clientWidth + 15
      window.addEventListener('resize', this.handlerDashboardResize)
    }
  },
  unmounted() {
    window.removeEventListener('resize', this.handlerDashboardResize)
  }
}
</script>

<style lang="scss">
@import 'src/scss/base/project/var.scss';
@import 'src/scss/utils/mixins.scss';

.instructionPopup {

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  min-height: 100vh;
  overflow-y: auto;
  z-index: 9999;

  &--PBBasicProductsInstructionSecondStep,
  &--PBBasicProductsInstructionThirdStep,
  &--PBSingleProductInstructionFirstStep,
  &--PBSingleProductInstructionSecondStep,
  &--PBSingleProductInstructionThirdStep,
  &--PBSingleProductInstructionFourthStep,
  &--PBSingleProductInstructionFifthStep,
  &--PBSingleProductInstructionSixthStep,
  &--PBSingleProductInstructionSeventhStep,
  &--PBSingleProductInstructionEighthStep,
  &--DashboardInstructionFirstStep,
  &--DashboardInstructionSecondStep,
  &--DashboardInstructionThirdStep,
  &--BloggersInstructionSecondStep,
  &--BloggersInstructionThirdStep,
  &--BloggersInstructionFourthStep,
  &--BloggersInstructionFifthStep,
  &--BloggersInstructionFinalStep {
    position: absolute;
    z-index: unset;
    overflow-y: hidden;
  }

  &--firstStepInstructionPopup.openMobileSidebar {
    @include breakpoint(sm) {
      z-index: 10000;
      min-height: auto;
      overflow: visible;
      position: absolute;
      left: auto;
      right: auto;
      bottom: auto;
      left: 50%;
      transform: translateX(-50%);
      max-width: 360px;
      width: 100%;
      top: 530px;
    }
    @include breakpoint(xs) {
      max-width: 300px;
      top: 312px;
    }
  }
  &--firstStepInstructionPopup.openProfile {
    @include breakpoint(sm) {
      top: 560px;
    }
    @include breakpoint(xs) {
      top: 415px;
    }
  }

  &__instructionBackLight {
    position: absolute;
    border: 2px dashed $gray-260;
    border-radius: 20px;
    mix-blend-mode: overlay;
    &Inner {
      background-color: $white;
      border-radius: inherit;
      width: 100%;
      height: 100%;
      &--PBBasicProductsInstructionThirdStep {
        background-repeat: no-repeat;
        background-position: 64% 46%;
        background-image: url("data:image/svg+xml,%3Csvg width='25' height='26' viewBox='0 0 25 26' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M24.4224 4.86579L20.6342 1.07763C20.4511 0.894097 20.2334 0.748578 19.9939 0.649446C19.7543 0.550314 19.4974 0.499526 19.2382 0.500003C18.7316 0.500003 18.2276 0.692108 17.8421 1.07763L13.1579 5.76316H1.31579C0.96682 5.76316 0.632144 5.90179 0.385386 6.14855C0.138627 6.3953 0 6.72998 0 7.07895V24.1842C0 24.5332 0.138627 24.8679 0.385386 25.1146C0.632144 25.3614 0.96682 25.5 1.31579 25.5H18.4211C18.77 25.5 19.1047 25.3614 19.3515 25.1146C19.5982 24.8679 19.7368 24.5332 19.7368 24.1842V12.3421L24.4224 7.65658C24.8079 7.27106 25 6.76579 25 6.26053C25 5.75527 24.8079 5.25132 24.4224 4.86579ZM11.1842 17.1737L8.32631 14.3158L16.6066 6.03553L19.4645 8.89342L11.1842 17.1737ZM7.81447 15.4118L10.125 17.6855L7.89474 17.6053L7.81447 15.4118ZM17.1053 22.8684H2.63158V8.39474H10.5263L6.3421 12.5789C5.95658 12.9645 5.71316 13.6474 5.51447 14.275C5.30395 14.9329 5.26316 15.6645 5.26316 16.2092V20.2368H9.29079C9.83553 20.2368 10.7487 20.1053 11.3579 19.8553C11.9684 19.6053 12.5368 19.3987 12.9211 19.0132L17.1053 14.9737V22.8684ZM20.3947 7.96316L17.5368 5.10527L19.2382 3.40395L22.0947 6.26185L20.3947 7.96316Z' fill='%23FFD362'/%3E%3C/svg%3E%0A");
      }
    }
    &--PBBasicProductsInstructionSecondStep {
      top: 254px;
      left: 326px;
      height: 115px;
      width: calc(100% - 356px);
      @include breakpoint(xl) {
        top: 245px;
        left: 330px;
        height: 105px;
        width: calc(100% - 338px);
      }
      @include mediaMaxWidth(1367) {
        top: 258px;
      }
      @include mediaMaxWidth(1306) {
        top: 270px;
      }
    }
    &--PBBasicProductsInstructionThirdStep {
      width: 51px;
      height: 51px;
      border-radius: 15px;
      top: 287px;
      right: 97px;
      @include breakpoint(xl) {
        top: 278px;
        right: 80px;
        width: 44px;
        height: 44px;
      }
      @include mediaMaxWidth(1367) {
        top: 291px;
      }
      @include mediaMaxWidth(1306) {
        top: 304px;
      }
    }

    &--PBSingleProductInstructionFirstStep {
      width: 273px;
      height: 113px;
      top: 452px;
      left: 31px;
    }
    &--PBSingleProductInstructionSecondStep {
      width: 372px;
      height: 113px;
      left: 324px;
      bottom: 78px;
      @include breakpoint(xl) {
        height: 100px;
        bottom: 63px;
      }
      &::before {
        content: '';
        position: absolute;
        top: 120px;
        left: 12px;
        width: 131px;
        height: 28px;
        background-color: $white;
        mix-blend-mode: overlay;
        border-radius: 43px;
        @include breakpoint(xl) {
          top: 106px;
          height: 25px;
        }
      }
      &::after {
        content: "";
        position: absolute;
        top: 88px;
        right: 24px;
        transform: rotate(331deg);
        width: 160px;
        height: 91px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        background-image: url("data:image/svg+xml,%3Csvg width='193' height='115' viewBox='0 0 193 115' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M192.244 98.3582C192.244 95.4127 189.857 93.0249 186.911 93.0249C183.966 93.0249 181.578 95.4127 181.578 98.3582C181.578 101.304 183.966 103.692 186.911 103.692C189.857 103.692 192.244 101.304 192.244 98.3582ZM3.34072 0.665336C2.79615 0.573318 2.2801 0.940185 2.18809 1.48475L0.688608 10.359C0.596582 10.9035 0.963449 11.4196 1.50802 11.5116C2.05259 11.6036 2.56863 11.2367 2.66065 10.6922L3.99352 2.80399L11.8817 4.13686C12.4263 4.22888 12.9423 3.86202 13.0343 3.31745C13.1264 2.77289 12.7595 2.25684 12.2149 2.16482L3.34072 0.665336ZM186.237 97.6192C169.111 113.232 149.294 115.132 129.052 109.122C108.757 103.097 88.0981 89.1282 69.5321 73.0996C50.9807 57.0834 34.5927 39.0757 22.8376 25.0635C16.9617 18.0593 12.2469 12.0577 9.00215 7.8086C7.37983 5.68413 6.12516 3.99797 5.27676 2.84389C4.85256 2.26686 4.52993 1.82287 4.31373 1.52363C4.20562 1.37402 4.12411 1.26059 4.06982 1.18481C4.04267 1.14693 4.02232 1.11846 4.00884 1.09959C4.00212 1.09015 3.9971 1.08311 3.99381 1.0785C3.99217 1.07619 3.99101 1.07455 3.99018 1.07339C3.98945 1.07236 3.98915 1.07194 3.17411 1.65136C2.35908 2.23077 2.35964 2.23157 2.36065 2.23299C2.36157 2.23427 2.363 2.23629 2.36483 2.23884C2.36848 2.24397 2.37387 2.25152 2.38096 2.26147C2.39517 2.28137 2.41625 2.31087 2.44415 2.3498C2.49993 2.42766 2.58296 2.5432 2.69262 2.69496C2.91194 2.99849 3.23777 3.44691 3.66532 4.02851C4.52043 5.19169 5.78239 6.88761 7.41261 9.02242C10.6729 13.2919 15.4069 19.3177 21.3054 26.3489C33.0994 40.4075 49.5637 58.5023 68.2251 74.6134C86.8721 90.712 107.787 104.895 128.483 111.04C149.233 117.2 169.826 115.287 187.585 99.0972L186.237 97.6192Z' fill='white'/%3E%3C/svg%3E%0A");
        @include breakpoint(xl) {
          top: 75px;
          right: 33px;
        }
      }
    }
    &--PBSingleProductInstructionThirdStep {
      width: 271px;
      height: 43px;
      left: 31px;
      top: 324px;
    }
    &--PBSingleProductInstructionFourthStep {
      width: 271px;
      height: 121px;
      left: 31px;
      top: 580px;
    }
    &--PBSingleProductInstructionFifthStep {
      width: 271px;
      height: 138px;
      left: 31px;
      bottom: 53px;
      @include mediaMinHeight(906) {
        top: 714px;
        bottom: auto;
      }
    }
    &--PBSingleProductInstructionSixthStep {
      top: 180px;
      left: 326px;
      height: 387px;
      width: calc(100% - 356px);
      @include breakpoint(xl) {
        top: 120px;
        height: 337px;
        width: calc(100% - 337px);
      }
    }
    &--PBSingleProductInstructionSeventhStep {
      max-width: 901px;
      width: 100%;
      height: 270px;
      top: 585px;
      left: 325px;
      @include breakpoint(xl) {
        max-width: 879px;
        height: 235px;
        top: 470px;
      }
    }
    &--PBSingleProductInstructionEighthStep {
      bottom: 206px;
      left: 326px;
      height: 220px;
      width: calc(100% - 356px);
      @include breakpoint(xl) {
        bottom: 176px;
        height: 200px;
        width: calc(100% - 336px);
      }
    }
    &--DashboardInstructionFirstStep {
      height: 336px;
      top: 265px;
      left: 349px;
      @include mediaMaxWidth(1920) {
        top: 245px;
      }
      @include mediaMaxWidth(1740) {
        height: 332px;
      }
      @include breakpoint(xl) {
        height: 329px;
        top: 230px;
      }
      @include mediaMaxWidth(1620) {
        height: 324px;
      }
      @include mediaMaxWidth(1365) {
        height: 292px;
        top: 549px;
      }
    }
    &--DashboardInstructionSecondStep {
      width: calc(100% - 380px);
      height: 380px;
      top: 607px;
      left: 346px;
      @include mediaMaxWidth(1920) {
        top: 587px;
      }
      @include mediaMaxWidth(1740) {
        top: 570px;
      }
      @include breakpoint(xl) {
        top: 555px;
      }
      @include mediaMaxWidth(1620) {
        top: 549px;
        width: calc(100% - 359px);
      }
      @include mediaMaxWidth(1365) {
        top: 848px;
        width: calc(100% - 350px);
      }
    }
    &--DashboardInstructionThirdStep {
      top: 992px; 
      height: 662px;
      left: 350px;
      width: calc(100% - 384px);
      @include mediaMaxWidth(1920) {
        top: 972px;
      }
      @include mediaMaxWidth(1740) {
        top: 955px;
      }
      @include breakpoint(xl) {
        top: 938px;
      }
      @include mediaMaxWidth(1620) {
        top: 934px;
        width: calc(100% - 364px);
        height: 1025px;
      }
      @include mediaMaxWidth(1365) {
        top: 1240px;
      }
    }
    &--BloggersInstructionSecondStep {
      width: 225px;
      height: 70px;
      top: 171px;
      left: 346px;
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 270px;
        width: 323px;
        height: 350px;
        background-image: url("data:image/svg+xml,%3Csvg width='323' height='350' viewBox='0 0 323 350' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M311.832 343.833C311.832 340.888 314.22 338.5 317.165 338.5C320.111 338.5 322.499 340.888 322.499 343.833C322.499 346.779 320.111 349.167 317.165 349.167C314.22 349.167 311.832 346.779 311.832 343.833ZM0.480553 7.17041C0.155543 6.72391 0.254052 6.09845 0.700579 5.77344L7.97716 0.477081C8.42369 0.152069 9.04914 0.25058 9.37416 0.697113C9.69917 1.14365 9.60066 1.76907 9.15413 2.09409L2.68606 6.80197L7.39392 13.2701C7.71893 13.7166 7.62042 14.342 7.1739 14.667C6.72737 14.992 6.10191 14.8935 5.7769 14.447L0.480553 7.17041ZM316.177 343.678C327.187 273.774 313.014 217.87 285.599 173.308C258.17 128.725 217.45 95.439 175.293 70.8684C133.139 46.2994 89.5935 30.4715 56.5768 20.7858C40.071 15.9437 26.2027 12.6385 16.4639 10.5443C11.5947 9.49719 7.7581 8.75293 5.14076 8.27075C3.8321 8.02966 2.82828 7.8541 2.15262 7.73898C1.81479 7.6814 1.55901 7.63895 1.38818 7.61096C1.30276 7.59695 1.23859 7.58658 1.19602 7.57974C1.17473 7.57632 1.15885 7.57379 1.14841 7.57214C1.14319 7.57129 1.13946 7.57071 1.13684 7.57028C1.13448 7.56992 1.13348 7.56976 1.28906 6.58194C1.44464 5.59412 1.44639 5.59439 1.44951 5.59488C1.45238 5.59534 1.45687 5.59604 1.46259 5.59695C1.47404 5.59879 1.49094 5.60147 1.51324 5.60507C1.55784 5.61221 1.62407 5.62292 1.71155 5.63727C1.88651 5.66592 2.1465 5.70911 2.48859 5.7674C3.17277 5.88397 4.18538 6.0611 5.50311 6.30386C8.13854 6.78937 11.9945 7.53745 16.8844 8.58896C26.6638 10.6919 40.5802 14.0089 57.1398 18.8667C90.2538 28.581 133.962 44.4644 176.3 69.1405C218.635 93.8149 259.646 127.308 287.302 172.26C314.971 217.234 329.236 273.621 318.153 343.989L316.177 343.678Z' fill='white'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        background-position: center;
        transform: rotate(-8deg);
        @include mediaMaxWidth(1720) {
          transform: rotate(8deg) scaleX(-1);
          left: auto;
          right: 220px;
        }
      }
      @include mediaMaxWidth(1920) {
        top: 153px;
      }
      @include mediaMaxWidth(1720) {
        left: auto;
        right: 19px;
        top: 110px;
        width: 179px;
        height: 65px;
      }
      @include mediaMaxWidth(1680) {
        top: 94px;
      }
    }
    &--BloggersInstructionThirdStep {
      width: 297px;
      height: 50px;
      left: 409px;
      top: 312px;
      &::before {
        content: '';
        position: absolute;
        top: 16px;
        left: 314px;
        width: 285px;
        height: 126px;
        background-repeat: no-repeat;
        background-position: center;
        background-image: url("data:image/svg+xml,%3Csvg width='285' height='126' viewBox='0 0 285 126' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M273.816 121.446C273.358 118.536 275.345 115.806 278.254 115.348C281.164 114.889 283.894 116.877 284.353 119.786C284.811 122.696 282.824 125.426 279.914 125.884C277.004 126.343 274.274 124.355 273.816 121.446ZM0.363287 9.27114C-0.0624987 8.9194 -0.12252 8.28908 0.229226 7.8633L5.96126 0.924723C6.313 0.498938 6.94332 0.438917 7.3691 0.790663C7.79489 1.14241 7.85491 1.77272 7.50316 2.19851L2.40802 8.36613L8.57565 13.4613C9.00143 13.813 9.06145 14.4433 8.70971 14.8691C8.35796 15.2949 7.72765 15.3549 7.30186 15.0032L0.363287 9.27114ZM278.11 120.39C282.181 102.847 280.917 88.0265 275.655 75.4945C270.389 62.9549 261.084 52.6232 248.934 44.1261C224.603 27.1104 189.007 17.5511 152.235 12.4007C115.492 7.25429 77.7064 6.52663 49.0948 7.08768C34.7918 7.36815 22.7878 7.97051 14.3609 8.50264C10.1476 8.7687 6.82886 9.01717 4.56453 9.19903C3.43238 9.28996 2.56385 9.36423 1.97892 9.41572C1.68646 9.44146 1.4649 9.46151 1.31674 9.47509C1.24266 9.48188 1.18694 9.48706 1.14987 9.49052C1.13134 9.49226 1.11747 9.49356 1.10831 9.49443C1.10373 9.49486 1.10039 9.49517 1.0981 9.49539C1.09595 9.49559 1.09497 9.49569 1.00018 8.50019C0.905384 7.50469 0.906786 7.50456 0.909368 7.50432C0.911808 7.50408 0.915573 7.50373 0.920443 7.50327C0.930182 7.50235 0.944629 7.50099 0.963744 7.4992C1.00197 7.49563 1.05888 7.49035 1.13414 7.48345C1.28467 7.46964 1.50864 7.44938 1.80355 7.42342C2.39338 7.3715 3.267 7.2968 4.40442 7.20545C6.67923 7.02275 10.0094 6.77344 14.2349 6.50662C22.6856 5.97298 34.7191 5.36919 49.0556 5.08806C77.7229 4.52592 115.624 5.25332 152.513 10.42C189.372 15.5826 225.353 25.1943 250.08 42.4872C262.459 51.1444 272.053 61.7515 277.499 74.7201C282.948 87.6962 284.209 102.953 280.058 120.842L278.11 120.39Z' fill='white'/%3E%3C/svg%3E%0A");
        @include mediaMaxWidth(1920) {
          top: 9px;
        }
      }
      @include mediaMaxWidth(1920) {
        top: 321px;
        height: 42px;
      }
      @include mediaMaxWidth(1720) {
        top: 330px;
      }
      @include mediaMaxWidth(1680) {
        top: 314px;
      }
    }
    &--BloggersInstructionFourthStep {
      width: 209px;
      height: 48px;
      top: 314px;
      left: 707px;
      &::before {
        content: '';
        position: absolute;
        top: 18px;
        left: 226px;
        width: 47px;
        height: 127px;
        background-image: url("data:image/svg+xml,%3Csvg width='47' height='127' viewBox='0 0 47 127' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M35.9629 121.891C35.5046 118.982 37.4918 116.252 40.4015 115.793C43.3112 115.335 46.0414 117.322 46.4997 120.232C46.9579 123.142 44.9707 125.872 42.061 126.33C39.1514 126.788 36.4211 124.801 35.9629 121.891ZM0.00239746 2.07733C-0.0402587 1.5267 0.37154 1.04574 0.922175 1.00309L9.89529 0.307964C10.4459 0.265308 10.9269 0.677106 10.9695 1.22774C11.0122 1.77838 10.6004 2.25933 10.0498 2.30199L2.07366 2.91988L2.69155 10.896C2.7342 11.4466 2.3224 11.9276 1.77177 11.9702C1.22113 12.0129 0.740176 11.6011 0.69752 11.0505L0.00239746 2.07733ZM40.2345 120.981C43.4155 81.6332 33.4147 52.0609 22.6369 32.3339C17.2463 22.4672 11.6595 15.0606 7.42632 10.1259C5.30991 7.65877 3.53247 5.81024 2.28803 4.58185C1.66583 3.96768 1.17695 3.50861 0.845674 3.20483C0.680036 3.05295 0.553806 2.9399 0.470018 2.8657C0.428124 2.8286 0.396842 2.80121 0.37655 2.78355C0.366404 2.77472 0.359006 2.76831 0.354404 2.76434C0.352103 2.76235 0.3505 2.76097 0.349603 2.7602C0.349154 2.75981 0.349011 2.75969 0.348785 2.7595C0.348819 2.75953 0.34903 2.75971 0.99941 2.0001C1.64979 1.24049 1.65036 1.24098 1.6511 1.24161C1.65159 1.24203 1.65251 1.24282 1.65348 1.24365C1.65541 1.24532 1.65805 1.24759 1.6614 1.25048C1.66808 1.25625 1.67757 1.26447 1.68982 1.27513C1.7143 1.29644 1.7498 1.32753 1.79594 1.36839C1.88822 1.4501 2.02304 1.5709 2.19737 1.73075C2.54601 2.05044 3.0527 2.52641 3.69303 3.15849C4.97365 4.42259 6.78916 6.31142 8.94431 8.82372C13.2543 13.8479 18.925 21.3684 24.392 31.375C35.3296 51.3945 45.4447 81.353 42.228 121.142L40.2345 120.981Z' fill='white'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        background-position: center;
        transform: rotate(-8deg);
      }
      @include mediaMaxWidth(1920) {
        top: 277px;
        height: 45px;
      }
      @include mediaMaxWidth(1720) {
        top: 286px;
      }
      @include mediaMaxWidth(1680) {
        top: 270px;
      }
    }
    &--BloggersInstructionFifthStep {
      width: 329px;
      height: 48px;
      top: 314px;
      left: 918px;
      &::before {
        content: '';
        position: absolute;
        top: 18px;
        left: -70px;
        width: 47px;
        height: 127px;
        background-image: url("data:image/svg+xml,%3Csvg width='47' height='127' viewBox='0 0 47 127' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M35.9629 121.891C35.5046 118.982 37.4918 116.252 40.4015 115.793C43.3112 115.335 46.0414 117.322 46.4997 120.232C46.9579 123.142 44.9707 125.872 42.061 126.33C39.1514 126.788 36.4211 124.801 35.9629 121.891ZM0.00239746 2.07733C-0.0402587 1.5267 0.37154 1.04574 0.922175 1.00309L9.89529 0.307964C10.4459 0.265308 10.9269 0.677106 10.9695 1.22774C11.0122 1.77838 10.6004 2.25933 10.0498 2.30199L2.07366 2.91988L2.69155 10.896C2.7342 11.4466 2.3224 11.9276 1.77177 11.9702C1.22113 12.0129 0.740176 11.6011 0.69752 11.0505L0.00239746 2.07733ZM40.2345 120.981C43.4155 81.6332 33.4147 52.0609 22.6369 32.3339C17.2463 22.4672 11.6595 15.0606 7.42632 10.1259C5.30991 7.65877 3.53247 5.81024 2.28803 4.58185C1.66583 3.96768 1.17695 3.50861 0.845674 3.20483C0.680036 3.05295 0.553806 2.9399 0.470018 2.8657C0.428124 2.8286 0.396842 2.80121 0.37655 2.78355C0.366404 2.77472 0.359006 2.76831 0.354404 2.76434C0.352103 2.76235 0.3505 2.76097 0.349603 2.7602C0.349154 2.75981 0.349011 2.75969 0.348785 2.7595C0.348819 2.75953 0.34903 2.75971 0.99941 2.0001C1.64979 1.24049 1.65036 1.24098 1.6511 1.24161C1.65159 1.24203 1.65251 1.24282 1.65348 1.24365C1.65541 1.24532 1.65805 1.24759 1.6614 1.25048C1.66808 1.25625 1.67757 1.26447 1.68982 1.27513C1.7143 1.29644 1.7498 1.32753 1.79594 1.36839C1.88822 1.4501 2.02304 1.5709 2.19737 1.73075C2.54601 2.05044 3.0527 2.52641 3.69303 3.15849C4.97365 4.42259 6.78916 6.31142 8.94431 8.82372C13.2543 13.8479 18.925 21.3684 24.392 31.375C35.3296 51.3945 45.4447 81.353 42.228 121.142L40.2345 120.981Z' fill='white'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        background-position: center;
        transform: rotate(8deg) scaleX(-1);
      }
      @include mediaMaxWidth(1920) {
        height: 43px;
        top: 278px;
      }
      @include mediaMaxWidth(1720) {
        top: 288px;
      }
      @include mediaMaxWidth(1680) {
        top: 272px;
      }
    }
    &--BloggersInstructionFinalStep {
      width: 44px;
      height: 48px;
      top: 312px;
      left: 1457px;
      &::before {
        content: '';
        position: absolute;
        top: 43px;
        right: 50px;
        width: 503px;
        height: 129px;
        background-repeat: no-repeat;
        background-position: center;
        background-image: url("data:image/svg+xml,%3Csvg width='503' height='129' viewBox='0 0 503 129' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.674479 123.479C0.674479 120.533 3.06229 118.145 6.00781 118.145C8.95333 118.145 11.3411 120.533 11.3411 123.479C11.3411 126.424 8.95333 128.812 6.00781 128.812C3.06229 128.812 0.674479 126.424 0.674479 123.479ZM501.996 15.8705C502.321 16.317 502.223 16.9425 501.776 17.2675L494.5 22.5639C494.053 22.8889 493.428 22.7904 493.103 22.3438C492.778 21.8973 492.876 21.2718 493.323 20.9468L499.791 16.239L495.083 9.77089C494.758 9.32436 494.857 8.69891 495.303 8.3739C495.75 8.04889 496.375 8.1474 496.7 8.59393L501.996 15.8705ZM5.01999 123.323C12.2084 77.6824 47.1044 48.2173 95.014 29.6623C142.925 11.1068 204.065 3.3655 264.226 1.26991C324.403 -0.826271 383.68 2.7245 427.906 6.79771C450.021 8.83449 468.377 11.0023 481.205 12.6611C487.619 13.4905 492.652 14.1927 496.082 14.6877C497.797 14.9352 499.111 15.1309 499.997 15.2648C500.441 15.3318 500.777 15.3833 501.002 15.4181C501.115 15.4355 501.2 15.4487 501.257 15.4576C501.285 15.4621 501.307 15.4654 501.321 15.4677C501.329 15.4688 501.334 15.4697 501.338 15.4703C501.341 15.4709 501.343 15.4712 501.188 16.459C501.032 17.4468 501.031 17.4466 501.027 17.446C501.024 17.4455 501.019 17.4447 501.012 17.4436C500.998 17.4414 500.977 17.4381 500.949 17.4337C500.893 17.425 500.809 17.4119 500.697 17.3947C500.473 17.3602 500.14 17.309 499.699 17.2424C498.817 17.1091 497.507 16.9141 495.796 16.6672C492.375 16.1735 487.352 15.4727 480.949 14.6446C468.141 12.9884 449.81 10.8235 427.723 8.78928C383.545 4.72055 324.359 1.17648 264.295 3.26869C204.215 5.3615 143.337 13.0918 95.7363 31.5273C48.134 49.9634 14.0241 79.0087 6.99564 123.634L5.01999 123.323Z' fill='white'/%3E%3C/svg%3E%0A");
        transform: rotate(-9deg);
      }
      @include mediaMaxWidth(1920) {
        height: 44px;
        top: 277px;
      }
      @include mediaMaxWidth(1720) {
        top: 287px;
      }
      @include mediaMaxWidth(1680) {
        top: 271px;
      }
      @include mediaMaxWidth(1531) {
        left: auto;
        right: 31px;
      }
    }
  }


  &__hamburger {
    position: absolute;
    z-index: 1;
    @include breakpoint(md) {
      top: 0;
      right: -100px;
    }
    @include mediaMaxWidth(730) {
      right: 15px;
    }
    @include breakpoint(sm) {
      top: 25px;
    }
    @include breakpoint(xs) {
      top: 10px;
    }
  }
  
  &__layout {
    padding: 20px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 100vh;
    height: auto;
    &::before {
      content: '';
      position: fixed;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-color: rgba($black, .5);
    }
    &--firstStepInstructionPopup {
      align-items: flex-start;
      padding-top: 398px;
      padding-left: 700px;
      justify-content: flex-start;
      @include breakpoint(xl) {
        padding-top: 300px;
        padding-left: 570px;
      }
      @include breakpoint(md) {
        padding: 0;
        justify-content: center;
        top: 30px;
        position: relative;
        max-width: 500px;
        margin: 0 auto;
      }
      @include mediaMaxWidth(730) {
        max-width: 100%;
        padding-right: 115px;
        justify-content: flex-end;
      }
      @include breakpoint(sm) {
        padding-right: 0;
        justify-content: center;
        top: 0;
      }
    }
    &--firstStepInstructionPopup.openMobileSidebar {
      @include breakpoint(sm) {
        display: block;
        min-height: auto;
        &::before {
          display: none;
        }
      }
    }
    &--secondStepInstructionPopup {
      align-items: flex-start;
      padding-top: 105px;
      padding-left: 710px;
      justify-content: flex-start;
      @include breakpoint(xl) {
        padding-top: 95px;
        padding-left: 570px;
      }
      @include breakpoint(md) {
        padding-top: 105px;
        padding-left: 0;
        justify-content: center;
      }
      @include breakpoint(sm) {
        padding-top: 0;
        padding-bottom: 74px;
        align-items: flex-end;
      }
    }
    &--PBBasicProductsInstructionThirdStep {
      justify-content: flex-end;
    }
    &--PBSingleProductInstructionFirstStep, 
    &--PBSingleProductInstructionSecondStep, 
    &--PBSingleProductInstructionThirdStep, 
    &--PBSingleProductInstructionFourthStep,
    &--PBSingleProductInstructionFifthStep
    {
      justify-content: flex-start;
    }
    &--PBSingleProductInstructionFifthStep {
      height: 100%;
      justify-content: flex-start;
      @include mediaMinHeight(906) {
        height: auto;
      }
    }
    &--PBSingleProductInstructionSecondStep {
      justify-content: flex-start;
      height: 100%;
    }
    &--PBSingleProductInstructionEighthStep {
      height: 100%;
    }
    &--DashboardInstructionFirstStep {
      justify-content: flex-end;
    }
    &--DashboardInstructionSecondStep {
      justify-content: flex-start;
    }
    &--DashboardInstructionThirdStep {
      justify-content: flex-start;
    }
    &--BloggersInstructionSecondStep {
      justify-content: flex-start;
      @include mediaMaxWidth(1720) {
        justify-content: flex-end;
      }
    }
    &--BloggersInstructionThirdStep, 
    &--BloggersInstructionFourthStep,
    &--BloggersInstructionFifthStep,
    &--BloggersInstructionFinalStep {
      justify-content: flex-start;
    }
    &--BloggersInstructionFinalStep {
      @include mediaMaxWidth(1531) {
        justify-content: flex-end;
      }
    }
  }

  &__container {
    box-shadow: 0px 5px 15px 0px rgba($black, 0.20);
    border-radius: 20px;
    background-color: $white;
    position: relative;
    max-width: 100%;

    &--PBBasicProductsInstructionSecondStep, &--PBBasicProductsInstructionThirdStep {
      align-self: flex-start;
      top: 370px;
      @include breakpoint(xl) {
        top: 360px;
      }
      @include mediaMaxWidth(1367) {
        top: 373px;
      }
      @include mediaMaxWidth(1306) {
        top: 385px;
      }
    }

    &--PBBasicProductsInstructionThirdStep {
      right: 716px;
      @include breakpoint(xl) {
        right: 445px;
      }
    }

    &--PBSingleProductInstructionFirstStep {
      align-self: flex-start;
      top: 343px;
      left: 710px;
    }

    &--PBSingleProductInstructionSecondStep {
      align-self: flex-end;
      bottom: 275px;
      left: 710px;
      @include breakpoint(xl) {
        bottom: 250px;
      }
    }

    &--PBSingleProductInstructionThirdStep {
      align-self: flex-start;
      top: 341px;
      left: 710px;
    }

    &--PBSingleProductInstructionFourthStep {
      align-self: flex-start;
      top: 428px;
      left: 710px;
    }

    &--PBSingleProductInstructionFifthStep {
      align-self: flex-end;
      bottom: 250px;
      left: 710px;
      @include mediaMinHeight(906) {
        top: 407px;
        bottom: auto;
        align-self: flex-start;
      }
    }

    &--PBSingleProductInstructionSixthStep {
      align-self: flex-start;
      top: 570px;
      @include breakpoint(xl) {
        top: 460px;
      }
    }

    &--PBSingleProductInstructionSeventhStep {
      align-self: flex-start;
      top: 350px;
      @include breakpoint(xl) {
        top: 235px;
      }
    }
    &--PBSingleProductInstructionEighthStep {
      align-self: flex-end;
      bottom: 435px;
      @include breakpoint(xl) {
        bottom: 380px;
      }
    }

    &--DashboardInstructionFirstStep {
      align-self: flex-start;
      top: 296px;
      right: 15%;
      @include mediaMaxWidth(1920) {
        top: 290px;
        right: 21%;
      }
      @include mediaMaxWidth(1740) {
        right: 15%;
        top: 280px;
      }
      @include mediaMaxWidth(1620) {
        right: 7%;
      }
      @include mediaMaxWidth(1365) {
        right: 100px;
        top: 313px;
      }
    }

    &--DashboardInstructionSecondStep {
      align-self: flex-start;
      left: 348px;
      top: 445px;
      @include mediaMaxWidth(1920) {
        top: 425px;
      }
      @include mediaMaxWidth(1740) {
        top: 408px;
      }
      @include breakpoint(xl) {
        top: 395px;
      }
      @include mediaMaxWidth(1620) {
        top: 387px;
      }
      @include mediaMaxWidth(1365) {
        top: 686px;
      }
    }

    &--DashboardInstructionThirdStep {
      align-self: flex-start;
      top: 816px;
      left: 350px;
      @include mediaMaxWidth(1920) {
        top: 796px;
      }
      @include mediaMaxWidth(1740) {
        top: 779px;
      }
      @include breakpoint(xl) {
        top: 762px;
      }
      @include mediaMaxWidth(1620) {
        top: 758px;
      }
      @include mediaMaxWidth(1365) {
        top: 1064px;
      }
    }

    &--BloggersInstructionSecondStep {
      align-self: flex-start;
      top: 490px;
      left: 710px;
      @include mediaMaxWidth(1920) {
        top: 472px;
      }
      @include mediaMaxWidth(1720) {
        left: auto;
        right: 330px;
        top: 430px;
      }
      @include mediaMaxWidth(1680) {
        left: auto;
        right: 330px;
        top: 414px;
      }
    }

    &--BloggersInstructionThirdStep {
      align-self: flex-start;
      left: 710px;
      top: 460px;
      @include mediaMaxWidth(1680) {
        top: 444px;
      }
    }

    &--BloggersInstructionFourthStep, &--BloggersInstructionFifthStep {
      align-self: flex-start;
      top: 450px;
      left: 710px;
      @include mediaMaxWidth(1920) {
        top: 413px;
      }
      @include mediaMaxWidth(1720) {
        top: 422px;
      }
      @include mediaMaxWidth(1680) {
        top: 408px;
      }
    }

    &--BloggersInstructionFinalStep {
      align-self: flex-start;
      left: 710px;
      top: 515px;
      @include mediaMaxWidth(1920) {
        top: 480px;
      }
      @include mediaMaxWidth(1720) {
        top: 490px;
      }
      @include mediaMaxWidth(1680) {
        top: 474px;
      }
      @include mediaMaxWidth(1531) {
        left: auto;
        right: 325px;
      }
    }

    &--firstStepInstructionPopup {
      @include breakpoint(sm) {
        top: 357px;
      }
      @include breakpoint(xs) {
        top: 190px;
      }
    }
    &--firstStepInstructionPopup.openMobileSidebar {
      @include breakpoint(sm) {
        top: 0;
      }
    }

    .uiButtonClose {
      position: absolute;
      top: 10px;
      right: 10px;
      z-index: 9999;
    
      &--sidebar {
        top: 10px;
        right: 10px;
        background-color: transparent;
      }
    }
  }

}
</style>