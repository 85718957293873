<template>
  <component :is="layout">
    <router-view />
  </component>
</template>

<script>
import BaseLayout from '@/layouts/BaseLayout'
import MainLayout from '@/layouts/MainLayout'
import AdminLayout from '@/layouts/AdminLayout'
import UnderDevelopmentLayout from '@/layouts/UnderDevelopmentLayout'
import {mapMutations, mapGetters, mapActions, mapState} from 'vuex'

export default {
  components: {
    BaseLayout,
    MainLayout,
    UnderDevelopmentLayout,
    AdminLayout
  },
  computed: {
    layout() {
      return `${this.$route.meta.layout}-layout`
    },
    ...mapGetters({
      getToken: 'auth/getToken',
      checkModalQueue: 'popup/checkModalQueue'
    }),
    ...mapState({
      storeList: state => state.userStore.storeList,
    }),
    routerName() {
      return this.$route.path
    }
  },
  methods: {
    checkIsMobile() {
      this.$store.dispatch('checkIsMobile')
    },
    ...mapMutations({
      cleanQueue: 'popup/cleanQueue',
    }),
    ...mapActions({
      getUser: 'auth/getUser',
      openPopup: 'popup/openPopup',
      getCurrentStore: 'userStore/getCurrentStore',
      getStoreList: 'userStore/getStoreList',
      addSetInternalStepsInstructionOnMobile: 'popup/addSetInternalStepsInstructionOnMobile'
    }),
    getUserInit() {
      if(this.getToken) {
        this.getUser()
        .then(() => {
          this.instructionsInit()
          this.getStoreList()
          .then(() => {
            if(this.storeList.length) {
              this.getCurrentStore()
            }
          })
        })
      }
    },
    instructionsInit() {

      if(this.$store.state.auth.user.is_instructed) {

        if(!this.$store.state.auth.user.is_instructed.gift && this.$route.name === 'PBBasicProducts' 
          || !this.$store.state.auth.user.is_instructed.gift && this.$route.path.includes('crm')
        ) {
          this.openPopup({
            component: 'PrizeAfterRegistrationPopup'
          })
        } else {
          if(!this.$store.state.auth.user.is_instructed.integration && this.$route.name === 'PBBasicProducts' 
            || !this.$store.state.auth.user.is_instructed.integration && this.$route.path.includes('crm')) {
            this.openPopup({
              component: 'FirstStepInstructionPopup'
            })
          }

          if(!this.$store.state.auth.user.is_instructed.integration && this.$route.name === 'settings') {
            this.openPopup({
              component: 'SecondStepInstructionPopup'
            })
          }

          if(this.$store.state.auth.user.is_instructed.integration && 
            !this.$store.state.auth.user.is_instructed.repricer && this.$route.name === 'PBBasicProducts' && !this.$store.state.isMobile) {
            this.openPopup({
              component: 'PBBasicProductsInstructionPopup'
            })
          }

          if(this.$store.state.auth.user.is_instructed.integration && 
            !this.$store.state.auth.user.is_instructed.repricer && this.$route.name === 'PBSingleProduct' && !this.$store.state.isMobile) {
            this.openPopup({
              component: 'PBSingleProductInstructionPopup'
            })
          }

          if(this.$store.state.auth.user.is_instructed.integration && 
            !this.$store.state.auth.user.is_instructed.dashboard && this.$route.path === '/crm/dashboard' && !this.$store.state.isMobile) {
            this.openPopup({
              component: 'DashboardInstructionPopup'
            })
          }

          if(this.$store.state.auth.user.is_instructed.integration && 
            !this.$store.state.auth.user.is_instructed.bloggers && this.$route.path === '/crm/blogers' && !this.$store.state.isMobile) {
            this.openPopup({
              component: 'BloggersInstructionPopup'
            })
          }
        }

      }
      
    },
    
  },
  async mounted() {
    await this.$router.isReady()
    this.getUserInit()
    window.addEventListener('resize', this.checkIsMobile)
  },
  watch: {
    $route(to, from) {
      document.title = to.meta.title
      
      this.cleanQueue()

      if(from.matched.length) {

        if(this.$store.state.auth.user && !this.$store.state.auth.user.is_instructed.integration && (from.fullPath === '/crm/dashboard' || from.fullPath === '/price_bidder')) {
          this.addSetInternalStepsInstructionOnMobile(0)
        }

        this.instructionsInit()
      }

    },
    getToken() {
      this.getUserInit()
    },
    checkModalQueue(val) {
      if(val) document.body.style.overflow = 'hidden'
      else document.body.style.overflow = 'auto'
    }
  }
}
</script>

<style lang="scss">
@import 'src/scss/utils/mixins.scss';
@import 'src/scss/base/project/var.scss';

.fade-enter-active, .fade-leave-active {
  transition: opacity $transition-duration;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
