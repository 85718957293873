<template>
    <!-- table header  color: #5698F7;-->
    <div class="camp-table-title__wrapper camp-table-title__wrapper-sticky" :style="styleСolor">
        <div class="camp-table-title__inner">

            <button v-if="deletable" type="button" aria-label="Удалить строку" class="camp-btn-delete "
                    @click="deleteGroup"
            >
	        <span class="camp-btn-delete__ic camp-tooltip" data-tippy-content="Удалить строку">

                <Svg :img="'trash'"/>
	        </span>
            </button>

            <button type="button" class="camp-table-title__ic camp__js-close-table-btn"
            @click="toggleExpande"
            >

                <Svg :img="'arrow'"/>
            </button>

            <!-- camp__is-active -->
            <div class="camp-table-title">

                <div class="camp-table-cell-title">
                    <div class="camp-table-cell-title__display camp-tooltip" data-tippy-content="Нажмите чтобы отредактировать">
                        <div class="camp-table-cell-title__display-inner">

                            <h2 class="camp-table-cell-title__display-text">{{inputGroupNameValue}}</h2>
                            <div class="camp-table-cell-title__edit" style="display: none">

                                <ColorPickers
                                    :keyid="groupID"
                                    :defalut_color="color"
                                    @colorChanged="handleColorChange"
                                />
                                <input type="text"
                                       v-model="inputGroupNameValue"
                                       @change="handleEditGrop"
                                >
                            </div>

                        </div>
                    </div>
                </div>

                <span class="camp-table-title__notify">{{numrows}} элементов</span>
            </div>
        </div>
    </div>
    <!-- //table header -->

</template>

<script>
import {mapActions,  mapState} from 'vuex'

    import Svg from "./Svg";
    import ColorPickers from "./Inputs/ColorPickers";
    import {setGroupData} from "@/components/Crm/helpers/dataHelpers";
    export default {
        name: "TableHeader",
        components: {ColorPickers, Svg},
        props: {
            groupName:String,
            groupID:Number,
            numrows:Number,
            color:String,
            deletable:true,
        },
        data() {
            return {
                inputGroupNameValue: this.groupName,
                doc:null,
                currentcolor:this.color,
            }
        },
        computed :{
            ...mapState({
                deskData: state => state.crm.deskData
            }),
            getGroupName(){
                this.inputGroupNameValue= this.groupName
                return this.inputGroupNameValue
            },
            styleСolor(){
                this.currentcolor=this.color;
                return this.currentcolor ? 'color:'+ this.currentcolor +';' : 'color: #5698F7;'
            },
        },
        methods: {
            ...mapActions({
                editGroup: 'crm/editGroup',
            }),

            deleteGroup() {
                if(confirm('Удалить группу?')) {
                    console.log('delete group', this.groupID);

                    this.editGroup({
                        obj: {
                            group_id: this.groupID,
                            name: 'delgroup',
                            action: 'delete_group',
                        }
                    });
                }
            },
            handleEditGrop() {
                console.log('edit group',this.inputGroupNameValue);
                this.postGroupData(this.inputGroupNameValue);

                this.updateGroupData({name:this.inputGroupNameValue})
            },
            postGroupData(groupname) {
                this.editGroup({

                    obj: {
                        group_id:this.groupID,
                        name:groupname,
                        action:'edit_group',
                    }
                }).then(() => {

                })
                    .catch(() => {

                    })
                    .finally(() => {

                    })
            },
            getCellClass() {
                return {
                    'camp-table-cell': true,
                    'camp-table-cell-sticky camp-table-cell-collapsed': this.isSticky,
                    [`camp-table-cell__${this.type === 'name' ? 'link' : this.type}`]: true,

                };
            },
            getCellStyle() {
                return {
                    width: `${this.width}px`,
                    // Другие стили, если необходимо
                };
            },


            tableTitleEdit() {
                const doc=this.doc;
                // Edit table title
                const tableTitles = doc.querySelectorAll('.camp-table-title');

                if (tableTitles) {
                    tableTitles.forEach(function (tableTitle) {
                        tableTitle.addEventListener('click', function(e) {
                            let target = e.target;
                            let editor = tableTitle.querySelector('.camp-table-cell-title__edit');
                            if (target.classList.contains('camp-table-cell-title__display-text')) {
                                target.style.display = 'none';
                                editor.style.display = 'block';
                                tableTitle.classList.add('camp__is-active');
                            }
                        });
                    });
                }

            },
            handleColorChange(c){

                console.log("Handle ColorChange", c.color);
                this.$store.commit('crm/setUiaction', {action:'change_color','color':c.color,'group_id':this.groupID});
                //this.$store.commit('crm/setDeskData',{'data':999});
                this.currentcolor=c.color;
                this.updateGroupData({color:this.currentcolor})
            },
            toggleExpande(){
                //console.log("groupData",this.deskData)
                //return;
                if(this.deskData?.data?.desk_groups) {
                    const groups=this.deskData?.data?.desk_groups
                    //const isExpanded=this.deskData?.data?.desk_groups[this.itemIndex]?.enabled ?? false;
                    const newGroupdata = setGroupData(groups, this.groupID, {expanded: false})
                    console.log("groupData", newGroupdata)

                    this.deskData.data.desk_groups=newGroupdata

                    this.$store.commit('crm/setDeskData', this.deskData );
                }

            },
            updateGroupData(data)
            {
                if(this.deskData?.data?.desk_groups) {

                    const groups=this.deskData?.data?.desk_groups

                    const newGroupdata = setGroupData(groups, this.groupID, data )


                    this.deskData.data.desk_groups=newGroupdata
                    console.log("updateGroupData", newGroupdata)

                    this.$store.commit('crm/setDeskData', this.deskData );
                }
            },
            init(){
                this.doc = document;

            }
        },
        mounted() {

            this.inputGroupNameValue= this.groupName
            console.log("TableHeader mounted", this.inputGroupNameValue);
        },
        watch: {
            groupName(newValue) {
                this.inputGroupNameValue = newValue;
            }
        }
    }
</script>

<style scoped>

</style>
