<template>
  <BasePopup
  >
    <div class="PBBasicProductEditPopup">
      <header class="PBBasicProductEditPopup__header">
        <div class="PBBasicProductEditPopup__headerTitle">
          <span>{{product.name}}</span>
          <BaseFavoriteIcon
            class="PBBasicProductEditPopup__headerTitleFavoriteIcon"
            :isFavorite="product.favorite"
          />
        </div>
        <div class="PBBasicProductEditPopup__headerArticle">Артикул: {{product.marketplace_sku}}</div>

      </header>

      <main class="PBBasicProductEditPopup__filter">
        <div class="PBBasicProductEditPopup__filterTitle">
            <div class="PBBasicProductEditPopup__headerPrice">Цена: {{product.min_price}} ₽ &nbsp;&nbsp;&nbsp; Скидка: {{product.discount}} %</div>

        </div>
        <div class="PBBasicProductEditPopup__filterTitle titlePrimary titlePrimary--small">Быстрое редактирование</div>


        <div class="PBBasicProductEditPopup__filterRow">

          <div class="PBBasicProductEditPopup__filterRowInner">
            <BaseSwitcher
              class="PBBasicProductEditPopup__switcher"
              :transparent="true"
              :active="product.bidder===1"
              @click="setBidderStatus"
              :name="'Включить репрайсер'"
            />

            <BaseTooltip
              :content="'Глобальное состояние бидера: включен или выключен. Если бидер выключен, то ни на один товар цены не будут устанавливаться автоматически'"
              :gray="true"
            />
          </div>

          <div class="PBBasicProductEditPopup__filterRowInner">
            <div class="PBBasicProductEditPopup__buttonWrap">
              <BaseButton
                class="PBBasicProductEditPopup__button--filter button--primary"
                @click="findLeader()"
              >
                Запуск скрипта по поиску лидера
              </BaseButton>
            </div>

            <BaseTooltip
              :content="'Эта кнопка запускает работу бидера по поиску лидера именно для этого товара прямо сейчас, не дожидаясь следующего автоматического цикла, который происходит в 12.00 и в 00.00. Скрипт ищет лидера продаж конкурентов на основании статистики по ключевым запросам этого товара и устанавливает на товар цену и скидку лидера конкурентов минус 10 руб.'"
              :gray="true"
            />
          </div>

        </div>

        <div class="PBBasicProductEditPopup__separator separator separator--gray"></div>

        <div class="PBBasicProductEditPopup__filterRow">

          <div class="PBBasicProductEditPopup__filterRowInner">
            <BaseSwitcher
              class="PBBasicProductEditPopup__switcher"
              :transparent="true"
              :name="'Приоритет автоматической установки цены'"
              :active="getAutoPrice"
              @click="switchAutoPrice"
            />

            <BaseTooltip
              :content="'Автоматический цикл установки цены на основании лидера продаж конкурентов не будет перезаписывать цену. Установленная вручную цена сохраняется.'"
              :gray="true"
            />
          </div>

          <div class="PBBasicProductEditPopup__filterRowInner">
            <BaseSwitcher
              class="PBBasicProductEditPopup__switcher"
              :transparent="true"
              :name="'Приоритет ручной установки цены'"
              :active="getManualPrice"
              @click="switchAutoPrice"
            />

            <BaseTooltip
              :content="'При установке цены вручную она будет перезаписана при следующем цикле автоматической установки цены на основании лидера продаж конкурентов.'"
              :gray="true"
            />
          </div>

        </div>

        <div class="PBBasicProductEditPopup__separator separator separator--gray"></div>

        <div class="PBBasicProductEditPopup__filterRow">

          <BaseInputEdit
            class="PBBasicProductEditPopup__inputEdit"
            :label="'Минимальная цена'"
            :labelClass="'inputEdit__label--PBBasicProductEditPopup'"
            :controlClass="'inputEdit__control--PBBasicProductEditPopup inputEdit__control--gray'"
            v-model="product.bidder_min_price"
          />

          <BaseInputEdit
            class="PBBasicProductEditPopup__inputEdit"
            :label="'Максимальная цена'"
            :labelClass="'inputEdit__label--PBBasicProductEditPopup'"
            :controlClass="'inputEdit__control--PBBasicProductEditPopup inputEdit__control--gray'"
            v-model="product.bidder_max_price"
          />

        </div>

        <div class="PBBasicProductEditPopup__separator separator separator--gray"></div>

        <div class="PBBasicProductEditPopup__filterRow">

          <BaseInputEdit
            class="PBBasicProductEditPopup__inputEdit"
            :label="'Установить цену вручную'"
            :labelClass="'inputEdit__label--PBBasicProductEditPopup'"
            :controlClass="'inputEdit__control--PBBasicProductEditPopup inputEdit__control--gray'"
            v-model="manual_price"
            @change="calcManual"
          />

          <BaseInputEdit
            class="PBBasicProductEditPopup__inputEdit"
            :label="'Установить скидку, %'"
            :labelClass="'inputEdit__label--PBBasicProductEditPopup'"
            :controlClass="'inputEdit__control--PBBasicProductEditPopup inputEdit__control--gray'"
            v-model="discount"
            @change="calcManual"
          />

        </div>

        <Keywords
          class="PBBasicProductEditPopup__keywords keywords--PBBasicProductEditPopup"
          :keywords="singleProduct.keywords"
          @add="addKeyword"
          @remove="removeKeyword"
        />

        <div class="PBBasicProductEditPopup__separator PBBasicProductEditPopup__separator--show-mobile separator separator--gray">
        </div>

        <BaseButton
          class="PBBasicProductEditPopup__button--keywords button--primary"
          @click="openPopup({
            component: 'PBBasicProductEditPopupKeywords'
          })"
          v-if="$store.state.isMobile"
        >
          Ключевые слова
        </BaseButton>

      </main>

      <footer class="PBBasicProductEditPopup__footer">
        <BaseButton
          class="PBBasicProductEditPopup__button button--primary"
          @click="saveParamBidder"
        >
          Сохранить
        </BaseButton>

        <BaseButton
          class="PBBasicProductEditPopup__button button--transparent button--green"
          @click="closePopup"
        >
          Закрыть
        </BaseButton>
      </footer>
    </div>
  </BasePopup>
</template>

<script>
import Keywords from '@/components/Keywords/Keywords'
import {mapActions, mapState, mapMutations} from 'vuex'

export default {
  name: 'PBBasicProductEditPopup',
  components: {
    Keywords
  },
  props: {
    product: {
      type: Object,
      require: true
    }
  },
  data() {
    return {
      autoSetPrice:true,
      max_price:0,
      min_price:0,
      auto:true,
      manual_price:0,
      discount:0,
      timeLeftToBidderInfo:0,
      bidderUpdateTime:3600,
    }
  },
  methods: {
    ...mapActions({
        openPopup: 'popup/openPopup',
        closePopup: 'popup/closePopup',
        runBidderForProduct: 'priceBidder/runBidder',
        stopBidderForProduct: 'priceBidder/stopBidder',
        searchLeader: 'priceBidder/searchLeader',
        updateBidderData: 'priceBidder/updateBidderData',
        setProductPrice: 'priceBidder/setProductPrice',
    }),

	  // Настройка биддера
      setBidderStatus(){

		  let newSingleProduct = this.product

		  if(newSingleProduct.bidder>1) {
              this.product.bidder=1;

			  if (newSingleProduct?.bidder_min_price > 0 &&
				  newSingleProduct?.bidder_max_price > 0 &&
                  newSingleProduct?.keywords.length > 0
			  ) {

				  newSingleProduct.bidder =1;

				  //this.setProductBidderData(newSingleProduct)

				  this.runBidder()

				  this.openPopup(
					  {
						  component: 'SettingsAlertPopup',
						  props: {
							  text: 'Репрайсер начал работу. Для сбора статистики по конкурентным товарам постребуется время'
						  }
					  }
				  )

			  }
			  else {
				  this.openPopup(
					  {
						  component: 'SettingsAlertPopup',
						  props: {
							  text: 'Для запуска репрайсера необходимо указать ключевые слова, а также минимальную и максимальную цену товара'
						  }
					  }
				  )

			  }

		  }
		  else {
			  //this.stopBidder()
			  newSingleProduct.bidder =2;
			  //this.setProductBidderData(newSingleProduct)
			  this.openPopup(
				  {
					  component: 'SettingsAlertPopup',
					  props: {
						  text: 'Репрайсер остановлен для текущего товара'
					  }
				  }
			  )

		  }
	  },

	  runBidder(){
		  const keywords = this.product?.keywords ?? ''
		  const bidderStatus = this.product?.bidder ?? 2

		  const vm = {
			  product_id: this.product.id,
			  keywords: keywords,
			  auto:this.auto,
			  min_price: this.min_price,
			  max_price: this.max_price,
		  }

		  console.log('Bidder', bidderStatus)
		  if (bidderStatus) {

			  this.runBidderForProduct(vm)

		  }
	  },

	  stopBidder(){

		  const vm = {
			  product_id: this.product.id,
		  }

		  this.stopBidderForProduct(vm)

	  },

	  findLeader1(){
		  const product_id=this.product.id


	  },
      setProductBidderData(dataProduct) {
          const payload={
              data:{
                  data:{
                      product:dataProduct
                  }
              }
          } ;

          this.$store.commit('priceBidder/setSingleProduct', payload);

      },
      findLeader(){

          const product_id=this.product.id

          if (this.singleProduct?.bidder_min_price > 0 &&
              this.singleProduct?.bidder_max_price > 0 &&
              this.singleProduct?.keywords.length > 0
          ) {

              this.singleProduct.bidder =1;
              this.autoSetPrice=true
              this.setProductBidderData(this.singleProduct)

              this.runBidder()

              //this.searchLeader(product_id)
              console.log("Поиск лидера", product_id);

              if(this.timeLeftToBidderInfo>0) {
                  this.openPopup(
                      {
                          component: 'PBSingleProductFirstRunPopup',
                          props: {
                              time: this.timeLeftToBidderInfo,
                          }

                      }
                  )
              }
              else {
                  this.autoSetPrice=true

                  this.openPopup(
                      {
                          component: 'PBSingleProductLeaderSearchProgressbarPopup',
                          props: {
                              percent: 50,
                              product_id:this.product.id,
                              //text: 'Скрипт поиска лидера по ключевым словам запущен'
                          }
                      }
                  )
              }
          }
          else {

              this.openPopup(
                  {
                      component: 'SettingsAlertPopup',
                      props: {
                          text: 'Для запуска репрайсера необходимо указать ключевые слова, а также минимальную и максимальную цену товара'
                      }
                  }
              )

          }


      },
	  saveParamBidder () {

          if(!this.product.auto)
          {
              this.saveManual()
          }
            console.log("save product", this.product);
          const vm = {
              id: this.product.id,
              auto: this.autoSetPrice,
          }
          this.updateBidderData({
              id: this.product.id,
              auto:this.autoSetPrice,
              bidder_min_price:this.product.bidder_min_price,
              bidder_max_price:this.product.bidder_max_price,
              keywords:this.singleProduct.keywords
          });


          this.closePopup()
	  },
      saveManual(){
        console.log(this.manual_price , this.discount);
        if(!this.manual_price || !this.discount || this.manual_price < 1 || this.discount < 1)
        {
            return;
        }
          this.setProductPrice(
              {
                  product_id: this.product.id,
                  new_price : this.manual_price,
                  discount: this.discount
              }
          );

          const vm = {
              id: this.product.id,
              auto: this.autoSetPrice,
          }
          this.updateBidderData(vm);
      },

      changePriorityProduct()
      {

            if(this.autoSetPrice)
            {
                this.product.priority='Репрайсер'
            }
            else {
                this.product.priority='Ручной'
            }

            this.product.auto=this.autoSetPrice
      },

	  switchAutoPrice()
      {

       // this.autoSetPrice=!this.autoSetPrice
        //console.log("autoSetPrice",this.autoSetPrice)


          if(this.product.bidder===1) {
              //const autoPrice = !this.productBidder.auto;
              this.autoSetPrice=!this.autoSetPrice


              this.changePriorityProduct()

              const vm = {
                  id: this.product.id,
                  keywords: this.product?.keywords ?? null,
                  auto: this.autoSetPrice,
                  bidder_min_price: this.bidder_min_price,
                  bidder_max_price: this.bidder_max_price,
              }
              this.updateBidderData(vm);


              //this.$store.commit('priceBidder/updateProductBidder', this.productBidder);

              if(this.autoSetPrice)
              {
                  this.openPopup(
                      {
                          component: 'PBSingleProductSelectedPricePriorityPopup',
                          props:{
                              priority:'Репрайсер',
                          }
                      }
                  )
              }
              else {
                  this.openPopup(
                      {
                          component: 'PBSingleProductSelectedPricePriorityPopup',
                          props:{
                              priority:'Ручной',
                          }
                      }
                  )

              }


          }
          else{
              this.openPopup(
                  {
                      component: 'SettingsAlertPopup',
                      props: {
                          text: 'Необходимо, чтобы репрайсер был запущен'
                      }
                  }
              )
          }


      },

    ...mapMutations({
      setSingleProductKeywords: 'priceBidder/setSingleProductKeywords',
      setSingleProductKeywordsRemove: 'priceBidder/setSingleProductKeywordsRemove'
    }),
    addKeyword(newKeyword) {
      this.setSingleProductKeywords(newKeyword.value)

    },
    removeKeyword(value) {
      this.setSingleProductKeywordsRemove(value)
    },
    calcManual(){

      if (this.discount > 99) {
          this.discount = 99;
      } else if (this.discount < 0) {
          this.discount = 0;
      } else {

      }



    },

  },
  computed:{
	  getAutoPrice()
	  {
		  let isAutoPrice=false
		  if(this.product.bidder===1) {
			  isAutoPrice=this.autoSetPrice
		  }
		  return isAutoPrice
	  },
	  getManualPrice()
	  {
		  let isManualPrice=false
		  if(this.product.bidder===1) {
			  isManualPrice=!this.autoSetPrice
		  }
		  return isManualPrice
	  },


    ...mapState({
      singleProduct: state => state.priceBidder.singleProduct
    }),
  },
  mounted() {
    setTimeout(YC.tippyMount, 1000);

      if (this.product?.bidder===1) {
          this.autoSetPrice = this.product?.auto ?? true
      }
      else{
          this.autoSetPrice = true
      }
      this.discount=this.product?.discount ?? 0
      this.manual_price=this.product?.max_price ?? 0

	  const payload={
		  data:{
			  data:{
				  product:this.product
			  }
		  }
	  } ;

	  this.$store.commit('priceBidder/setSingleProduct', payload);
  }
}
</script>

<style lang="scss" scoped>
@import 'src/scss/base/project/var';
@import 'src/scss/utils/mixins';

.PBBasicProductEditPopup {

  min-width: 1180px;
  padding: 40px 100px;
  max-width: 1180px;
  width: 100%;

  @include breakpoint(xl) {
    min-width: 926px;
    max-width: 926px;
    padding: 30px 60px;
  }

  @include breakpoint(md) {
    min-width: auto;
    max-width: 480px;
    padding: 40px 60px;
  }

  @include breakpoint(sm) {
    max-width: 380px;
    padding: 40px;
  }

  @include mediaMaxWidth(375) {
    max-width: 314px;
    padding: 20px 30px 20px 20px;
  }

  @include breakpoint(xs) {
    padding: 20px 30px 20px 20px;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 50px;
    @include breakpoint(xl) {
      margin-bottom: 30px;
    }
    @include breakpoint(md) {
      flex-direction: column;
      align-items: flex-start;
    }
    &Title {
      font-size: 24px;
      line-height: 24px;
      font-family: $font-secondary;
      font-weight: 400;
      display: flex;
      align-items: center;
      flex: 1;
      &FavoriteIcon {
        flex: 0 0 30px;
        height: 30px;
      }
      span {
        margin-right: 5px;
      }
      @include breakpoint(xl) {
        font-size: 20px;
        line-height: 20px;
      }
      @include breakpoint(md) {
        margin-bottom: 10px;
        width: 100%;
      }
    }
    &Article {
      font-size: 14px;
      line-height: 14px;
      color: $gray-400;
      @include breakpoint(xl) {
        font-size: 13px;
        line-height: 13px;
      }
      @include breakpoint(md) {
        font-size: 14px;
        line-height: 14px;
        color: $black;
      }
    }
  }

  &__filter {
    margin-bottom: 30px;
    @include breakpoint(md) {
      margin-bottom: 45px;
    }
    @include breakpoint(xs) {
      margin-bottom: 35px;
    }
    &Title {
      margin-bottom: 30px;
      @include breakpoint(xl) {
        margin-bottom: 17px;
      }
      @include breakpoint(md) {
        margin-bottom: 20px;
        font-size: 16px;
        line-height: 16px;
      }
    }
    &Row {
      display: flex;
      width: 100%;
      justify-content: space-between;
      @include breakpoint(md) {
        flex-direction: column;
      }
      > div {
        position: relative;
        flex: 0 0 380px;
        display: flex;
        > div {
          &:first-child {
            width: 100%;
          }
        }
        @include breakpoint(xl) {
          flex: 0 0 394px;
          &:first-child {
            flex: 0 0 335px;
          }
        }
        @include breakpoint(md) {
          flex: 0 0 100%;
          &:first-child {
            flex: 0 0 100%;
            margin-bottom: 15px;
          }
        }
      }
    }
  }

  &__switcher {
    font-weight: 500;
  }

  &__inputEdit {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__buttonWrap {
    position: relative;
  }

  &__keywords {
    margin-top: 40px;
    @include breakpoint(xl) {
      margin-top: 30px;
    }
    @include breakpoint(md) {
      display: none;
    }

  }

  &__footer {
    display: flex;
    justify-content: center;
  }

  &__button {
    flex: 0 0 180px;
    border-radius: 43px;
    height: 30px;
    @include breakpoint(xl) {
      height: 25px;
      flex: 0 0 157px;
    }
    @include breakpoint(md) {
      height: 40px;
      flex: 0 0 160px;
    }
    @include breakpoint(sm) {
      flex: 0 0 140px;
    }
    @include breakpoint(xs) {
      flex: 0 0 120px;
    }
    &:first-child {
      margin-right: 20px;
    }
    &--keywords, &--filter {
      height: 40px;
    }
    &--filter {
      @include breakpoint(xl) {
        height: 30px;
        font-size: 13px;
      }
      @include breakpoint(md) {
        height: 40px;
      }
    }
    &--keywords {
      display: none;
      @include breakpoint(md) {
        display: block;
      }
    }
  }

  &__separator {
    margin: 30px 0;
    @include breakpoint(xl) {
      margin: 25px 0;
    }
    @include breakpoint(md) {
      margin: 20px 0;
    }
    &--show-mobile {
      display: none;
      @include breakpoint(md) {
        display: block;
      }
    }
  }

  &__uiButtonClose {
    @include breakpoint(md) {
      top: 0;
      right: 0;
    }
  }

}
</style>
