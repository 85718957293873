<template>
  <div class="storeInfo">
    <div class="storeInfo__title">Данные об организации</div>

    <div class="storeInfo__fieldset">
      <BaseSettingField     
        class="storeInfo__settingField"
        :label="'Полное наименивание продавца'"
        :classModificator="'storeInfo'"
        :buttonEditing="editing"
        v-model="store.supplierFullName"
      />

      <BaseSettingField     
        class="storeInfo__settingField"
        :label="'Юридический адрес'"
        :classModificator="'storeInfo'"
        :buttonEditing="editing"
        v-model="store.address"
      />

      <BaseSettingField     
        class="storeInfo__settingField"
        :label="'ИНН'"
        :classModificator="'storeInfo'"
        :buttonEditing="editing"
        v-model="store.inn"
      />

      <BaseSettingField     
        class="storeInfo__settingField"
        :label="'ОГРН'"
        :classModificator="'storeInfo'"
        :buttonEditing="editing"
        v-model="store.ogrnip"
      />

      <BaseSettingField     
        class="storeInfo__settingField"
        :label="'Расчетный счет'"
        :classModificator="'storeInfo'"
        :buttonEditing="editing"
        v-model="store.current_account"
      />

      <BaseSettingField     
        class="storeInfo__settingField"
        :label="'БИК'"
        :classModificator="'storeInfo'"
        :buttonEditing="editing"
        v-model="store.bik"
      />

      <BaseSettingField     
        class="storeInfo__settingField"
        :label="'КПП'"
        :classModificator="'storeInfo'"
        :buttonEditing="editing"
        v-model="store.kpp"
      />
    </div>

    <div class="storeInfo__actions">
      <div
        class="storeInfo__actionsChange"
        v-if="!editing"
      >
        <BaseButton    
          class="storeInfo__button button button--setting button--settingTransparent button--settingGreen"
          @click="editing = !editing"
        >
          Изменить реквизиты
        </BaseButton>
        <BaseButton
          class="storeInfo__button button button--setting button--settingTransparent button--settingRed"
          @click="handlerDeleteStore(store.id)"
        >
          Удалить компанию
        </BaseButton>
      </div>
      <div
        v-else
        class="storeInfo__actionsUpdate"
      >
        <BaseButton
          class="storeInfo__button button button--setting button--settingTransparent button--settingGreen"
          @click="handlerUpdateStoreInfo(store.id)"
        >
          Сохранить
        </BaseButton>
        <BaseButton
          @click="resetStoreInfo(store.id)"
          class="storeInfo__button button button--setting button--settingTransparent button--settingGreen"
        >
          Сбросить
        </BaseButton>
      </div>
    </div>

  </div>
</template>

<script>
import {mapActions, mapMutations, mapState} from 'vuex'

export default {
  props: {
    store: {
      type: Object,
      require: true
    }
  },
  data() {
    return {
      editing: false,
      formData: {
        supplierFullName: '',
        address: '',
        current_account: '',
        inn: '',
        ogrnip: '',
        bik: '',
        kpp: ''
      },
    }
  },
  methods: {
    ...mapActions({
      getStoreList: 'userStore/getStoreList',
      updateStoreInfo: 'userStore/updateStoreInfo',
      deleteStore: 'userStore/deleteStore',
      switchStore: 'userStore/switchStore',
      getCurrentStore: 'userStore/getCurrentStore'
    }),
    ...mapMutations({
      setCleanProducts: 'priceBidder/setCleanProducts'
    }),
    handlerUpdateStoreInfo(storeId) {
      for(let item in this.formData) {
        if(this.formData[item] === '' && this.store[item] === this.formData[item]) {
          this.formData[item] = this.store[item]
        } 
      }
      this.updateStoreInfo({id: storeId, data: this.store})
      .then(() => {
        
      })
      .finally(() => {
        this.editing = false
      })
    },
    handlerDeleteStore(storeId) {
      this.deleteStore(storeId)
      .then(() => {
        this.getStoreList()
        .then(() => {
          if(this.storeList.length) {

            this.switchStore(this.storeList[0].id)
            .then(() => {
              this.getCurrentStore()
            })

          } else {
            this.setCleanProducts()
          }
          
        })
      })
      .catch(() => {

      })
    },
    resetStoreInfo(storeId) {
      this.$store.commit('userStore/setResetStoreInfo', storeId)
      this.updateStoreInfo({id: storeId, data: this.formData})
      .then(() => {
        
      })
      .finally(() => {
        this.editing = false
      })
    }
  },
  computed: {
    ...mapState({
      storeList: state => state.userStore.storeList,
    })
  },
  watch: {
    'store.account': function(newVal) {
      this.formData.account = String(newVal)
    },
    'store.supplierFullName': function(newVal) {
      this.formData.supplierFullName = String(newVal)
    },
    'store.address': function(newVal) {
      this.formData.address = String(newVal)
    },
    'store.inn': function(newVal) {
      this.formData.inn = String(newVal)
    },
    'store.ogrnip': function(newVal) {
      this.formData.ogrnip = String(newVal)
    },
    'store.bik': function(newVal) {
      this.formData.bik = String(newVal)
    },
    'store.kpp': function(newVal) {
      this.formData.kpp = String(newVal)
    }
  }
}
</script>

<style lang="scss">
@import 'src/scss/utils/mixins.scss';
@import 'src/scss/base/project/var.scss';

.storeInfo {

  &__title {
    font-size: 14px;
    line-height: 14px;
    margin-bottom: 10px;
    @include breakpoint(xl) {
      font-size: 13px;
      line-height: 13px;
    }
    @include breakpoint(md) {
      font-size: 14px;
      line-height: 14px;
    }
    @include breakpoint(sm) {
      margin-bottom: 20px;
    }
  }

  &__settingField {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    &:last-child {
      margin-bottom: 0;
    }
    @include breakpoint(sm) {
      margin-bottom: 10px;
    }
  }

  &__fieldset {
    margin-bottom: 20px;
  }

  &__actions {
    &Change, &Update {
      display: flex;
      justify-content: space-between;
      align-items: center;
      @include breakpoint(sm) {
        flex-direction: column;
      }
    }
  }

  &__button {
    max-width: max-content;
    @include breakpoint(sm) {
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

}

</style>