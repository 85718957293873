export default {

  namespaced: true,

  state: () => ({

    isLoading: false,
    isRunConfirmed: false,
    isStatisticRunning: false,
    products: [],
    totalProducts: 0,
    singleProduct: {},
    leaderData:[],
    graphData:[],
    graphData2:[],
    productBidder:{},

    params: {
      limit: 10,
      offset: 0,
      set_price_priority: '',
      is_favorite: false,
      sort_by: ''
    },

    sortParams: {
      date: {
        asc: false,
        desc: false
      },
      name: {
        asc: false,
        desc: false
      }
    },

    searchQuery: '',
    selectedTab: null

  }),

  mutations: {
    setIsLoading(state, payload) {
      state.isLoading = payload
    },
    setIsRunConfirmed(state, payload) {
      state.isRunConfirmed = payload
    },
    setIsStatisticRunning(state, payload) {
      state.isStatisticRunning = payload
    },
    setProducts(state, payload) {
      state.products = payload
    },
    setTotalProducts(state, payload) {
      state.totalProducts = payload
    },
    setCleanProducts(state) {
      state.products = []
    },
    setParamsLimit(state, payload) {
      state.params.limit = payload
    },
    setParamsOffset(state, payload) {
      state.params.offset = payload
    },
    setParamsPriority(state, payload) {
      state.params.set_price_priority = payload
    },
    setSortParamsName(state, payload) {
      state.sortParams.name = payload
    },
    setSortParamsDate(state, payload) {
      state.sortParams.date = payload
    },
    setParamsSortBy(state) {

      let value = ''
      
      for(let sortParam in state.sortParams) {
        for(let sortType in state.sortParams[sortParam]) {

          if(state.sortParams[sortParam][sortType]) {
            value += `${sortParam}-${sortType},`
          } else {
            value = value.replace(`${sortParam}-${sortType}`, '')
          }
        }
      }

      state.params.sort_by = value.replace(/^,|,$/g, '')

    },
    setParamsIsFavorite(state, payload) {
      state.params.is_favorite = payload
    },
    setSingleProduct(state, payload) {
      state.singleProduct = payload.data.data.product
    },
    setSingleProductKeywords(state, keyword) {
      state.singleProduct.keywords.push(keyword)
    },
    setSingleProductKeywordsRemove(state, removeKeyword) {
      state.singleProduct.keywords = state.singleProduct.keywords.filter(keyword => keyword !== removeKeyword)
    },
    setProductBidder(state, payload) {
      state.productBidder = payload.data.data.product.wb_bidder
    },
    updateProductBidder(state, bidderData) {
      state.productBidder = bidderData
    },
    setSearchQuery(state, payload) {
      state.searchQuery = payload
    },
    setFavoriteProduct(state, id) {
      state.products.map(product => {
        if(product.id === id) product.favorite = !product.favorite
      })
    },
    setLeaderData(state, payload) {
       state.leaderData = payload
    },
    setGraphData(state, payload) {
       state.graphData = payload?.data?.data ?? []
    },
    setGraphData2(state, payload) {
       state.graphData2 = payload?.data?.data ?? []
    },
    setSelectedTab(state, payload) {
          state.selectedTab = payload
    },
  },

  actions: {

    productList({commit}, params) {
      commit('setIsLoading', true)
      commit('setParamsOffset', 0)
      return new Promise((resolve => {
        YC.priceBidderApi.getProductList(params)
          .then(products => {
            commit('setProducts', products.data.data.items)
            commit('setTotalProducts', products.data.data.items_count)
            resolve()
          })
          .finally(() => {
            commit('setIsLoading', false)
          })
      }))
    },

    productListLoadMore({state, commit}, params) {
      commit('setParamsOffset', state.params.offset + state.params.limit)
      return new Promise((resolve => {
        YC.priceBidderApi.getProductList(params)
          .then(products => { 
            commit('setProducts', [...state.products, ...products.data.data.items])
            resolve()
          })
          .finally(() => {
            commit('setIsLoading', false)
          })
      }))
    },

    updateBidderData({state, commit}, params) {

      const payload={
          product_id:params.id,
          auto:params.auto,
          min_price:params.bidder_min_price,
          max_price:params.bidder_max_price,
          keywords:params.keywords ? params.keywords.join(", ") :null,
      };



      return new Promise((resolve => {
        YC.priceBidderApi.updateBidderForProduct(payload)
          .then(products => {
            resolve()
          })
          .finally(() => {
            commit('setIsLoading', false)
          })
      }))
    },

    runBidder({commit}, params) {
          commit('setIsLoading', true)
          return new Promise((resolve => {
              YC.priceBidderApi.runBidderForProduct(params)
                  .then(results => {
                      //commit('setProducts', products)
                      console.log('results',results);
                        if(results?.data?.message)
                        {
                            //alert(results?.data?.message)
                            console.log('error',results);
                        }

                      commit('setIsLoading', false)
                      resolve()
                  })
                  .catch(results => {
                      console.log('error',results);
                      if(results?.response?.data?.error) {

                          console.log(results?.response?.data?.error)
                      }
                  })
                  .finally(results => {
                      commit('setIsLoading', false)


                  })
          }))
    },

    stopBidder({commit}, params) {

          return new Promise((resolve => {
              YC.priceBidderApi.stopBidderForProduct(params)
                  .then(results => {
                      //commit('setProducts', products)
                      console.log('results',results);
                        if(results?.data?.message)
                        {
                            //alert(results?.data?.message)
                            console.log('error',results);
                        }


                      resolve()
                  })
                  .catch(results => {
                      console.log('error',results);
                      if(results?.response?.data?.error) {

                          console.log(results?.response?.data?.error)
                      }
                  })

          }))
    },

    searchLeader({commit}, product_id) {
          commit('setIsLoading', true)
          const days=30;
          return new Promise((resolve => {
              YC.priceBidderApi.runSearchLeader(product_id,days)
                  .then(products => {
                      //commit('setProducts', products)

                      resolve()
                  })
                  .finally(() => {
                      commit('setIsLoading', false)
                  })
          }))
    },

    getSingleProduct({commit}, product_id) {
      return new Promise((resolve => {
        YC.priceBidderApi.getSingleProduct(product_id)
          .then(product => {
            commit('setSingleProduct', product)
            commit('setProductBidder', product)
            resolve()
          })
      }))
    },

    searchProducts({commit}, searchQuery) {
      commit('setIsLoading', true)
      return new Promise((resolve => {
        YC.priceBidderApi.searchProducts(searchQuery)
          .then(products => {
            commit('setProducts', products.data.data)
            resolve()
          })
          .finally(() => {
            commit('setIsLoading', false)
          })
      }))
    },

    getDiagram({commit}, product_id) {

      return new Promise((resolve => {
        YC.priceBidderApi.getProductDiagram(product_id)
          .then(graphData => {
              commit('setGraphData', graphData)

            resolve()
          })
          .finally(() => {

          })
      }))
    },

    getDiagram2({commit}, product_id) {

      return new Promise((resolve => {
        YC.priceBidderApi.getProductDiagram2(product_id)
          .then(graphData => {
              commit('setGraphData2', graphData)

            resolve()
          })
          .finally(() => {

          })
      }))
    },

    addFavoriteProduct({commit}, payload) {
      commit('setFavoriteProduct', payload)
      return new Promise((resolve, reject) => {
        YC.priceBidderApi.addFavorite(payload)
          .then(response => {
            resolve()
          })
          .catch(() => {
            reject()
          })
      })
    },

    removeFavoriteProduct({commit}, payload) {
      commit('setFavoriteProduct', payload)
      return new Promise((resolve, reject) => {
        YC.priceBidderApi.removeFavorite(payload)
          .then(response => {
            resolve()
          })
          .catch(() => {
            reject()
          })
      })
    },

    searchAllLeaders({commit}, payload) {
      return new Promise((resolve, reject) => {
        YC.priceBidderApi.searchAllLeaders(payload)
          .then(response => {
            resolve()
          })
          .catch((error) => {
            reject()
          })
      })
    },

    getLeadersByDay({commit}, payload) {
      return new Promise((resolve, reject) => {
        YC.priceBidderApi.get('wbbidder/leaders-v2/'+payload.product_id+'/'+payload.countdays)
          .then(response => {
            commit('setLeaderData', response?.data ?? null)
            resolve()
          })
          .catch((error) => {
            reject()
          })
      })
    },

    runStatisticService({commit}, payload) {
      return new Promise((resolve, reject) => {
          YC.priceBidderApi.get('wbbidder/get-one-product-leader/'+payload.product_id)
              .then(response => {
                  resolve(response)
              })
              .catch((error) => {
                  reject(error)
              })
      })
    },

    setProductPrice(store, payload) {

      return new Promise((resolve, reject) => {
        YC.priceBidderApi.post('wbbidder/set-product-price/'+payload.product_id,payload)
          .then(response => {

              let editSingleProductData=store?.state?.singleProduct
              console.log( "singleProduct" , editSingleProductData )
              console.log( "response" , response )
              if(editSingleProductData?.min_price && editSingleProductData?.max_price)
              {
                  editSingleProductData.min_price=parseInt(response?.data?.min_price) ?? 0
                  editSingleProductData.max_price=parseInt(response?.data?.max_price) ?? 0
                  store.state.singleProduct=editSingleProductData

              }



            resolve()
          })
          .catch((error) => {
            reject()
          })
          .finally(() => {

          })
      })
    },

      setPriceFromLeader(store, payload) {

          return new Promise((resolve, reject) => {
              YC.priceBidderApi.post('wbbidder/set-leader-price/'+payload.product_id,payload)
                  .then(response => {
                      let editSingleProductData=store?.state?.singleProduct
                      console.log( "singleProduct" , editSingleProductData )
                      console.log( "response" , response )

                      // if(editSingleProductData?.min_price && editSingleProductData?.max_price)
                      // {
                      //     editSingleProductData.min_price=parseInt(response?.data?.min_price) ?? 0
                      //     editSingleProductData.max_price=parseInt(response?.data?.max_price) ?? 0
                      //     store.state.singleProduct=editSingleProductData
                      //
                      // }
                      resolve(response)
                  })
                  .catch((error) => {
                      reject()
                  })
                  .finally(() => {

                  })
          })
      },



  }

}
