<template>
  <div class="singleProductHeader">

    <div class="singleProductHeader__imageWrapper">
      <img :src="singleProduct?.image" :alt="singleProduct.name" class="singleProductHeader__headerImage">
    </div>

    <div class="singleProductHeader__content">
      <div class="singleProductHeader__top">
        <div class="singleProductHeader__headlines">
          <div class="singleProductHeader__headlinesWrap">
            <h2 class="singleProductHeader__title">
              <span>{{singleProduct.name}}</span>
              <BaseFavoriteIcon 
                class="singleProductHeader__titleFavoriteIcon"
                :isFavorite="singleProduct.favorite"
              />
              <div class="singleProductHeader__titleFavorite"></div>
            </h2>
            <div class="singleProductHeader__article">Артикул: {{singleProduct.sku}}</div>
          </div>
        </div>
      </div>

      <ProductPrice        
        :priceDiscount="singleProduct.min_price"
        :priceWithoutDiscount="singleProduct.max_price"
      />
    </div>

  </div>
</template>

<script>
import {mapState} from 'vuex'
import ProductPrice from '@/components/ProductPrice'

export default {
  components: {
    ProductPrice
  },
  computed: {
    ...mapState({
      singleProduct: state => state.priceBidder.singleProduct
    })
  }
}
</script>

<style lang="scss">
@import 'src/scss/utils/mixins.scss';
@import 'src/scss/base/project/var.scss';

.singleProductHeader {
  z-index: 2;
  display: flex;
  align-items: center;
  &__imageWrapper {
    margin-right: 15px;
    flex: 0 0 74px;
    height: 100px;
    border-radius: 10px;
    overflow: hidden;
    @include breakpoint(xl) {
      flex: 0 0 58px;
      height: 80px;
    }
  }
  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  &__content {
    width: 100%;
  }
  &__top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }
  &__headlines {
    display: flex;
    width: 100%;
    &Wrap {
      margin-right: 5px;
      width: inherit;
    }
  }
  &__title {
    font-size: 24px;
    line-height: 24px;
    font-family: $font-secondary;
    font-weight: 400;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    &FavoriteIcon {
      flex: 0 0 30px;
      height: 30px;
    }
    span {
      flex: 1;
      margin-right: 5px;
      max-width: fit-content;
    }
    @include breakpoint(xl) {
      font-size: 20px;
      line-height: 20px;
    }
  }
  &__button {
    border-radius: 43px;
    max-width: 135px;
    height: 30px;
    @include breakpoint(xl) {
      height: 25px;
      max-width: 98px;
    }
  }
  &__article {
    font-weight: 500;
    @include breakpoint(xl) {
      font-size: 13px;
      line-height: 13px;
    }
  }
}

</style>
