<template>


    <div class="camp-table-header crow-header">

        <div class="camp-table-header-row-header"
             v-if="customTable===18"
        >
            <UnitEconomicsRow
                :color="color"
            />

        </div>
        <div class="camp-table-row camp-row camp-row-nowrap">


            <TableCellHeader
                v-for="(cellParams, index) in tableCellsParams"
                :key="index"
                :group_id="group_id"
                :field="cellParams.field"
                :name="cellParams.name"
                :width="cellParams.width"
                :type="cellParams.type"
                :value="cellParams.title"
                :is_extra="cellParams.is_extra"
                :withCheckbox="cellParams.withCheckbox"
                :isSticky="cellParams.isSticky"
                :color="color"
            />


        </div>

    </div>
    <!-- //theader -->


</template>

<script>
    /*
    * Компонент заголовочная строка таблицы
     */
    import TableCellHeader from "./TableCellHeader";
    import {mapState} from "vuex";
    import UnitEconomicsRow from "@/components/Crm/Desks/Custom/UnitEconomicsRow.vue";
    export default {
        name: "TableRowHeader",
        components: {UnitEconomicsRow, TableCellHeader},
        props: {
             tableCellsParams: {
                defalut:null,
                required: true,
            },

            group_id:Number,
            color:String,
        },
        computed: {
            ...mapState({
                deskData: state => state.crm.deskData,
            }),
            customTable()
            {
                return this.deskData?.data?.desk_id;
            },

        },
    }
</script>

<style scoped>
.camp-table-header.crow-header{
    position: sticky;
    top: 0;
    z-index: 2000;

}


</style>
