import PBBasicProductEditPopup from '@/views/PriceBidder/PBBasicProductEditPopup'
import PBBasicProductEditPopupKeywords from '@/views/PriceBidder/PBBasicProductEditPopupKeywords'
import PBBasicProductLeaderSearchPopup from '@/views/PriceBidder/PBBasicProductLeaderSearchPopup'
import PBBasicProductsInstructionPopup from '@/views/PriceBidder/PBBasicProductsInstructionPopup'
import PBSingleProductSalesLeaderSetPricePopup from '@/views/PriceBidder/PriceBidderSingleProduct/PBSingleProductSalesLeader/PBSingleProductSalesLeaderSetPricePopup'
import PBSingleProductManualPricePriorityPopup from '@/views/PriceBidder/PriceBidderSingleProduct/PBSingleProductPopup/PBSingleProductManualPricePriorityPopup'
import PBSingleProductSelectedPricePriorityPopup from '@/views/PriceBidder/PriceBidderSingleProduct/PBSingleProductPopup/PBSingleProductSelectedPricePriorityPopup'
import PBSingleProductBidderStartConfirmPopup from '@/views/PriceBidder/PriceBidderSingleProduct/PBSingleProductPopup/PBSingleProductBidderStartConfirmPopup'
import PBSingleProductLeaderSearchProgressbarPopup from '@/views/PriceBidder/PriceBidderSingleProduct/PBSingleProductPopup/PBSingleProductLeaderSearchProgressbarPopup'
import PBSingleProductLeaderSearchResultPopup from '@/views/PriceBidder/PriceBidderSingleProduct/PBSingleProductPopup/PBSingleProductLeaderSearchResultPopup'
import PBSingleProductBidderRefreshProductListPopup from '@/views/PriceBidder/PriceBidderSingleProduct/PBSingleProductPopup/PBSingleProductBidderRefreshProductListPopup'
import PBSingleProductFirstRunPopup from '@/views/PriceBidder/PriceBidderSingleProduct/PBSingleProductPopup/PBSingleProductFirstRunPopup'
import PBSingleProductInstructionPopup from '@/views/PriceBidder/PriceBidderSingleProduct/PBSingleProductInstructionPopup'

export default [
  PBBasicProductEditPopup,
  PBBasicProductEditPopupKeywords,
  PBBasicProductLeaderSearchPopup,
  PBBasicProductsInstructionPopup,
  PBSingleProductSalesLeaderSetPricePopup,
  PBSingleProductManualPricePriorityPopup,
  PBSingleProductSelectedPricePriorityPopup,
  PBSingleProductBidderStartConfirmPopup,
  PBSingleProductLeaderSearchProgressbarPopup,
  PBSingleProductLeaderSearchResultPopup,
  PBSingleProductBidderRefreshProductListPopup,
  PBSingleProductFirstRunPopup,
  PBSingleProductInstructionPopup,
]