<template>
  <BaseInstructionPopup
    :classModifier="setClassModified"
  >
    <div
      class="PBSingleProductInstructionPopup"
      :class="`PBSingleProductInstructionPopup--${setClassModified}`"
    >

      <div class="PBSingleProductInstructionPopup__content">
        
        <div
          class="PBSingleProductInstructionPopup__firstStep"
          v-if="firstStep"
        >

          <div class="PBSingleProductInstructionPopup__titlePopup titlePopup">1.</div>

          <p class="PBSingleProductInstructionPopup__text">Чтобы репрайсер нашел идеальную цену, укажите <span class="PBSingleProductInstructionPopup__textBlue">минимум</span> и <span class="PBSingleProductInstructionPopup__textBlue">максимум</span> 💰 </p>
          <p class="PBSingleProductInstructionPopup__text"><span class="PBSingleProductInstructionPopup__textBlue">Цена минимум</span> - цена, ниже которой репрайсер не опустится (оккупаемость по Unit-экономике).</p>
          <p class="PBSingleProductInstructionPopup__text"><span class="PBSingleProductInstructionPopup__textBlue">Цена максимум</span> - цена, выше которой не поднимется.</p>
          <p class="PBSingleProductInstructionPopup__text">Нажмите <span class="PBSingleProductInstructionPopup__textBlue">"Сохранить"</span> перед дальнейшими действиями 😉</p>
          <p class="PBSingleProductInstructionPopup__text"><span class="PBSingleProductInstructionPopup__textBlue">Например:</span> Грызунки для детей. Мин. цена 150 р., макс. цена 1050 р.</p>

        </div>

        <div
          class="PBSingleProductInstructionPopup__secondStep"
          v-else-if="secondStep"
        >

          <div class="PBSingleProductInstructionPopup__titlePopup titlePopup">2.</div>

          <p class="PBSingleProductInstructionPopup__text">Чтобы репрайсер знал, на какие цены конкурентов ориентироваться, впишите ключевые запросы для вашего товара.</p>
          <p class="PBSingleProductInstructionPopup__text">Рекомендуем указать 1-2 самых целевых. 🎯</p>
          <p class="PBSingleProductInstructionPopup__text"><span class="PBSingleProductInstructionPopup__textBlue">Пример</span> Пример: грызунки, грызунки детские</p>
          <p class="PBSingleProductInstructionPopup__text">Обязательно нажмите <span class="PBSingleProductInstructionPopup__textBlue">"Сохранить"</span> после ввода данных. </p>

          </div>

          <div
            class="PBSingleProductInstructionPopup__thirdStep"
            v-else-if="thirdStep"
          >

            <div class="PBSingleProductInstructionPopup__titlePopup titlePopup">3.</div>

            <p class="PBSingleProductInstructionPopup__text">Отлично! Почти все готово! Вы - молодец, повод похвалить себя 😉</p>
            <p class="PBSingleProductInstructionPopup__text">Нажмите <span class="PBSingleProductInstructionPopup__textBlue">"Включить репрайсер"</span> для анализа конкурентов. Система установит цену на ваш товар на 10 руб. ниже конкурента с наибольшими заказами.</p>
            <p class="PBSingleProductInstructionPopup__text">Репрайсер соберет данные и изменит цену с учетом ваших параметров. Да начнется волшебство! 🪄</p>

          </div>

          <div
            :class="`${fourthStep ? 'PBSingleProductInstructionPopup__fourthStep' : 'PBSingleProductInstructionPopup__fifthStep'}`"
            v-else-if="fourthStep || fifthStep"
          >

            <div class="PBSingleProductInstructionPopup__titlePopup titlePopup">4.</div>

            <p class="PBSingleProductInstructionPopup__text">Если вам больше не нужен репрайсер, переключитесь на  <span class="PBSingleProductInstructionPopup__textBlue">"Ручное управление ценой".</span></p>
            <p class="PBSingleProductInstructionPopup__text">Репрайсер перестанет менять цены, и вы сможете устанавливать их самостоятельно 😌</p>

          </div>
          
          <div
            class="PBSingleProductInstructionPopup__sixthStep"
            v-else-if="sixthStep"
          >

            <div class="PBSingleProductInstructionPopup__titlePopup titlePopup">5. <span>(1/3)</span></div>

            <p class="PBSingleProductInstructionPopup__text">Раздел <span class="PBSingleProductInstructionPopup__textBlue">"График продаж"</span> начнет работать после включения репрайсера, показывая динамику роста продаж по товару.</p>

          </div>

          <div
            class="PBSingleProductInstructionPopup__seventhStep"
            v-else-if="seventhStep"
          >

            <div class="PBSingleProductInstructionPopup__titlePopup titlePopup">5. <span>(2/3)</span></div>

            <p class="PBSingleProductInstructionPopup__text">Раздел <span class="PBSingleProductInstructionPopup__textBlue">"Лидеры продаж за разные периоды в днях"</span> покажет ваших конкурентов. Вы сможете установить их цену на свой товар при необходимости, что полезно для сезонных товаров.</p>

          </div>

          <div
            class="PBSingleProductInstructionPopup__eighthStep"
            v-else-if="eighthStep"
          >

            <div class="PBSingleProductInstructionPopup__titlePopup titlePopup">5. <span>(3/3)</span></div>

            <p class="PBSingleProductInstructionPopup__text">Раздел <span class="PBSingleProductInstructionPopup__textBlue">"История заказов"</span> покажет количество заказов и цену на каждую дату.</p>

          </div>

          <div
            class="PBSingleProductInstructionPopup__finalStep"
            v-else-if="finalStep"
          >

            <p class="PBSingleProductInstructionPopup__text">Это была короткая инструкция по репрайсеру от CAMP.</p>
            <p class="PBSingleProductInstructionPopup__text">Спасибо вам за ваше упорство. 😌</p>
            <p class="PBSingleProductInstructionPopup__text">Вперед к большим деньгам и прибыли! 🚀</p>

          </div>

      </div>

      <footer class="PBSingleProductInstructionPopup__footer">
        <div
          class="PBSingleProductInstructionPopup__dontShow"
          @click="dontShow"
        >
          Не показывать больше
        </div>
        <BaseButton
          class="PBSingleProductInstructionPopup__button button button--primary"
          @click="next"
        >
          {{buttonText}}
        </BaseButton>
      </footer>

    </div>
  </BaseInstructionPopup>
</template>

<script>
import {mapActions} from 'vuex'

export default {
  name: 'PBSingleProductInstructionPopup',
  data() {
    return {
      firstStep: true,
      secondStep: false,
      thirdStep: false,
      fourthStep: false,
      fifthStep: false,
      sixthStep: false,
      seventhStep: false,
      eighthStep: false,
      finalStep: false,
      buttonText: 'Далее'
    }
  },
  methods: {
    ...mapActions({
      closePopup: 'popup/closePopup',
      setInstructed: 'settings/setInstructed',
      getUser: 'auth/getUser'
    }),
    dontShow() {
      this.closePopup()
      this.setInstructed({repricer: true})
      .then(() => {
        this.getUser()
      })
    },
    next() {
      if(this.firstStep) {
        this.firstStepHandler()
      } else if(this.secondStep) {
        this.secondStepHandler()
      } else if(this.thirdStep) {
        this.thirdStepHandler()
      } else if(this.fourthStep) {
        this.fourthStepHandler()
      } else if(this.fifthStep) {
        this.fifthStepHandler()
      } else if(this.sixthStep) {
        this.sixthStepHandler()
      } else if(this.seventhStep) {
        this.seventhStepHandler()
      } else if(this.eighthStep) {
        this.eighthStepHandler()
      } else if(this.finalStep) {
        this.finalStepHandler()
      }
    },
    firstStepHandler() {
      document.getElementById('app').scrollIntoView(({block: "end"}))
      this.firstStep = false
      this.secondStep = true
    },
    secondStepHandler() {
      document.getElementById('app').scrollIntoView(({block: "start"}))
      this.secondStep = false
      this.thirdStep = true
    },
    thirdStepHandler() {
      this.thirdStep = false
      this.fourthStep = true
    },
    fourthStepHandler() {
      this.fourthStep = false
      this.fifthStep = true
      document.querySelector('.productSetting').scrollIntoView(({block: "end"}))
      if(window.innerHeight < 906) document.getElementById('app').scrollIntoView(({block: "end"}))
    },
    fifthStepHandler() {
      this.fifthStep = false
      this.sixthStep = true
      document.querySelector('.productSetting').scrollIntoView(({block: "start"}))
      document.getElementById('app').scrollIntoView(({block: "start"}))
    },
    sixthStepHandler() {
      this.sixthStep = false
      this.seventhStep = true
    },
    seventhStepHandler() {
      this.seventhStep = false
      this.eighthStep = true
      document.getElementById('app').scrollIntoView(({block: "end"}))
    },
    eighthStepHandler() {
      this.eighthStep = false
      this.finalStep = true
      document.getElementById('app').scrollIntoView(({block: "start"}))
      this.buttonText = 'Ура!'
    },
    finalStepHandler() {
      this.closePopup()
      this.setInstructed({repricer: true})
      .then(() => {
        this.getUser()
      })
    }
  },
  computed: {
    setClassModified() {
      if(this.firstStep) return 'PBSingleProductInstructionFirstStep'
      else if(this.secondStep) return 'PBSingleProductInstructionSecondStep'
      else if(this.thirdStep) return 'PBSingleProductInstructionThirdStep'
      else if(this.fourthStep) return 'PBSingleProductInstructionFourthStep'
      else if(this.fifthStep) return 'PBSingleProductInstructionFifthStep'
      else if(this.sixthStep) return 'PBSingleProductInstructionSixthStep'
      else if(this.seventhStep) return 'PBSingleProductInstructionSeventhStep'
      else if(this.eighthStep) return 'PBSingleProductInstructionEighthStep'
      else if(this.finalStep) return 'PBSingleProductInstructionFinalStep'
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'src/scss/base/project/var';
@import 'src/scss/utils/mixins';

.PBSingleProductInstructionPopup {

  padding: 20px;
  max-width: 100%;
  width: 500px;

  font-size: 14px;
  line-height: 14px;

  &::before {
    content: '';
    position: absolute;
    background-repeat: no-repeat;
    background-position: center;
  }

  &--PBSingleProductInstructionFirstStep {
    &::before {
      top: 139px;
      left: -400px;
      width: 391px;
      height: 48px;      
      transform: rotate(8deg);
      background-image: url("data:image/svg+xml,%3Csvg width='391' height='48' viewBox='0 0 391 48' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M390.734 5.91809C390.734 2.97257 388.346 0.584755 385.401 0.584755C382.455 0.584755 380.068 2.97257 380.068 5.91809C380.068 8.86361 382.455 11.2514 385.401 11.2514C388.346 11.2514 390.734 8.86361 390.734 5.91809ZM1.32303 30.3188C0.851134 30.6057 0.701232 31.2209 0.988219 31.6928L5.66467 39.3824C5.95163 39.8543 6.5668 40.0042 7.03867 39.7172C7.51056 39.4303 7.66043 38.8151 7.37347 38.3432L3.21664 31.508L10.0519 27.3511C10.5238 27.0642 10.6736 26.449 10.3867 25.9771C10.0997 25.5052 9.48456 25.3553 9.01266 25.6423L1.32303 30.3188ZM385.047 4.98278C289.778 41.0238 194.032 47.333 122.083 44.624C86.113 43.2696 56.1014 39.6616 35.0863 36.3931C24.579 34.7588 16.3215 33.2095 10.6935 32.0695C7.87961 31.4995 5.72314 31.0318 4.27161 30.707C3.54584 30.5446 2.99631 30.4178 2.62894 30.3319C2.44528 30.289 2.30713 30.2562 2.2153 30.2343C2.16937 30.2233 2.13501 30.215 2.11233 30.2096C2.10098 30.2068 2.09256 30.2048 2.08707 30.2035C2.08432 30.2028 2.0824 30.2023 2.08102 30.202C2.07983 30.2017 2.07937 30.2016 1.84262 31.1732C1.60586 32.1447 1.6069 32.145 1.60864 32.1454C1.6102 32.1458 1.61267 32.1464 1.61578 32.1471C1.62204 32.1487 1.63119 32.1509 1.64328 32.1538C1.66745 32.1596 1.70331 32.1682 1.75073 32.1796C1.84558 32.2022 1.98672 32.2357 2.17346 32.2794C2.54693 32.3667 3.10269 32.4949 3.83481 32.6587C5.29907 32.9864 7.46878 33.4569 10.2965 34.0297C15.9518 35.1753 24.2393 36.73 34.7789 38.3693C55.8575 41.6477 85.9475 45.2648 122.008 46.6225C194.121 49.3377 290.155 43.0194 385.755 6.8534L385.047 4.98278Z' fill='white'/%3E%3C/svg%3E%0A");
    }
  }

  &--PBSingleProductInstructionSecondStep {
    &::before {
      top: 145px;
      left: -250px;
      width: 255px;
      height: 198px;      
      transform: rotate(-8deg);
      background-image: url("data:image/svg+xml,%3Csvg width='255' height='198' viewBox='0 0 255 198' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M254.568 5.41483C254.568 8.36035 252.18 10.7482 249.234 10.7482C246.289 10.7482 243.901 8.36034 243.901 5.41483C243.901 2.46931 246.289 0.0814928 249.234 0.081493C252.18 0.0814933 254.568 2.46931 254.568 5.41483ZM8.07535 196.809C7.64023 197.149 7.01176 197.072 6.67163 196.637L1.12877 189.546C0.788634 189.111 0.86563 188.483 1.30075 188.142C1.73585 187.802 2.36433 187.879 2.70447 188.314L7.63144 194.617L13.9342 189.69C14.3693 189.35 14.9978 189.427 15.3379 189.862C15.6781 190.297 15.6011 190.926 15.166 191.266L8.07535 196.809ZM249.079 6.40265C195.688 -2.00628 154.004 5.66969 121.5 21.854C88.9864 38.0429 65.5849 62.7812 48.8276 88.606C32.0683 114.434 21.9706 141.325 16.0632 161.77C13.1102 171.99 11.2062 180.592 10.041 186.636C9.45839 189.657 9.06055 192.039 8.80865 193.663C8.68271 194.475 8.59326 195.097 8.53544 195.515C8.50653 195.724 8.48553 195.882 8.47183 195.988C8.46498 196.04 8.45996 196.079 8.45669 196.105C8.45506 196.118 8.45386 196.128 8.45309 196.134C8.4527 196.137 8.45244 196.139 8.45225 196.141C8.4521 196.142 8.45206 196.142 7.45947 196.021C6.46689 195.899 6.46705 195.898 6.46733 195.896C6.46756 195.894 6.46792 195.891 6.4684 195.887C6.46931 195.88 6.47067 195.869 6.47247 195.855C6.47606 195.826 6.4814 195.784 6.48858 195.729C6.50294 195.619 6.52464 195.456 6.55429 195.241C6.61355 194.813 6.7046 194.179 6.83229 193.356C7.08766 191.71 7.48962 189.304 8.07715 186.257C9.25212 180.163 11.1696 171.501 14.1418 161.215C20.0846 140.648 30.2515 113.56 47.1499 87.5174C64.0502 61.472 87.6995 36.4494 120.608 20.0637C153.526 3.67343 195.637 -4.03892 249.39 4.427L249.079 6.40265Z' fill='white'/%3E%3C/svg%3E%0A");    
    }
  }
  &--PBSingleProductInstructionThirdStep {
    &::before {
      left: -408px;
      top: -22px;
      transform: rotate(7deg);
      width: 413px;
      height: 142px;
      background-image: url("data:image/svg+xml,%3Csvg width='413' height='142' viewBox='0 0 413 142' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M412.467 136.22C412.467 133.274 410.079 130.886 407.134 130.886C404.188 130.886 401.8 133.274 401.8 136.22C401.8 139.165 404.188 141.553 407.134 141.553C410.079 141.553 412.467 139.165 412.467 136.22ZM0.755972 25.7461C0.483816 26.2267 0.652761 26.8369 1.13332 27.109L8.96468 31.5441C9.44524 31.8163 10.0554 31.6473 10.3276 31.1668C10.5998 30.6862 10.4308 30.076 9.95025 29.8038L2.98907 25.8615L6.93136 18.9003C7.20351 18.4198 7.03457 17.8095 6.55401 17.5374C6.07341 17.2652 5.46322 17.4342 5.19106 17.9147L0.755972 25.7461ZM408.122 136.064C399.681 82.4714 369.919 48.7217 330.079 28.2994C290.29 7.90339 240.47 0.80397 191.811 0.366276C143.135 -0.0715712 95.5246 6.15666 60.0938 12.4903C42.3757 15.6577 27.6972 18.8525 17.4462 21.256C12.3206 22.4578 8.30166 23.4618 5.56273 24.1658C4.19326 24.5177 3.14379 24.7947 2.43599 24.9839C2.08208 25.0784 1.81362 25.1511 1.63326 25.2001C1.54308 25.2247 1.47494 25.2433 1.42916 25.2559C1.40627 25.2622 1.38897 25.2669 1.37731 25.2702C1.37148 25.2718 1.36699 25.273 1.36407 25.2738C1.36095 25.2747 1.35927 25.2751 1.62612 26.2389C1.89297 27.2026 1.89412 27.2023 1.89669 27.2016C1.89944 27.2008 1.9034 27.1997 1.9089 27.1982C1.91985 27.1952 1.93645 27.1906 1.95861 27.1845C2.00298 27.1724 2.06969 27.1541 2.15844 27.13C2.3359 27.0817 2.60146 27.0098 2.95238 26.916C3.6542 26.7285 4.6975 26.4531 6.06057 26.1028C8.78676 25.4021 12.792 24.4015 17.9028 23.2032C28.1247 20.8065 42.768 17.6192 60.4458 14.4591C95.8066 8.13795 143.284 1.92985 191.793 2.36618C240.317 2.80267 289.777 9.88796 329.166 30.0792C368.504 50.244 397.816 83.484 406.146 136.375L408.122 136.064Z' fill='white'/%3E%3C/svg%3E%0A");
    }
  }
  &--PBSingleProductInstructionFourthStep {
    &::before {
      background-image: url("data:image/svg+xml,%3Csvg width='403' height='70' viewBox='0 0 403 70' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M402.777 6.24414C402.777 9.18966 400.389 11.5775 397.444 11.5775C394.498 11.5775 392.111 9.18966 392.111 6.24414C392.111 3.29862 394.498 0.910807 397.444 0.910807C400.389 0.910807 402.777 3.29862 402.777 6.24414ZM0.501309 41.2492C0.20239 40.7848 0.336514 40.166 0.8009 39.867L8.36856 34.9956C8.83294 34.6967 9.45175 34.8308 9.75067 35.2952C10.0496 35.7596 9.91546 36.3784 9.45108 36.6774L2.72427 41.0075L7.05441 47.7343C7.35336 48.1987 7.2192 48.8175 6.75482 49.1164C6.29043 49.4153 5.67163 49.2812 5.37271 48.8168L0.501309 41.2492ZM398.432 6.39972C393.747 36.1434 366.109 52.9881 328.045 61.689C289.91 70.4062 240.855 71.0709 192.46 68.1053C144.047 65.1386 96.2159 58.5333 60.4942 52.6714C42.6317 49.7402 27.7936 46.9942 17.42 44.9809C12.2332 43.9742 8.16226 43.1506 5.38733 42.5785C3.99987 42.2925 2.9364 42.0693 2.21939 41.9176C1.8609 41.8417 1.58902 41.7837 1.40655 41.7446C1.31534 41.7251 1.24646 41.7103 1.20031 41.7003C1.17724 41.6953 1.15985 41.6916 1.14816 41.6891C1.1423 41.6878 1.13784 41.6868 1.13495 41.6862C1.13189 41.6856 1.13031 41.6852 1.34216 40.7079C1.55401 39.7306 1.55529 39.7309 1.55801 39.7315C1.56085 39.7321 1.56503 39.733 1.57068 39.7342C1.58197 39.7366 1.59897 39.7403 1.62164 39.7452C1.66702 39.755 1.7351 39.7696 1.82553 39.789C2.00638 39.8277 2.27661 39.8854 2.63348 39.9609C3.34717 40.112 4.40722 40.3344 5.79117 40.6197C8.55902 41.1903 12.6223 42.0124 17.8011 43.0175C28.1587 45.0278 42.9776 47.7702 60.818 50.6978C96.5021 56.5536 144.261 63.1479 192.582 66.109C240.922 69.0712 289.747 68.3918 327.599 59.7393C365.523 51.0704 391.978 34.5197 396.456 6.08856L398.432 6.39972Z' fill='white'/%3E%3C/svg%3E%0A");
      width: 403px;
      height: 70px;
      left: -404px;
      transform: rotate(-10deg);
      top: 110px;
    }
  }
  &--PBSingleProductInstructionFifthStep {
    &::before {
      width: 428px;
      height: 223px;
      left: -421px;
      top: 110px;
      transform: rotate(-8deg);
      background-image: url("data:image/svg+xml,%3Csvg width='428' height='223' viewBox='0 0 428 223' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M427.584 5.93945C427.584 8.88497 425.196 11.2728 422.251 11.2728C419.305 11.2728 416.918 8.88497 416.918 5.93945C416.918 2.99393 419.305 0.606119 422.251 0.60612C425.196 0.60612 427.584 2.99393 427.584 5.93945ZM0.498151 214.602C0.164166 214.162 0.249951 213.535 0.689801 213.201L7.8575 207.758C8.29732 207.424 8.92466 207.51 9.25865 207.95C9.59266 208.389 9.50685 209.017 9.067 209.351L2.69572 214.189L7.53383 220.56C7.86781 221 7.78199 221.627 7.34217 221.961C6.90232 222.295 6.27501 222.209 5.94099 221.77L0.498151 214.602ZM423.239 6.09503C402.662 136.742 296.874 188.923 196.606 208.462C146.453 218.236 97.5966 219.866 61.2874 219.055C43.1302 218.65 28.1047 217.634 17.6158 216.719C12.3713 216.261 8.26057 215.829 5.45933 215.511C4.0587 215.352 2.98539 215.221 2.26136 215.131C1.89937 215.085 1.62465 215.05 1.44001 215.025C1.3477 215.013 1.27791 215.004 1.23097 214.998C1.2075 214.995 1.18974 214.992 1.17775 214.991C1.17173 214.99 1.16707 214.989 1.16407 214.989C1.16084 214.988 1.15904 214.988 1.29457 213.997C1.4301 213.006 1.4312 213.007 1.43376 213.007C1.43654 213.007 1.44056 213.008 1.44612 213.009C1.4572 213.01 1.47407 213.012 1.49666 213.015C1.54179 213.021 1.60978 213.031 1.70027 213.042C1.88124 213.066 2.15223 213.101 2.51045 213.146C3.22694 213.236 4.29243 213.366 5.68498 213.524C8.47017 213.84 12.5635 214.271 17.7896 214.726C28.2421 215.638 43.2241 216.651 61.332 217.056C97.5528 217.864 146.256 216.236 196.223 206.499C296.195 187.018 400.885 135.171 421.263 5.78387L423.239 6.09503Z' fill='white'/%3E%3C/svg%3E%0A");
    }
  }
  


  &__content {
     margin-bottom: 20px;
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__button {
    height: 34px;
    width: 87px;
    border-radius: 10px;
  }
  &__dontShow {
    font-size: 12px;
    line-height: 12px;
    color: $gray-450;
    cursor: pointer;
  }
  
  &__titlePopup {
    text-align: left;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    span {
      margin-left: 5px;
      font-size: 16px;
      line-height: 16px;
      color: $gray-450;
      font-weight: 400;
    }
  }
  &__text {
    margin-bottom: 20px;
    &:last-of-type {
      margin-bottom: 0;
    }
    &Blue {
      color: $dark-green-400;
      font-weight: 600;
    }
  }

}

</style>
