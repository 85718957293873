<template>
  <BasePopup>

    <div class="setPricePopup">
      <div class="setPricePopup__title">
          <template v-if="leaderFound">Вы действительно хотите заменить цену на цену конкурента: <br/>
              <div class="setPricePopup__sku_link">Артикул : <a class="setPricePopup__link" target="_blank"  :href="getProdLink(sku)">{{sku}}</a></div>

              Цена: {{minPrice}}₽ Скидка:  {{discount}}%?
          </template>
          <template v-else>
          Для установки цены, необходим поиск лидера.
          </template>
      </div>

      <div class="setPricePopup__controls">
          <template v-if="leaderFound">
        <button @click="confirmSetPrice"
            class="setPricePopup__button button button--transparent button--green">Да</button>
        <button  @click="declineSetPrice"
            class="setPricePopup__button button button--transparent button--green">Нет</button>
          </template>
          <template v-else>

          </template>
      </div>
    </div>

  </BasePopup>
</template>

<script>

import { mapActions } from 'vuex'

export default {
  name: "PBSingleProductSalesLeaderSetPricePopup",
  props: {
    minPrice: {
      type: [String,Number],
      required: true
    },
    maxPrice: {
      type: [String,Number],
      required: true
    },
    discount: {
      type: [String,Number],
      required: true
    },
	sku: {
      type: [String,Number],
      required: true
    },
	leaderFound:true,
  },
  methods:
  {
      ...mapActions({
          openPopup: 'popup/openPopup',
          closePopup: 'popup/closePopup',
          setPriceFromLeader: 'priceBidder/setPriceFromLeader',
          getSingleProduct: 'priceBidder/getSingleProduct',
      }),
      confirmSetPrice(){
          this.setPriceFromLeader(
              {
                  product_id: this.$route.params.id,
                  leader_price: this.minPrice,
              }
          ).then(r=>{
              this.getSingleProduct(this.$route.params.id)
              console.log( "rPop" , r )
              this.openPopup(
                  {
                      component: 'SettingsAlertPopup',
                      props: {
                          text: 'Цена была успешно установлена. Новая цена: '+r?.data?.new_price + '₽. Скидка: '+r?.data?.discount +'%'
                      }
                  }
              )
          })

          this.closePopup()
      },
      declineSetPrice(){
          this.closePopup()
      },
      getProdLink(sku){
          return sku ? 'https://www.wildberries.ru/catalog/'+sku+'/detail.aspx' :'';
      }

  },
  computed:{

  }
}
</script>

<style lang="scss">
@import 'src/scss/utils/mixins.scss';
@import 'src/scss/base/project/var.scss';

.setPricePopup {
  max-width: 800px;
  width: 100%;
  padding: 60px 100px 40px;
  text-align: center;

  @include breakpoint(md) {
    max-width: 340px;
    padding: 60px 40px 40px;
  }

  @include breakpoint(sm) {
    padding: 60px 30px 30px;
  }

  @include breakpoint(xs) {
    padding: 50px 20px 30px;
  }

  &__sku_link {
      padding: 10px 0;
  }
  &__link {
      color: #00a0a7;
  }
  &__title {
    font-size: 20px;
    line-height: 20px;
    margin-bottom: 25px;
    @include breakpoint(xl) {
      font-size: 18px;
      line-height: 18px;
      max-width: 700px;
    }
    @include breakpoint(md) {
      font-size: 20px;
      line-height: 20px;
    }
  }

  &__controls {
    display: flex;
    justify-content: center;
  }

  &__button {
    flex: 0 0 120px;
    height: 30px;
    &:first-child {
      margin-right: 20px;
    }
    @include breakpoint(sm) {
      flex: 0 0 120px;
      height: 40px;
    }
  }
}

</style>
