<template>
  <BaseInstructionPopup
    :classModifier="setClassModified"
  >
    <div
      class="PBBasicProductsInstructionPopup"
      :class="`PBBasicProductsInstructionPopup--${setClassModified}`"
    >

      <div class="PBBasicProductsInstructionPopup__content">

        <div
          class="PBBasicProductsInstructionPopup__firstStep"
          v-if="firstStep"
        >
          <div class="PBBasicProductsInstructionPopup__titlePopup titlePopup">Добро пожаловать <br> в репрайсер цен от CAMP! 😌</div>
          <p class="PBBasicProductsInstructionPopup__text">Вот <span class="PBBasicProductsInstructionPopup__textBlue">5 простых шагов</span>  для настройки и комфортного использования:</p>
          <p class="PBBasicProductsInstructionPopup__text">Инструмент поможет Вам найти идеальную цену на товар, учитывая вашу Unit-экономику и спрос рынка.</p>
          <p class="PBBasicProductsInstructionPopup__text">Вам понравится! 😉</p>
        </div>

        <div
          class="PBBasicProductsInstructionPopup__secondStep"
          v-else
        >
          <p class="PBBasicProductsInstructionPopup__text">Вот список товаров вашего магазина на маркетплейсе. Чтобы включить репрайсер, зайдите в карточку товара и настройте все параметры.</p>
          <p class="PBBasicProductsInstructionPopup__text"><span class="PBBasicProductsInstructionPopup__textBlue">P.S.</span> Редактируйте данные через кнопку <span class="PBBasicProductsInstructionPopup__textBlue">быстрого редактирования 😉</span></p>
        </div>

      </div>

      <footer class="PBBasicProductsInstructionPopup__footer">
        <div
          class="PBBasicProductsInstructionPopup__dontShow"
          @click="dontShow"
        >
          Не показывать больше
        </div>
        <BaseButton
          class="PBBasicProductsInstructionPopup__button button button--primary"
          @click="next"
        >
          Далее
        </BaseButton>
      </footer>

    </div>
  </BaseInstructionPopup>
</template>

<script>
import {mapState, mapActions} from 'vuex'

export default {
  name: 'PBBasicProductsInstructionPopup',
  data() {
    return {
      firstStep: true,
      secondStep: false,
      thirdStep: false
    }
  },
  methods: {
    ...mapActions({
      closePopup: 'popup/closePopup',
      setInstructed: 'settings/setInstructed',
      getUser: 'auth/getUser'
    }),
    dontShow() {
      this.closePopup()
      this.setInstructed({repricer: true})
      .then(() => {
        this.getUser()
      })
    },
    next() {
      if(this.firstStep) {
        this.firstStepHandler()
      } else if(this.secondStep) {
        this.secondStepHandler()
      } else if(this.thirdStep) {
        this.thirdStepHandler()
      }
    },
    firstStepHandler() {
      this.firstStep = false
      this.secondStep = true
    },
    secondStepHandler() {
      this.secondStep = false
      this.thirdStep = true
    },
    thirdStepHandler() {
      this.$router.push(`/price_bidder/product/${this.products[0].id}`)
    }
  },
  computed: {
    ...mapState({
      products: state => state.priceBidder.products,
    }),
    setClassModified() {
      if(this.firstStep) return 'PBBasicProductsInstructionFirstStep'
      else if(this.secondStep) return 'PBBasicProductsInstructionSecondStep'
      else return 'PBBasicProductsInstructionThirdStep'
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'src/scss/base/project/var';
@import 'src/scss/utils/mixins';

.PBBasicProductsInstructionPopup {

  padding: 20px;

  &__firstStep {
    width: 460px;
    max-width: 100%;
  }

  &__secondStep {
    width: 520px;
  }

  &--PBBasicProductsInstructionThirdStep {
    &::before {
      content: '';
      position: absolute;
      top: -20px;
      right: -610px;
      width: 603px;
      height: 156px;
      transform: rotate(357deg);
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='603' height='156' viewBox='0 0 603 156' fill='none'%3E%3Cpath d='M0.299479 52.4646C0.299479 49.5191 2.68729 47.1313 5.63281 47.1313C8.57833 47.1313 10.9661 49.5191 10.9661 52.4646C10.9661 55.4102 8.57833 57.798 5.63281 57.798C2.68729 57.798 0.299479 55.4102 0.299479 52.4646ZM594.013 0.858963C594.384 0.449509 595.016 0.41806 595.426 0.788696L602.098 6.82857C602.507 7.1992 602.539 7.83159 602.168 8.24104C601.797 8.65048 601.165 8.68195 600.756 8.31131L594.825 2.94252L589.456 8.87347C589.085 9.28293 588.453 9.31438 588.043 8.94374C587.634 8.5731 587.603 7.94072 587.973 7.53128L594.013 0.858963ZM6.02451 51.5445C308.217 180.191 455.065 167.261 526.335 122.646C561.953 100.349 578.802 70.0772 586.754 45.3684C590.731 33.0106 592.482 22.047 593.237 14.1774C593.615 10.2431 593.744 7.08409 593.779 4.91307C593.797 3.82762 593.791 2.98933 593.78 2.42487C593.775 2.14262 593.769 1.92886 593.764 1.7869C593.761 1.71591 593.759 1.66289 593.758 1.62822C593.757 1.61089 593.757 1.59814 593.756 1.59006C593.756 1.586 593.756 1.58344 593.756 1.58139C593.756 1.57999 593.756 1.57974 594.754 1.53006C595.753 1.48038 595.753 1.48248 595.753 1.48576C595.754 1.48842 595.754 1.49287 595.754 1.49818C595.755 1.5088 595.755 1.52408 595.756 1.54396C595.758 1.58371 595.76 1.64191 595.763 1.71811C595.768 1.8705 595.774 2.09496 595.78 2.38812C595.791 2.97444 595.797 3.83571 595.779 4.94518C595.743 7.16402 595.612 10.3764 595.228 14.3686C594.462 22.3516 592.688 33.4604 588.658 45.9811C580.596 71.0295 563.501 101.739 527.396 124.341C455.219 169.525 307.507 182.062 5.24112 53.3847L6.02451 51.5445Z' fill='white'/%3E%3C/svg%3E");
      @include breakpoint(xl) {
        width: 357px;
        height: 119px;
        right: -360px;
        transform: rotate(353deg);
        background-image: url("data:image/svg+xml,%3Csvg width='357' height='119' viewBox='0 0 357 119' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.496745 50.0782C0.496745 47.1327 2.88456 44.7449 5.83008 44.7449C8.7756 44.7449 11.1634 47.1327 11.1634 50.0782C11.1634 53.0237 8.7756 55.4115 5.83008 55.4115C2.88456 55.4115 0.496745 53.0237 0.496745 50.0782ZM348.506 0.935883C348.919 0.569618 349.551 0.607796 349.918 1.02116L355.886 7.75729C356.252 8.17065 356.214 8.80267 355.801 9.16893C355.388 9.53519 354.756 9.49702 354.389 9.08366L349.084 3.09598L343.096 8.40143C342.683 8.76769 342.051 8.72952 341.685 8.31615C341.318 7.90279 341.356 7.27077 341.77 6.90451L348.506 0.935883ZM6.30209 49.1966C171.08 137.42 256.419 125.088 300.548 91.2936C322.653 74.3656 334.564 51.9564 340.95 33.7424C344.142 24.6386 345.948 16.5958 346.956 10.8328C347.46 7.95174 347.764 5.64194 347.942 4.05595C348.031 3.263 348.089 2.65113 348.124 2.23941C348.141 2.03356 348.153 1.87776 348.161 1.7744C348.164 1.72272 348.167 1.68415 348.169 1.65899C348.17 1.64641 348.17 1.63718 348.171 1.63135C348.171 1.62843 348.171 1.6266 348.171 1.62514C348.171 1.62416 348.171 1.62404 349.169 1.68434C350.167 1.74464 350.167 1.74624 350.167 1.74869C350.167 1.75064 350.167 1.75395 350.166 1.75786C350.166 1.76567 350.165 1.77689 350.164 1.79147C350.162 1.82063 350.159 1.86324 350.155 1.91901C350.147 2.03054 350.135 2.19469 350.116 2.40907C350.08 2.8378 350.021 3.46748 349.93 4.27898C349.748 5.90186 349.438 8.25242 348.926 11.1774C347.903 17.0263 346.072 25.178 342.837 34.4041C336.37 52.8498 324.275 75.6422 301.764 92.8814C256.661 127.421 170.33 139.287 5.35807 50.9598L6.30209 49.1966Z' fill='white'/%3E%3C/svg%3E%0A");
      }
    }
  }

  font-size: 14px;
  line-height: 14px;

  &__content {
     margin-bottom: 20px;
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__button {
    height: 34px;
    width: 87px;
    border-radius: 10px;
  }
  &__dontShow {
    font-size: 12px;
    line-height: 12px;
    color: $gray-450;
    cursor: pointer;
  }
  
  &__titlePopup {
    text-align: left;
    margin-bottom: 20px;
  }
  &__text {
    margin-bottom: 20px;
    &:last-of-type {
      margin-bottom: 0;
    }
    &Blue {
      color: $dark-green-400;
      font-weight: 600;
    }
  }

}

</style>
