:<template>
  <aside
    class="sidebar" 
    :class="['sidebar--' + getModifyClass, {'sidebar--rollUp': sidebarRollUp}]"  
  >

    <BaseSidebarBackButton      
      :text="'Меню'"
      class="sidebar__sidebarBackButton"
      v-if="buttonBackShow && this.$store.state.isMobile"
    />

    <Profile
      class="sidebar__profile"
      :classModificator="`profile--sidebar`"  
      @dropdownStatus="handlerButtonBack"
    />

    <div
      class="sidebar__wrapper"
    >
      <div class="sidebar__container">

        <SidebarHeader      
          class="sidebar__sidebarHeader"  
          v-if="!this.$store.state.isMobile"
        />

        <SidebarContent        
          class="sidebar__sidebarContent" 
          :class="{'sidebar__sidebarContent--rollUp': sidebarRollUp}" 
        />

      </div>

      <div
        class="sidebar__rollUp"
        @click="sidebarRollUpAction(sidebarRollUp ? false : true)"
        v-if="!this.$store.state.isMobile"
      >
        <SvgUse
          :width="14"
          :height="14"
          id="rollUp"
          v-if="!sidebarRollUp"
        />
        <SvgUse
          :width="14"
          :height="14"
          id="rollUpActive"
          v-else
        />
        <div class="sidebar__rollUpText" v-if="!sidebarRollUp">Свернуть</div>
      </div>
    </div>
    
  </aside>
</template>

<script>
import SidebarHeader from '@/components/Sidebar/SidebarHeader'
import SidebarContent from '@/components/Sidebar/SidebarContent'
import {mapState, mapActions} from 'vuex'

import Profile from '@/components/Profile'

export default {
  components: {
    SidebarContent,
    SidebarHeader,
    Profile
  },
  data() {
    return {
      buttonBackShow: false
    }
  },
  methods: {
    ...mapActions({
      sidebarRollUpAction: 'sidebarRollUpAction'
    }),
    handlerButtonBack(val) {
      this.buttonBackShow = val
    }
  },
  computed: {
    ...mapState({
      sidebarRollUp: state => state.sidebarRollUp
    }),
    getModifyClass() {
      return `${this.$route.name}`
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'src/scss/utils/mixins.scss';
@import 'src/scss/base/project/var.scss';

.sidebar {
  height: 100vh;
  z-index: 4;
  background-color: $white;
  padding: 20px 0 20px 20px;
  background-image: none;
  background-color: transparent;
  font-family: $font-primary;
  box-shadow: none;
  overflow: inherit;
  @include breakpoint(md) {
    padding: 10px;
  }

  &--rollUp {
    max-width: 100px;
  }
  &--rollUp &__wrapper {
    padding-left: 25px;
    padding-right: 25px;
  }
  &--rollUp &__profile {
    right: 25px;
  }
  &--rollUp &__container {
    min-width: 30px;
  }

  &__sidebarBackButton {
    padding: 10px;
    margin-bottom: 5px;
  }

  &__profile {
    position: absolute;
    top: 50px;
    right: 30px;
    @include breakpoint(md) {
      position: relative;
      left: 0;
      top: 0;
      background-color: $white;
      width: 100%;
      padding: 20px 30px;
      border-radius: 20px;
    }
    @include mediaMaxWidth(375) {
      padding: 20px;
    }
  }

  &__wrapper {
    // display: flex;
    // flex-direction: column;
    // justify-content: space-between;
    font-weight: 600;
    padding: 30px 30px 45px;
    box-shadow: 5px 0px 10px 0px rgba($black, 0.15);
    border-radius: 50px;
    background-image: radial-gradient(278.96% 86.84% at 50% 22.96%, #0153CE 0%, #006FD6 64.62%, #00D1D1 100%);
    height: 100%;
    z-index: 5;
    position: relative;
    @include breakpoint(md) {
      border-radius: 20px;
      background-image: none;
      padding: 20px;
      box-shadow: none;
    }
  }

  &__container {
    min-width: 237px;
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &--crm {
    color: $black;
    background-color: $white;
    background-image: none;
  }

  

  &__sidebarHeader {
    margin-bottom: 50px;
  }

  &__sidebarContent {
    flex: 1;
    overflow-y: scroll;
    margin-right: -30px;
    padding-right: 30px;
    &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 7px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: rgba(0, 0, 0, .5);
      box-shadow: 0 0 1px rgba(255, 255, 255, .5);
    }
    &--rollUp {
      overflow: visible;
      margin-right: 0;
      padding-right: 0;
    }
  }

  &__rollUp {
    position: relative;
    bottom: -15px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &Text {
      font-size: 10px;
      margin-left: 6.5px;
    }
  }

}
</style>