<template>
  <BasePopup :classModifier="'tickets-chat'">
    <div class="ticketChat">
      <div class="ticketChat__header">

        <div class="ticketChat__headerOperator">
          <div
            class="ticketChat__headerOperatorIcon"
            :class="{ 'ticketChat__headerOperatorIcon--gray': selectedUserTicket.status === 'closed' }"
          >
          </div>
          {{ selectedUserTicket.client }}
        </div>
        <div class="ticketChat__headerTicketInfo">
          <span>{{ selectedUserTicket.title }}</span>
          <span>ID тикета: {{ selectedUserTicket.id }}</span>
        </div>
        <BaseButton
          v-if="selectedUserTicket.status !== 'closed'"
          class="ticketChat__headerButton button button--transparent"
          @click="closeTicket"
        >
          Закрыть тикет
        </BaseButton>
        <div
          v-else
          class="ticketChat__headerTicketClosed"
        >
          Тикет закрыт
        </div>
      </div>

      <div class="ticketChat__main">

        <div
          class="ticketChat__mainMessagesPerDate"
          v-for="message in messages"
          :key="message.date"
        >
          <div class="ticketChat__mainMessagesDate">{{ transformDate(message.date, 'fullDate') }}</div>

          <div
            v-for="chat in message.chat"
            :key="chat.id"
            :class="[
              'ticketChat__mainMessage',
              chat.user_id === currentUser.id ?
                'ticketChat__mainMessage--user' :
                'ticketChat__mainMessage--operator'
            ]"
          >
            <div class="ticketChat__mainMessageContent">
              {{ chat.description }}
            </div>
            <div class="ticketChat__mainMessageInfo">
              <div class="ticketChat__mainMessageInfoTime">{{ transformDate(chat.created_at, 'time') }}</div>
              <div class="ticketChat__mainMessageInfoChecked">
                <SvgUse
                  :width="13"
                  :height="10"
                  id="checkedMsg"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="ticketChat__actions"
        v-if="selectedUserTicket.status !== 'closed'"
      >
        <input
          type="text"
          class="ticketChat__actionsTextarea"
          placeholder="Введите сообщение"
          v-model="message"
        />
        <BaseButton
          class="ticketChat__actionsButton button--primary"
          @click="sendMessage(selectedUserTicket.id)"
        >
          Отправить
        </BaseButton>
      </div>
    </div>
  </BasePopup>
</template>

<script>
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex'

export default {
  name: 'TicketsChatPopup',
  props: {
    id: {
      type: Number
    },
    closed: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      message: ''
    }
  },
  computed: {
    ...mapState({
      selectedUserTicket: (state) => state.userTickets.selectedUserTicket,
      currentUser: (state) => state.auth.user,
    }),
    ...mapGetters({
      messages: 'userTickets/messages'
    })
  },
  methods: {
    ...mapActions({
      openPopup: 'popup/openPopup',
      sendNewMessage: 'userTickets/sendNewMessage'
    }),
    ...mapMutations({
      resetSelectedUserTicket: 'userTickets/setSelectedUserTicket'
    }),
    transformDate(date, format) {
      const currentDate = new Date(date).toLocaleString().replaceAll(".", "-")

      if (format === 'fullDate') {
        return currentDate.slice(6, 10) + '-' + currentDate.slice(3, 5) + '-' + currentDate.slice(0, 2)
      } else if (format === 'time') {
        return currentDate.slice(12, 17)
      }

    },
    sendMessage(id) {
      this.sendNewMessage({ id, message: this.message, status: null })
      this.message = ''
    },
    closeTicket() {
      this.openPopup({component: 'TicketsAssessSupportPopup'})
      this.sendNewMessage({ id: this.selectedUserTicket.id, message: '', status: 'closed' })
    }
  },
  beforeUnmount() {
    this.resetSelectedUserTicket('')
  },
  unmounted() {
    this.$router.replace({'query': null});
  }
}
</script>

<style lang="scss" scoped>
@import 'src/scss/base/project/var.scss';
@import 'src/scss/utils/mixins.scss';

.ticketChat {
  max-width: 100%;
  width: 1440px;
  padding: 30px 50px;
  font-size: 15px;

  @include breakpoint(xl) {
    font-size: 13px;
    padding: 30px;
    width: 1200px;
  }

  @include breakpoint(md) {
    font-size: 15px;
    padding: 20px 30px;
    width: 700px;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &Operator {
      display: flex;
      align-items: center;

      &Icon {
        margin-right: 10px;
        width: 30px;
        height: 30px;
        background-repeat: no-repeat;
        background-position: center;
        background-image: url("data:image/svg+xml,%3Csvg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='15' cy='15' r='15' fill='%23D9D9D9'/%3E%3Ccircle cx='15' cy='15' r='15' fill='url(%23paint0_linear_1_437)'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8.18394 12.0505C7.25662 12.1916 6.54541 13.0068 6.54541 13.9914V17.9163C6.54541 19.0002 7.4073 19.8788 8.47054 19.8788H9.2406C10.3038 19.8788 11.1657 19.0002 11.1657 17.9163V13.9914C11.1657 13.1493 10.6455 12.4312 9.91486 12.1526C9.95293 9.42226 12.136 7.22079 14.8235 7.22079C17.4908 7.22079 19.6612 9.3893 19.7309 12.0912C18.9014 12.3096 18.2887 13.0775 18.2887 13.9914V17.9163C18.2887 19.0002 19.1506 19.8788 20.2139 19.8788H19.9251C19.9251 20.3676 19.6628 20.8668 19.1279 21.2686C18.6952 21.5936 18.1078 21.8318 17.4325 21.9109C17.2223 21.299 16.6508 20.86 15.9786 20.86H14.4385C13.5878 20.86 12.8984 21.563 12.8984 22.43C12.8984 23.2971 13.5878 24 14.4385 24H15.9786C16.5117 24 16.9816 23.7238 17.2581 23.3042C18.2755 23.2464 19.2118 22.9119 19.9273 22.3745C20.7162 21.782 21.2727 20.9074 21.2727 19.8788H20.9839C22.0472 19.8788 22.909 19.0002 22.909 17.9163V13.9914C22.909 13.0764 22.2948 12.3077 21.4639 12.0903C21.3935 8.41328 18.4474 5.45456 14.8235 5.45456C11.2126 5.45456 8.27483 8.39196 8.18394 12.0505Z' fill='white'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_1_437' x1='23.4' y1='30' x2='4.8' y2='-4.2' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%230084B8'/%3E%3Cstop offset='1' stop-color='%2300D1C1'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A");

        &--gray {
          background-image: url("data:image/svg+xml,%3Csvg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='15' cy='15' r='15' fill='%23D9D9D9'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8.18394 12.0505C7.25662 12.1916 6.54541 13.0067 6.54541 13.9913V17.9163C6.54541 19.0002 7.4073 19.8788 8.47054 19.8788H9.2406C10.3038 19.8788 11.1657 19.0002 11.1657 17.9163V13.9913C11.1657 13.1493 10.6455 12.4312 9.91486 12.1526C9.95293 9.42223 12.136 7.22076 14.8235 7.22076C17.4908 7.22076 19.6612 9.38927 19.7309 12.0911C18.9014 12.3096 18.2887 13.0775 18.2887 13.9913V17.9163C18.2887 19.0002 19.1506 19.8788 20.2139 19.8788H19.9251C19.9251 20.3675 19.6628 20.8668 19.1279 21.2686C18.6952 21.5935 18.1078 21.8318 17.4325 21.9109C17.2223 21.2989 16.6508 20.86 15.9786 20.86H14.4385C13.5878 20.86 12.8984 21.5629 12.8984 22.43C12.8984 23.2971 13.5878 24 14.4385 24H15.9786C16.5117 24 16.9816 23.7238 17.2581 23.3042C18.2755 23.2464 19.2118 22.9119 19.9273 22.3745C20.7162 21.7819 21.2727 20.9074 21.2727 19.8788H20.9839C22.0472 19.8788 22.909 19.0002 22.909 17.9163V13.9913C22.909 13.0764 22.2948 12.3077 21.4639 12.0903C21.3935 8.41325 18.4474 5.45453 14.8235 5.45453C11.2126 5.45453 8.27483 8.39193 8.18394 12.0505Z' fill='white'/%3E%3C/svg%3E%0A");
        }
      }
    }

    &TicketInfo {
      span {
        display: block;
        text-align: center;

        &:first-child {
          margin-bottom: 5px;
        }

        &:last-child {
          font-size: 12px;
          line-height: 12px;
          color: $gray-400;
        }
      }
    }

    &Button {
      flex: 0 0 100%;
      max-width: 153px;
      height: 30px;
      font-family: $font-primary;
      font-weight: 500;
    }

    &TicketClosed {
      color: $gray-400;

      @include breakpoint(md) {
        margin-left: 70px;
      }
    }
  }

  &__main {
    height: 650px;
    overflow-y: auto;
    padding-bottom: 15px;
    display: flex;
    flex-direction: column;

    &MessagesPerDate {
      margin-top: auto;
      margin-bottom: 15px;

      &:last-child {
        margin-bottom: 0;
      }

      @include breakpoint(md) {
        margin-bottom: 20px;
      }
    }

    &MessagesDate {
      font-size: 12px;
      line-height: 12px;
      color: $gray-400;
      text-align: center;
      margin-bottom: 10px;

      @include breakpoint(md) {
        margin-bottom: 20px;
      }
    }

    &Message {
      padding: 20px 30px 5px;
      max-width: 542px;
      border-radius: 20px;
      box-shadow: 0 5px 10px 0 rgba($black, .07);

      &--user {
        margin-left: auto;
        color: $dark-green-500;
      }

      &--operator {
        margin-right: auto;
      }

      &Content {
        margin-bottom: 5px;
      }

      &Info {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        &Time {
          font-size: 12px;
          line-height: 15px;
          color: $gray-400;
        }

        &Checked {
          width: 16px;
          height: 100%;
          display: flex;
          justify-content: flex-end;
          align-items: center;
        }
      }
    }
  }

  >div {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__actions {
    display: flex;

    &Textarea {
      height: auto;
      min-height: 50px;
      border: 1px solid $gray-400;
      resize: none;
      border-radius: 20px;
      margin-right: 10px;
      padding: 15px 29px 15px;
      flex: 1;
      font-size: 15px;

      &::placeholder {
        color: $gray-400;
        font-family: $font-primary;
      }
    }

    &Button {
      flex: 0 0 100%;
      max-width: 270px;
      align-self: flex-end;
      height: 50px;
    }
  }

}
</style>