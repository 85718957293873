<template>



    <div v-if="type==='name'" :class="getCellClass()" :style="getCellStyle()">
        <CheckboxCell
            v-if="withCheckbox"
            :color="color"
            :type="1"
            :group_id="group_id"
        />

        <div class="camp-table-cell-inner" :style="getCellInnerStyle()">
            <div class="camp-table-cell-title">
                <div class="camp-table-cell-title__display">
                    <div class="camp-table-cell-title__display-inner">
                        <p class="camp-table-cell-title__display-text">{{value}}</p>
                        <div class="camp-table-cell-title__edit" style="display: none;">
                            <input type="text" :value="value">
                        </div>
                    </div>
                </div>

            </div>

            <CellMenu :type="getType" :field="field"/>
        </div>

        <button class="camp-table-cell-expander camp-tooltip" type="button" data-tippy-content="Расширить колонку" aria-label="Расширить колонку"></button>
    </div>
    <div v-else-if="type==='noedit'" :class="getCellClass()" :style="getCellStyle()">


        <CheckboxCell
            v-if="withCheckbox"
            :color="color"
            :type="1"
            :group_id="group_id"
        />

        <div class="camp-table-cell-inner"  :style="getCellInnerStyle()">
            <div class="camp-table-cell-title">
                <div class="camp-table-cell-title__display">
                    <div class="camp-table-cell-title__display-inner">
                        <p class="camp-table-cell-title__display-text">{{value}}</p>
                        <div class="camp-table-cell-title__edit" style="display: none;">
                            <input type="text" :value="value">
                        </div>
                    </div>
                </div>

            </div>

            <CellMenu :type="getType" :field="field"/>
        </div>

        <button class="camp-table-cell-expander camp-tooltip" type="button" data-tippy-content="Расширить колонку" aria-label="Расширить колонку"></button>
    </div>
    <div v-else-if="type==='add-new'" :class="getCellClass()" :style="getCellStyle()">


        <AddColumn/>


    </div>
    <div v-else-if="type==='price'"  :class="getCellClass()" :style="getCellStyle()">


        <CheckboxCell
            v-if="withCheckbox"
            :color="color"
            :type="1"
            :group_id="group_id"
            />



        <div class="camp-table-cell-inner"  :style="getCellInnerStyle()">
            <div class="camp-table-cell-title">
                <EditColumn 
                    v-if="getType" 
                    :value="value"
                    :field="field"
                />
                <div v-else class="camp-table-cell-title__display">
                    <div class="camp-table-cell-title__display-inner">
                        <p class="camp-table-cell-title__display-text">{{value}}</p>
                    </div>
                </div>

            </div>

            <CellMenu :type="getType" :field="field"/>
        </div>

        <button class="camp-table-cell-expander camp-tooltip" type="button" data-tippy-content="Расширить колонку" aria-label="Расширить колонку"></button>


    </div>
    <div v-else-if="type==='link'" :class="getCellClass()" :style="getCellStyle()">



        <CheckboxCell
            v-if="withCheckbox"
            :color="color"
            :type="1"
            :group_id="group_id"
        />

        <div class="camp-table-cell-inner"  :style="getCellInnerStyle()">
            <div class="camp-table-cell-title">
                <div class="camp-table-cell-title__display">
                    <div class="camp-table-cell-title__display-inner">
                        <p class="camp-table-cell-title__display-text">{{value}}</p>
                    </div>
                </div>

            </div>

            <CellMenu :type="getType" :field="field"/>
        </div>

        <button class="camp-table-cell-expander camp-tooltip" type="button" data-tippy-content="Расширить колонку" aria-label="Расширить колонку"></button>

    </div>
    <div v-else-if="type==='status'" :class="getCellClass()" :style="getCellStyle()">



        <CheckboxCell
            v-if="withCheckbox"
            :color="color"
            :type="1"
            :group_id="group_id"
        />
        <div class="camp-table-cell-inner"  :style="getCellInnerStyle()">
            <div class="camp-table-cell-title">
                <EditColumn 
                    v-if="getType" 
                    :value="value"
                    :field="field"
                />
                <div v-else class="camp-table-cell-title__display">
                    <div class="camp-table-cell-title__display-inner">
                        <p class="camp-table-cell-title__display-text">{{value}}</p>
                    </div>
                </div>

            </div>

            <CellMenu :type="getType" :field="field"/>
        </div>

        <button class="camp-table-cell-expander camp-tooltip" type="button" data-tippy-content="Расширить колонку" aria-label="Расширить колонку"></button>


    </div>
    <div v-else-if="type==='date'" :class="getCellClass()" :style="getCellStyle()">


        <CheckboxCell
            v-if="withCheckbox"
            :color="color"
            :type="1"
            :group_id="group_id"
        />

        <div class="camp-table-cell-inner"  :style="getCellInnerStyle()">
            <div class="camp-table-cell-title">
                <EditColumn 
                    v-if="getType" 
                    :value="value"
                    :field="field"
                />
                <div v-else class="camp-table-cell-title__display">
                    <div class="camp-table-cell-title__display-inner">
                        <p class="camp-table-cell-title__display-text">{{value}}</p>
                    </div>
                </div>

            </div>

            <CellMenu :type="getType" :field="field"/>
        </div>

        <button class="camp-table-cell-expander camp-tooltip" type="button" data-tippy-content="Расширить колонку" aria-label="Расширить колонку"></button>

    </div>

    <div v-else-if="type==='text'" :class="getCellClass()" :style="getCellStyle()">
        <CheckboxCell
            v-if="withCheckbox"
            :color="color"
            :type="1"
            :group_id="group_id"
        />

        <div class="camp-table-cell-inner"  :style="getCellInnerStyle()">
            <div class="camp-table-cell-title">
                <EditColumn 
                    v-if="getType" 
                    :value="value"
                    :field="field"
                />
                <div v-else class="camp-table-cell-title__display">
                    <div class="camp-table-cell-title__display-inner">
                        <p class="camp-table-cell-title__display-text">{{value}}</p>
                    </div>
                </div>

            </div>

            <CellMenu :type="getType" :field="field"/>
        </div>

        <button class="camp-table-cell-expander camp-tooltip" type="button" data-tippy-content="Расширить колонку" aria-label="Расширить колонку"></button>
    </div>


    <div v-else class="camp-table-cell camp-table-cell__name" :style="{ width: `${width}px` }">
        <div v-if="isSticky" class="camp-table-stub"></div>

        <CheckboxCell
            v-if="withCheckbox"
            :color="color"
            :type="1"
            :group_id="group_id"
        />
        <div v-show="value" class="camp-table-cell-inner"  :style="getCellInnerStyle()">
            <div class="camp-table-cell-title">
                <EditColumn 
                    v-if="getType" 
                    :value="value"
                    :field="field"
                />
                <div v-else class="camp-table-cell-title__display">
                    <div class="camp-table-cell-title__display-inner">
                        <p class="camp-table-cell-title__display-text">{{value}}</p>
                    </div>
                </div>

            </div>

            <CellMenu :type="getType" :field="field"/>
        </div>

    </div>


</template>

<script>
    import CheckboxCell from "./CheckboxCell";
    import CellMenu from "./CellMenu";
    import AddColumn from "./Inputs/AddColumn";
    import Svg from "./Svg";
    import EditColumn from "./Inputs/EditColumn.vue";
    export default {
        name: "TableCellHeader",
        components: {Svg, AddColumn, CellMenu, CheckboxCell, EditColumn},
        props: {
            width: {
                type: Number,
                default: 20 // Устанавливаем значение по умолчанию равным 0
            },
            value:{
                type: String,
                default: '' // Устанавливаем значение по умолчанию равным 0
            },
            group_id:Number,
            field:null,
            withCheckbox:Boolean,
            isSticky:Boolean,
            is_extra:Boolean,
            type:String,
            color:String,
        },
        computed: {

            getType(){
             return this.is_extra? 1: 0;
            }
        },
        methods: {
            getCellClass() {
                if(this.width===0)
                {
                    return "display_none";
                }
                let tablename=this.type;
                if(this.is_extra){
                    tablename='new';
                }
                else {
                    if (tablename === 'noedit' || tablename === 'date') {
                        tablename = 'name';
                    }
                }
                return {
                    'camp-table-cell': true,
                    'camp-table-cell-sticky': this.isSticky,
                    [`camp-table-cell__${tablename}`]: true,
                };

            },
            getCellStyle() {
                 const style = {
                    width: `${this.width}px`,
                    // Другие стили, если необходимо

                 };

                 if (this.color) {
                    //style.backgroundColor = `${this.color}`;
                 }

                 return style;
            },
            getCellInnerStyle(){
                 const style = {};
                 if (this.color) {
                   // style.backgroundColor = `${this.color}`;
                     //style.filter= 'contrast(0.8)';
                 }



                 return style;
            }
        },
    }
</script>

<style scoped>
    .display_none{
        display: none;
    }
</style>
