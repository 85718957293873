<template>
    <div class="camp-table-row camp-row camp-row-nowrap">
        <div class="camp-table-cell camp-table-cell__name camp-table-cell-sticky camp-table-cell__empty" style="width: 360px;">

            <div class="camp-table-left-indicator"
                 :style="getBorderStyle()"
            >

            </div>

            <div class="camp-table-stub"></div>


            <div class="camp-table-cell-checkbox"></div>

            <div class="camp-table-cell-inner"></div>

            <div class="camp-table-cell-group-line"></div>
        </div>

        <div class="camp-table-cell camp-table-cell__empty" style="width: 200px;">
            <div class="camp-table-cell-inner">
                <div class="camp-table-cell-title">
                    <div class="camp-table-cell-title__display">
                        <div class="camp-table-cell-title__display-inner">
                            <p class="camp-table-cell-title__display-text" style="color: #e7172f">Артикул</p>

                        </div>
                    </div>

                </div>


            </div>

            <div class="camp-table-cell-group-line" style="background-color: #FDAB3D;"></div>
        </div>
        <!-- //tcell -->
        <div class="camp-table-cell camp-table-cell__empty" style="width: 600px;">




            <div class="camp-table-cell-inner">

                <div class="camp-table-cell-title">
                    <div class="camp-table-cell-title__display">
                        <div class="camp-table-cell-title__display-inner">
                            <p class="camp-table-cell-title__display-text" style="color: #0097EC">Габариты</p>
                            <div class="camp-table-cell-title__edit" style="display: none;">
                                <input type="text" value="Заголовок" style="color: #0097EC">
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div class="camp-table-cell-group-line"></div>
        </div>

        <div class="camp-table-cell camp-table-cell__empty" style="width: 190px;">
            <div class="camp-table-cell-inner">
                <div class="camp-table-cell-title">
                    <div class="camp-table-cell-title__display">
                        <div class="camp-table-cell-title__display-inner">
                            <p class="camp-table-cell-title__display-text" style="color: #FDAB3D">Цена продажи</p>
                            <div class="camp-table-cell-title__edit" style="display: none;">
                                <input type="text" value="Заголовок" style="color: #FDAB3D">
                            </div>
                        </div>
                    </div>

                </div>


            </div>

            <div class="camp-table-cell-group-line" style="background-color: #FDAB3D;"></div>
        </div>
        <!-- //tcell -->

        <div class="camp-table-cell camp-table-cell__empty" v-show="false" :style="headerGroupWidth(1)">
            <div class="camp-table-cell-inner">
                <div class="camp-table-cell-title">
                    <div class="camp-table-cell-title__display">
                        <div class="camp-table-cell-title__display-inner">
                            <p class="camp-table-cell-title__display-text" style="color: #0097EC">Закупка (Себестоимость)</p>
                            <div class="camp-table-cell-title__edit" style="display: none;">
                                <input type="text" value="Заголовок" style="color: #0097EC">
                            </div>
                        </div>
                    </div>

                </div>

                <div class="camp-expand-btn"
                     @click="toggle_expand(1)"
                >
                            <Svg :img="'expand'"/>
                </div>
            </div>

            <div class="camp-table-cell-group-line" style="background-color: #0097EC;"></div>
        </div>
        <!-- //tcell -->



        <div class="camp-table-cell camp-table-cell__empty" :style="headerGroupWidth(3)">
            <div class="camp-table-cell-inner">
                <div class="camp-table-cell-title">
                    <div class="camp-table-cell-title__display">
                        <div class="camp-table-cell-title__display-inner">
                            <p class="camp-table-cell-title__display-text" style="color: #9B51E0">Комиссия ВБ</p>
                            <div class="camp-table-cell-title__edit" style="display: none;">
                                <input type="text" value="Заголовок" style="color: #9B51E0">
                            </div>
                        </div>
                    </div>

                </div>

                <div class="camp-expand-btn"
                     @click="toggle_expand(3)"
                >
                    <Svg :img="'expand'"/>
                </div>

            </div>

            <div class="camp-table-cell-group-line" style="background-color: #9B51E0;"></div>

        </div>
        <!-- //tcell -->

        <div class="camp-table-cell camp-table-cell__empty" :style="headerGroupWidth(4)">
            <div class="camp-table-cell-inner">
                <div class="camp-table-cell-title">
                    <div class="camp-table-cell-title__display">
                        <div class="camp-table-cell-title__display-inner">
                            <p class="camp-table-cell-title__display-text" style="color: #FF3D57">Логистика</p>
                            <div class="camp-table-cell-title__edit" style="display: none;">
                                <input type="text" value="Заголовок" style="color: #FF3D57">
                            </div>
                        </div>
                    </div>

                </div>

                <div class="camp-expand-btn"
                     @click="toggle_expand(4)"
                >
                    <Svg :img="'expand'"/>
                </div>

            </div>

            <div class="camp-table-cell-group-line" style="background-color: #FF3D57;"></div>

        </div>
        <!-- //tcell -->

        <div class="camp-table-cell camp-table-cell__empty"  v-show="false" :style1="headerGroupWidth(5)">
            <div class="camp-table-cell-inner">
                <div class="camp-table-cell-title">
                    <div class="camp-table-cell-title__display">
                        <div class="camp-table-cell-title__display-inner">
                            <p class="camp-table-cell-title__display-text" style="color: #00CB8E">Маржа</p>
                            <div class="camp-table-cell-title__edit" style="display: none;">
                                <input type="text" value="Заголовок" style="color: #00CB8E">
                            </div>
                        </div>
                    </div>

                </div>

                <div class="camp-expand-btn"
                     @click="toggle_expand(5)"
                >
                    <Svg :img="'expand'"/>
                </div>

            </div>

            <div class="camp-table-cell-group-line" style="background-color: #00CB8E;"></div>

        </div>
        <!-- //tcell -->

        <div class="camp-table-cell camp-table-cell__empty" :style="headerGroupWidth(6)">
            <div class="camp-table-cell-inner">
                <div class="camp-table-cell-title">
                    <div class="camp-table-cell-title__display">
                        <div class="camp-table-cell-title__display-inner">
                            <p class="camp-table-cell-title__display-text" style="color: #0073EA">Реклама</p>
                            <div class="camp-table-cell-title__edit" style="display: none;">
                                <input type="text" value="Заголовок" style="color: #0073EA">
                            </div>
                        </div>
                    </div>

                </div>

                <div class="camp-expand-btn"
                     @click="toggle_expand(6)"
                >
                    <Svg :img="'expand'"/>
                </div>

            </div>

            <div class="camp-table-cell-group-line" style="background-color: #0073EA;"></div>

        </div>
        <!-- //tcell -->

        <div class="camp-table-cell camp-table-cell__empty" :style="headerGroupWidth(7)">
            <div class="camp-table-cell-inner">
                <div class="camp-table-cell-title">
                    <div class="camp-table-cell-title__display">
                        <div class="camp-table-cell-title__display-inner">
                            <p class="camp-table-cell-title__display-text" style="color: #EC00AA">Хранение</p>
                            <div class="camp-table-cell-title__edit" style="display: none;">
                                <input type="text" value="Заголовок" style="color: #EC00AA">
                            </div>
                        </div>
                    </div>

                </div>

                <div class="camp-expand-btn"
                     @click="toggle_expand(7)"
                >
                    <Svg :img="'expand'"/>
                </div>

            </div>

            <div class="camp-table-cell-group-line"
                 style="background-color: #EC00AA;" ></div>

        </div>
        <!-- //tcell -->

        <div class="camp-table-cell" style="width: 380px;">
            <div class="camp-table-cell-group-line"></div>
        </div>
        <!-- //tcell -->

    </div>
</template>

<script>
import {mapState} from "vuex";
import Svg from "@/components/Crm/UI/Svg.vue";

export default {
    name: "UnitEconomicsRow",
    components: {Svg},
    props:{
        color:null,
    },
    computed: {
        ...mapState({
            tableParams: state => state.crm.tableParams,
            minLayoutWidth: state => state.crm.minLayoutWidth,

        }),
        extraWidth()
        {
            return  this.minLayoutWidth - 6145+546;
        }
    },
    methods:{
        getBorderStyle(){
            if(this.color) {
                return {

                    backgroundColor: this.color

                };
            }else{
                return null;
            }
        },
        getExtraWidth(){
            if(this.extraWidth) {
                return {

                    width: this.extraWidth

                };
            }else{
                return null;
            }
        },
        headerGroupWidth(groupd_cells_id)
        {
            const groupExpanded=this.tableParams.groupCells[groupd_cells_id];
            if(groupd_cells_id===1) {
                if (groupExpanded) {
                    return {width: '590px'};
                } else {
                    return {width: '200px'};
                }
            }
            else if(groupd_cells_id===3)
            {
                if (groupExpanded) {
                    return {width: '510px'};
                } else {
                    return {width: '200px'};
                }
            }
            else if(groupd_cells_id===4)
            {
                if (groupExpanded) {
                    return {width: '520px'};
                } else {
                    return {width: '200px'};
                }
            }
            else if(groupd_cells_id===5)
            {
                if (groupExpanded) {
                    return {width: '480px'};
                } else {
                    return {width: '200px'};
                }
            }
            else if(groupd_cells_id===6)
            {
                if (groupExpanded) {
                    return {width: '550px'};
                } else {
                    return {width: '200px'};
                }
            }
            else if(groupd_cells_id===7)
            {
                if (groupExpanded) {
                    return {width: '710px'};
                } else {
                    return {width: '200px'};
                }
            }
        },
        toggle_expand(groupd_cells_id)
        {

            console.log("tableParams",this.tableParams,this.tableParams?.groupCells[groupd_cells_id])
            this.tableParams.groupCells[groupd_cells_id] = !this.tableParams?.groupCells[groupd_cells_id] ?? false;

            //this.tableParams?.groupsCell[0]
            console.log("tableParamsAfter",this.tableParams?.groupCells[groupd_cells_id])

            this.$store.commit('crm/setTableParams', this.tableParams);
        }
    }
}
</script>
<style scoped lang="scss">

</style>
