import {createStore} from 'vuex'
import { createLogger } from 'vuex'

import auth from '@/store/moduleAuth'
import priceBidder from '@/store/modulePriceBidder'
import popup from '@/store/modulePopup'
import crm from '@/store/moduleCrm'
import sidebarMenu from '@/store/moduleSidebarMenu'
import admin from '@/store/moduleAdmin'
import adminUser from '@/store/admin/moduleAdminUser'
import adminLog from '@/store/admin/moduleAdminLog'
import adminFinance from '@/store/admin/moduleAdminFinance'
import settings from '@/store/account/moduleSettings'
import userStore from '@/store/account/moduleUserStore'
import userTickets from '@/store/account/moduleUserTickets'
import userBalance from '@/store/account/moduleUserBalance'
import userNotification from '@/store/account/moduleUserNotification'
import adminMinus from '@/store/admin/moduleAdminMinus'


export default createStore ({

  state: () => ({
    isMobile: window.innerWidth <= 1200,
    globalPreloader: false,
    sidebarRollUp: false,
    countryCodes: [
      {value: '+7', name: 'ru'},
      {value: '+7', name: 'kz'},
      {value: '+375', name: 'by'},
      {value: '+380', name: 'uk'},
      {value: '+994', name: 'az'},
      {value: '+374', name: 'ar'},
      {value: '+996', name: 'ky'},
      {value: '+373', name: 'ml'},
      {value: '+992', name: 'tj'},
      {value: '+993', name: 'tr'},
      {value: '+998', name: 'uz'},
    ],
    countryCode: '+7', //default country code
  }),

  mutations: {
    setIsMobile(state, payload) {
      console.log('checkIsMobile')
      state.isMobile = payload
    },
    setGlobalPreloader(state, globalPreloader) {
      state.globalPreloader = globalPreloader
    },
    setSidebarRollUp(state, sidebarRollUp) {
      state.sidebarRollUp = sidebarRollUp
    },
    setCountryCode(state, code) {
      state.countryCode = code
    }
  },

  actions: {
    checkIsMobile({commit}) {
      commit('setIsMobile', window.innerWidth <= 1200)
    },
    showGlobalPreloader({commit}) {
      commit('setGlobalPreloader', true);
    },
    hideGlobalPreloader({commit}) {
      commit('setGlobalPreloader', false);
    },
    sidebarRollUpAction({commit}, payload) {
      console.log(payload)
      commit('setSidebarRollUp', payload)
    }
  },

  modules: {
    auth,
    priceBidder,
    popup,
    crm,
    sidebarMenu,
    admin,
    adminUser,
    adminLog,
    adminFinance,
    settings,
    userStore,
    userTickets,
    userBalance,
    userNotification,
    adminMinus,
  },

  plugins: process.env.NODE_ENV === 'production' ? [] : [
    createLogger()
  ]

})