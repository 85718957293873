import baseApi from '@/api/baseApi'

export default class priceBidderApi extends baseApi {

  getUpdatePricesFromApi() {
    return this.get('wbbidder/update/prices-from-api')
  }

  getProductList(queryParams) {
    let setQueryParam = {}

    for(let param in queryParams) {
      if(queryParams[param]) {
        setQueryParam[param] = queryParams[param]
      }
    }

    return this.get('wbbidder', setQueryParam)
  }

  searchProducts(payload) {
    return this.post(`wbbidder/search`, payload)
  }

  addFavorite(id) {
      const payload = {
            product_id: id,
      }
    return this.post(`wbbidder/product/add-favorite/`, payload)
  }

  removeFavorite(id) {
      const payload = {
          product_id: id,
      }
    return this.post(`wbbidder/product/remove-favorite/`, payload)
  }

  searchAllLeaders(id) {
      const payload = {
          store_id: id,
      }
    return this.post(`wbbidder/get-all-leaders/`, payload)
  }

  getSingleProduct(product_id) {

    return this.get(`wbbidder/${product_id}`)
  }

  runBidderForProduct(payload) {
    //const {product_id, keywords,min_price,max_price}

      const product_id=payload.product_id;
      //console.log("biide");

    //return this.post("bidder/start/" + product_id, payload);
    // let rKeywords="";
    // if(Array.isArray(payload.keywords)) {
    //     rKeywords = payload.keywords
    //     .map(item => item.trim()) // Удалить пробелы с начала и конца каждой строки
    //     .join(', ');
    // }
      const payloadUpdated = {
          // keywords: rKeywords,
          product_id:payload.product_id,
          // max_price: payload.max_price,
          // min_price: payload.min_price,
          // is_auto:payload?.auto ?? true,
          // is_active: true,
      };

    return this.post("wbbidder/start-bidder", payloadUpdated);

  }

  stopBidderForProduct(payload) {
    //const {product_id, keywords,min_price,max_price}

    const product_id=payload.product_id;
      //console.log("biide");
      ///api/bidder/stop/{product:id
    //return this.post("bidder/stop/" + product_id);

      const payloadUpdated = {
          product_id:product_id,

      };
    return this.post("wbbidder/stop-bidder",payloadUpdated);

  }

  updateBidderForProduct(payload) {
    //const {product_id, keywords,min_price,max_price}
    const keyWords= payload?.keywords ?? [];

    const product_id=payload.product_id;
      console.log("bide",payload);

      var payloadUpdated = {
          //keywords: payload?.keywords ?? "",
          product_id:payload.product_id,
          max_price: payload.max_price,
          min_price: payload.min_price,
          is_auto:payload.auto ?? true,
          //is_active: true,
      };
      if(payload.keywords && payload.keywords.length > 0)
      {
          payloadUpdated={
              ...payloadUpdated,
              keywords: payload?.keywords ,
          }
      }

    //return this.post("bidder/update/" + product_id, payload);
    return this.post("wbbidder/update-bidder", payloadUpdated);
  }


  runSearchLeader(product_id,days) {
    return this.get("wbbidder/leaders-v2/"+product_id+"/"+days);
  }


  getProductDiagram(product_id) {
    return this.get(`wbbidder/diagram/${product_id}`);
  }
  getProductDiagram2(product_id) {
    return this.get(`wbbidder/diagram2/${product_id}`);
  }

}