<template>
  <div
    class="youcamp"
    :class="{'youcamp--overflow' : globalPreloader}"
  >


    <transition name="fade">
      <GlobalPreloader
        v-if="globalPreloader"
        :startTextAnimation="globalPreloader"
      />
    </transition>

    <div
      :class="`youcamp__layout youcamp__layout--${this.$route.name} ${checkModalQueue ? 'youcamp__layout--popup' : ''}`"
    >
      <Sidebar
        v-if="!$store.state.isMobile"
        class="youcamp__sidebar"
      />

      <main class="youcamp__content">

        <Header
          class="youcamp__header"
          v-if="$store.state.isMobile && $route.path.includes('crm') ? false : true"
        />

        <div class="youcamp__contentInner">
          <router-view :class="componentClass" />
        </div>
      </main>
    </div>

    <component
      v-for = "item in modalQueue"
      :key="item"
      :is = "item.component"
      v-bind = "item.props"
    />

  </div>
</template>

<script>
import Header from '@/components/Header/Header'
import Sidebar from '@/components/Sidebar/Sidebar'
import GlobalPreloader from '@/components/GlobalPreloader'

import {mapState, mapActions, mapGetters} from 'vuex'



export default {
  components: {
    GlobalPreloader,
    Header,
    Sidebar,
  },
  data() {
    return {

    }
  },
  methods: {
    ...mapActions({
      hideGlobalPreloader: 'hideGlobalPreloader',
      openPopup: 'popup/openPopup'
    })
  },
  computed: {

    ...mapGetters({
      checkModalQueue: 'popup/checkModalQueue'
    }),

    ...mapState({
      modalQueue: state => state.popup.modalQueue,
      globalPreloader: state => state.globalPreloader,
    }),

    componentClass() {
      return `youcamp__${this.$route.name}`
    },
    getRouteName() {
      return this.$route.name
    }
  },
  beforeMount() {
    if(this.globalPreloader) {
      setTimeout(() => {
        this.hideGlobalPreloader()
      }, 3500)
    }
  }
}
</script>

<style lang="scss">
@import 'src/scss/utils/mixins.scss';
@import 'src/scss/base/project/var.scss';

.youcamp {

  &--overflow {
    height: 100vh;
    //overflow: hidden;
  }

  &__layout {
    width: 100%;
    height: 100%;
    min-height: 100vh;
    display: flex;
    background-color: $white;
    @include breakpoint(md) {
      background-color: $gray-100;
      display: flex;
      flex-direction: column;
      &--PBSingleProduct {
        background-color: $white;
      }
    }
    &--popup {
      position: relative;
      z-index: -1;
    }
  }

  &__header {
    position: sticky;
    top: 0;
  }

  &__sidebar {
    position: sticky;
    top: 0;
    color: $white;
    flex: 0 0 100%;
    max-width: 317px;
  }

  &__content {
    position: relative;
    flex: 1;
    z-index: 1;
    padding-bottom: 40px;
    @include breakpoint(xl) {
      width: calc(100vw - 296px)
    }
    @include breakpoint(md) {
      max-width: 1200px;
      width: 100%;
    }
    &Inner {
      @include breakpoint(md) {
        max-width: 768px;
        margin: 0 auto;
      }
    }
  }

  &__PBBasicProducts {
    position: relative;
    padding-top: 20px;
    min-height: calc(100vh - 287px);
    @include breakpoint(xl) {
      min-height: calc(100vh - 279px);
    }
    @include breakpoint(md) {
      background-color: $gray-100;
      padding-top: 15px;
      margin-bottom: -20px;
      min-height: calc(100vh - 256px);
    }
    @include breakpoint(sm) {
      min-height: calc(100vh - 287px);
    }
    @include mediaMaxWidth(375) {
      min-height: calc(100vh - 302px);
    }
    @include breakpoint(xs) {
      padding-top: 10px;
    }
  }

  &__PBSingleProduct {
    padding-top: 40px;
    @include breakpoint(xl) {
      padding-top: 20px;
    }
  }

  &__instructionBackLight {
    position: absolute;
    top: 0;
    left: 0;
    width: 100px;
    height: 100px;
    background-color: #D9D9D9;
    mix-blend-mode: overlay;
    z-index: 10000;
  }

}
</style>
